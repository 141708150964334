import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    /* using the previous version to override the new version (!danger) */
  .str-chat__channel-search {
    display: flex;
  }

  str-chat__channel-search {
    padding: var(--md-p) var(--md-p) 0;
    display: flex;
    align-items: center;
    position: relative;
    background: var(--grey-gainsboro);
  }
  .str-chat__channel-search input {
    flex: 1;
    background: var(--grey-whisper);
    border: 1px solid transparent;
    outline: none;
    height: 30px;
    border-radius: var(--border-radius);
    color: var(--black);
    font-size: var(--md-font);
    padding: var(--sm-p);
  }
  .str-chat__channel-search input::placeholder {
    color: var(--grey);
  }
  .str-chat__channel-search input:focus {
    background: var(--white);
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 0 2px var(--primary-color-faded);
    transition: all 0.2s ease-in-out;
  }
  .str-chat__channel-search-container {
    position: absolute;
    width: 300px;
    z-index: ${props => props.theme.zIndex.chatSearchContainer};
    padding: 8px;
  }
  .str-chat__channel-search-container-searching {
    padding-left: 8px;
  }
  .str-chat__channel-search-container-empty {
    padding-left: 8px;
  }
  .str-chat__channel-search-container .str-chat__channel-search-result {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-md);
    height: 40px;
    cursor: pointer;
    padding-left: 8px;
    width: 100%;
    border: none;
    background: transparent;
  }
  .str-chat__channel-search-container .str-chat__channel-search-result:hover {
    font-weight: var(--font-weight-bold);
    transition: all 0.2s ease-in-out;
  }
  .str-chat__channel-search-result.focused {
    font-weight: var(--font-weight-bold);
  }
  .str-chat__channel-search-container.popup {
    border-radius: var(--border-radius-md);
    background: var(--white-smoke);
    left: 300px;
    top: 20px;
    box-shadow: 0 7px 9px 0 var(--border), 0 1px 0 0 var(--border);
    border: 1px solid var(--border);
  }
  .str-chat__channel-search-container.popup .str-chat__channel-search-result:hover {
    background: var(--light-grey);
  }
  .str-chat__channel-search-container.popup .str-chat__channel-search-result.focused {
    background: var(--light-grey);
  }
  .str-chat__channel-search-container.inline {
    top: 64px;
    right: 0;
    background: var(--grey-gainsboro);
    height: calc(100vh - 60px);
  }
  .str-chat__channel-search-container.inline .str-chat__channel-search-result:hover {
    background: var(--white);
  }
  .str-chat__channel-search-container.inline .str-chat__channel-search-result.focused {
    background: var(--white);
  }
  .dark.str-chat .str-chat__channel-search,
  .dark.str-chat .str-chat__channel-search-container.inline {
    background: var(--dark-grey);
    color: var(--white);
  }
  .dark.str-chat .str-chat__channel-search input,
  .dark.str-chat .str-chat__channel-search-container.inline input {
    background: var(--grey-gainsboro);
  }
  .dark.str-chat .str-chat__channel-search .str-chat__channel-search-result,
  .dark.str-chat .str-chat__channel-search-container.inline .str-chat__channel-search-result {
    color: var(--white);
  }
  .dark.str-chat .str-chat__channel-search .str-chat__channel-search-result:hover,
  .dark.str-chat .str-chat__channel-search-container.inline .str-chat__channel-search-result:hover {
    background: var(--white5);
  }

  /* my custom */

  .str-chat.messaging {
    background-color: ${props => props.theme.background} !important;
  }
  .str-chat__li--middle .message-sender-name,
  .str-chat__li--bottom .message-sender-name {
    display: none;
  }

  .chat-gp-li-bottom:has(.str-chat__message-simple-status), .chat-gp-li-single:has(.str-chat__message-simple-status) {
    padding-bottom: 35px;
  }

  .str-chat__message-attachment--image img {
    width: inherit;
    height: inherit;
  }

  .str-chat__message-attachment-card:not(.str-chat__message-attachment-card--giphy) .str-chat__message-attachment-card--header img {
    width: 100%;
    height: 100%;
  }

  .str-chat__modal__inner {
    max-width: 50vw;
  }

  .str-chat__modal__inner.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => props.theme.zIndex.chatInnerModal};
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .splide {
    position: relative;
    visibility: unset;
  }

  .splide.is-initialized,
  .splide.is-rendered {
    visibility: unset;
  }
  
  .str-chat__li--top .str-chat__message .str-chat__avatar, .str-chat__li--middle .str-chat__message .str-chat__avatar {
    visibility: hidden;
  }

@media screen and (max-width: 960px) {
.str-chat-channel-list.messaging, .str-chat-channel-list.team, .str-chat-channel-list.commerce {
    position: static;
    box-shadow: unset;
    min-height: unset;
  }
}

@media screen and (max-width: 960px) {
  .str-chat__header-hamburger {
    display: none;
  }
}
`;
