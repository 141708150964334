import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { setAccountMigrations, setBrokerageAccountType, setBrokerageAccountTypes } from './AuthConfig';
import Loading from './components/Loading';
import ThemeProvider from './contexts/ThemeContext';
import { SERVICES_ENV } from './env';
import { queryClient } from './lib/queryClient';
import { router } from './routes/routes';

polyfillCountryFlagEmojis(); //to fix the issue with emojis not showing on Windows.

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/labels/account_type.json')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Server returned HTTP ' + response.status);
        }
      })
      .then(({ accountMigrations, brokerageAccountType, brokerageAccountTypes }) => {
        setAccountMigrations(accountMigrations);
        setBrokerageAccountType(brokerageAccountType);
        setBrokerageAccountTypes(brokerageAccountTypes);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <GTMProvider
        state={{
          id: SERVICES_ENV.GTM_ID,
          environment: { gtm_auth: SERVICES_ENV.GTM_AUTH, gtm_preview: SERVICES_ENV.GTM_PREVIEW },
        }}
      >
        <HelmetProvider>
          <ThemeProvider>
            <RouterProvider router={router} fallbackElement={<Loading />} />
          </ThemeProvider>
        </HelmetProvider>
      </GTMProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
    </QueryClientProvider>
  );
}
