import {
  API_CONFIG,
  KycStatus,
  LiveAccountRegistration,
  LiveAccountRegistrationData,
  Page,
  TradingAccountMigrationStatus,
} from '@aqt/pt-api';

import { fetchJson } from './apiUtils';

export const getTAndCByAccountType = async (accountType: string, token?: string | Promise<string>) => {
  return fetchJson<Page<{ accountType: string; id: number; title: string; url: string }>>(
    `${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/tAndC?accountType=${accountType}`,
    undefined,
    token ?? API_CONFIG.accessToken
  );
};

export const getMigrationStatus = (fromAccountType: string, toAccountType: string, token?: string | Promise<string>) => {
  return fetchJson<{ status: TradingAccountMigrationStatus; reason: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v1/migrations/${fromAccountType}/${toAccountType}/status`,
    undefined,
    token ?? API_CONFIG.accessToken
  );
};

export const getKycStatus = async (cognitoId: string, token?: string | Promise<string>) => {
  const response = await fetchJson<{ status: KycStatus }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/cognitoUsers/${cognitoId}/liveAccountRegistrationStatus`,
    undefined,
    token ?? API_CONFIG.accessToken
  );
  return response.status;
};

export const submitKycForm = async (formData: LiveAccountRegistrationData, token?: string | Promise<string>) => {
  return fetchJson<LiveAccountRegistration>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/liveAccountRegistrations`,
    {
      body: JSON.stringify(formData),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    },
    token ?? API_CONFIG.accessToken
  );
};

export const uploadImagesForKyc = async (
  liveAccountRegistrationId: string,
  imageUploadData: FormData,
  token?: string | Promise<string>
) => {
  return fetchJson(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/liveAccountRegistrations/${liveAccountRegistrationId}/images`,
    {
      body: imageUploadData,
      method: 'POST',
    },
    token ?? API_CONFIG.accessToken
  );
};

export const migrateAccount = async (fromAccountType: string, toAccountType: string, token?: string | Promise<string>) => {
  return fetchJson<{ message: string; status: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v1/migrations/${fromAccountType}/${toAccountType}`,
    {
      method: 'PUT',
    },
    token ?? API_CONFIG.accessToken
  );
};
