import { jitter } from '@aqt/pt-api';
import { API_CONFIG } from '@aqt/pt-api/http';

export let fetchResponse: (url: string, accessToken: string, opts?: RequestInit) => Promise<Response> = async (
  url,
  accessToken,
  opts
) => {
  if (API_CONFIG.isLogStdout) console.log(new Date(), `${opts?.method ?? 'GET'} ${url}`);
  let response = await fetch(url, {
    ...opts,
    headers: { ...(opts?.headers ?? {}), Authorization: accessToken },
  });

  let contentType = response.headers.get('content-type') ?? 'application/json';
  if (response.ok) {
    return response;
  } else if (contentType.startsWith('application/json')) {
    let json = await response.json();
    let message = json?.errors?.[0]?.message;
    // eslint-disable-next-line no-throw-literal
    throw { message: 'Server returned HTTP ' + response.status + ': ' + JSON.stringify(json), field: message, json, url };
  } else {
    let text = await response.text();
    // eslint-disable-next-line no-throw-literal
    throw { message: 'Server returned HTTP ' + response.status + ': ' + text, text, url };
  }
};

export let fetchJson: <T>(url: string, opts?: RequestInit, token?: string | Promise<string>) => Promise<T> = async (
  url,
  opts,
  token = API_CONFIG.accessToken
) => {
  let response = await fetchResponse(url, await token, opts);
  let contentType = response.headers.get('content-type') ?? 'application/json';
  if (contentType.startsWith('application/json')) {
    return response.json();
  } else {
    // eslint-disable-next-line no-throw-literal
    throw { message: 'Server returned non-JSON ' + response.status };
  }
};

export let fetchText: (url: string, opts?: RequestInit) => Promise<string> = async (url, opts) => {
  let response = await fetchResponse(url, await API_CONFIG.accessToken, opts);
  let contentType = response.headers.get('content-type') ?? 'text/plain; charset=utf-8';
  if (contentType.startsWith('text/plain')) {
    return response.text();
  } else {
    // eslint-disable-next-line no-throw-literal
    throw { message: 'Server returned non-JSON ' + response.status };
  }
};

export let fetchMarketDataJson: <T>(url: string, opts?: RequestInit) => Promise<T> = async (url, opts) => {
  let response = await fetchResponse(url, await getMarketDataAccessToken(), opts);
  return await response.json();
};

export type OnMessage = (e: MessageEvent) => void;
export type OnStateChange = (state: EventSource['readyState']) => void;

export function connectSSE(
  url: string,
  onMessage: OnMessage,
  onConnect?: () => void,
  onOpen?: (e: Event) => void,
  onClose?: () => void
) {
  let active = true;
  let reconnectWait = 1000;
  const maxReconnectWait = 64 * 1000;
  let eventSource: EventSource | null;
  let timerId: NodeJS.Timeout;

  const close = () => {
    eventSource?.close();
    clearTimeout(timerId);
    onClose?.();
    console.log(`UNSUBSCRIBE ${url}`);
  };

  const connect = async () => {
    try {
      const token = await API_CONFIG.accessToken;
      if (active) {
        console.log(`SUBSCRIBE ${url}`);
        onConnect?.();
        eventSource = new EventSource(`${url}?token=${token}`);
        eventSource.addEventListener('error', e => {
          close();
          reconnectWait = Math.min(reconnectWait * 2, maxReconnectWait);
          const waitTime = jitter(reconnectWait);
          timerId = setTimeout(connect, waitTime);
          console.error(`reconnect ${url} in ${waitTime} ms`, e);
        });
        eventSource.addEventListener('message', onMessage);
        eventSource.addEventListener('open', e => {
          reconnectWait = 1000;
          onOpen?.(e);
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  connect();

  return () => {
    active = false;
    close();
  };
}

export const connectSSEWithStateChange = (url: string, onMessage: OnMessage, onStateChange: OnStateChange) => {
  const onOpen = () => {
    console.info('SSE opened: ' + url);
    onStateChange(EventSource.OPEN);
  };

  onStateChange(EventSource.CLOSED);

  return connectSSE(
    url,
    onMessage,
    () => onStateChange(EventSource.CONNECTING),
    onOpen,
    () => onStateChange(EventSource.CLOSED)
  );
};

let marketDataAccessToken: Promise<{ accessToken: string; expTs: number }> = Promise.resolve({ accessToken: '', expTs: 0 });

let getMarketDataAccessToken = async () => {
  let { accessToken, expTs } = await marketDataAccessToken;
  if (Date.now() < expTs) {
    return accessToken;
  } else {
    marketDataAccessToken = (async () => {
      let { accessToken, exp } = await fetchJson<{ accessToken: string; exp: string }>(
        `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/history/api/v1/tokens/marketData`
      );
      return { accessToken, expTs: +new Date(exp) };
    })();
  }
  return (await marketDataAccessToken).accessToken;
};
