import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AqtQuery } from '../lib/apiClient';

type Key = keyof typeof AqtQuery;
type Keys<K extends Key> = [K, { [key: string]: unknown }];

export const useAqtQuery = <K extends Key>(
  key: K | Keys<K>,
  params: Parameters<(typeof AqtQuery)[K]>,
  opts?: Omit<UseQueryOptions<Awaited<ReturnType<(typeof AqtQuery)[K]>>>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<Awaited<ReturnType<(typeof AqtQuery)[K]>>>({
    queryKey: typeof key === 'string' ? [key] : key,
    queryFn: () => (AqtQuery[typeof key === 'string' ? key : key[0]] as Function)(...params),
    ...opts,
  });
};
