import {
  CampaignBannerDetail,
  CampaignFAQ,
  CampaignRule,
  ClaimRewardResponse,
  LuckyDrawClaim,
  LuckyDrawEntry,
  LuckyDrawStatus,
  MyMission,
  Page,
  PromotionCampaign,
  RewardHistory,
  RewardStatus,
  UnredeemedCount,
} from '@aqt/pt-api';
import { API_CONFIG } from '@aqt/pt-api/http';

import { fetchJson } from './apiUtils';

export const claimLuckyDraw = (campaignCode: string, accountType: string, accountId: string, noOfEntries: 'all' | '1') => {
  return fetchJson<LuckyDrawClaim>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns/${campaignCode}/luckyDraw/claim`, {
    body: JSON.stringify({
      accountType,
      accountId,
      noOfEntries,
    }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const claimReward = (rewardCode: string, accountType: string, accountId: string) => {
  return fetchJson<ClaimRewardResponse>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/rewards/claim`, {
    body: JSON.stringify({
      rewardCode,
      accountType,
      accountId,
    }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};
export const getCampaignBanners = async () => {
  return fetchJson<
    Page<{
      id: number;
      banners: CampaignBannerDetail[];
    }>
  >(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/campaignBanners`);
};

export const getCampaignConfig = async () => {
  const response = await fetch(`${API_CONFIG.PT_WEB_ENDPOINT}/campaigns/rocket_to_rewards.json`);
  return response.json();
};

export const getCampaignFAQs = async () => {
  return fetchJson<Page<CampaignFAQ>>(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/campaignFAQs`);
};

export const getCampaignRules = async () => {
  return fetchJson<Page<CampaignRule>>(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/campaignRules`);
};

export const getCampaigns = () => {
  return fetchJson<{ campaignCode: string; campaignDesc: string; campaignStartAt: Date; campaignEndAt: Date }[]>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns`
  );
};

export const getMyMission = async (campaignCode: string, accountType: string, accountId: string) => {
  return fetchJson<MyMission>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns/${campaignCode}/status?accountType=${accountType}&accountId=${accountId}`
  );
};

export const getRewardHistory = (accountType: string, accountId: string, fr: Date, to: Date, page = 0, size = 20) => {
  return fetchJson<Page<RewardHistory>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/rewards/history?accountType=${accountType}&accountId=${accountId}&fr=${fr.toISOString()}&to=${to.toISOString()}&page=${page}&size=${size}`
  );
};

export const getPromotionCampaign = () => {
  return fetchJson<PromotionCampaign>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns/promotions`);
};

export const getFirstDepositAmount = (rewardCode: string, accountType: string, accountId: string) => {
  return fetchJson<{
    type: 'DEPOSIT' | 'WITHDRAWAL';
    amount: number;
    transactedAt: string;
    requestId: string;
  }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/rewards/${rewardCode}/firstDepositAmount?accountType=${accountType}&accountId=${accountId}`
  );
};

export const getLuckyDrawHistory = (
  campaignCode: string,
  accountType: string,
  accountId: string,
  page: number = 0,
  size: number = 8
) => {
  return fetchJson<Page<LuckyDrawEntry>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns/${campaignCode}/luckyDraw/history?accountType=${accountType}&accountId=${accountId}&page=${page}&size=${size}`
  );
};

export const getLuckyDrawStatus = (campaignCode: string, accountType: string, accountId: string) => {
  return fetchJson<LuckyDrawStatus>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns/${campaignCode}/luckyDraw/status?accountType=${accountType}&accountId=${accountId}`
  );
};

export const getReferralCode = (cognitoId: string) => {
  return fetchJson<{ referralCode: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/referrals/cognitoUsers/${cognitoId}/referralCodes/generate`
  );
};

export const getUserRewardStatus = (campaignCode: string, accountType: string, accountId: string) => {
  return fetchJson<RewardStatus>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns/${campaignCode}/rewardSummary?accountType=${accountType}&accountId=${accountId}`
  );
};

export const getUnredeemedCount = (campaignCode: string, accountType: string, accountId: string) => {
  return fetchJson<UnredeemedCount>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/campaigns/${campaignCode}/unredeemed/count?accountType=${accountType}&accountId=${accountId}`
  );
};

export const verifyReferralCode = async (referralCode: string) => {
  return fetchJson<{ isValid: boolean }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/referrals/referralCodes/${encodeURIComponent(referralCode)}/verify`,
    {
      headers: { Authorization: await API_CONFIG.accessToken },
    }
  );
};
