import { API_CONFIG } from '@aqt/pt-api';
import {
  FocusedMarket,
  User,
  UserNotificationPreferences,
  UserNotificationPreferencesRequest,
  UserPreferences,
  UserTradePreferences,
  UserTradePreferencesRequest,
} from '@aqt/pt-api';

import { fetchJson, fetchResponse } from './apiUtils';

export const getCurrentUser = () => {
  return fetchJson<User>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/currentUser`);
};

export const getUser = (userId: string) => {
  return fetchJson<{
    id: string;
    cognitoId: string;
    hashId: string;
    friendlyName: string; // login name
    avatarLink: string;
    bio: string;
    lastLoginTime: Date;
    focusedMarkets: FocusedMarket[];
    status: 'OFFLINE' | 'ONLINE' | 'SOCKET_COMPLETE';
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/users/${encodeURIComponent(userId)}`);
};

export const getUserByCognitoId = (cognitoId: string) => {
  return fetchJson<User[]>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/users/search?cognitoIds=${encodeURIComponent(cognitoId)}`);
};

export const getUserPreferences = (cognitoId: string) => {
  return fetchJson<UserPreferences>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/cognitoUsers/${cognitoId}/preferences`);
};

export const getUserNotificationPreferences = (cognitoId: string) => {
  return fetchJson<UserNotificationPreferences>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/cognitoUsers/${cognitoId}/preferences/notificationSettings`
  );
};

export const getUserTradePreferences = (cognitoId: string) => {
  return fetchJson<UserTradePreferences>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/cognitoUsers/${cognitoId}/preferences/tradeSettings`
  );
};

export const uploadCoverPic = async (image: Blob, width: number, height: number) => {
  const formData = new FormData();
  formData.append('files', image);
  formData.append('toCropImgX', '0');
  formData.append('toCropImgY', '0');
  formData.append('toCropImgW', width.toString());
  formData.append('toCropImgH', height.toString());

  return fetchJson<User>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/uploadCoverPic`, {
    method: 'POST',
    headers: { Authorization: await API_CONFIG.accessToken },
    body: formData,
  });
};

export const uploadAvatar = async (image: Blob, width: number, height: number) => {
  const formData = new FormData();
  formData.append('files', image);
  formData.append('toCropImgX', '0');
  formData.append('toCropImgY', '0');
  formData.append('toCropImgW', width.toString());
  formData.append('toCropImgH', height.toString());
  return await fetchJson<User>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/uploadAvatar`, {
    method: 'POST',
    headers: { Authorization: await API_CONFIG.accessToken },
    body: formData,
  });
};

export const updateUserPreferences = (cognitoId: string, userPreferences: UserPreferences) => {
  return fetchJson<UserPreferences>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/cognitoUsers/${cognitoId}/preferences`, {
    body: JSON.stringify(userPreferences),
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
  });
};

export const updateUserTradePreferences = (cognitoId: string, requestBody: UserTradePreferencesRequest) => {
  return fetchJson<UserTradePreferences>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/cognitoUsers/${cognitoId}/preferences/tradeSettings`,
    {
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    }
  );
};

export const updateUserNotificationPreferences = (cognitoId: string, requestBody: UserNotificationPreferencesRequest) => {
  return fetchJson<UserNotificationPreferences>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/cognitoUsers/${cognitoId}/preferences/notificationSettings`,
    {
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    }
  );
};

export const removeAvatar = () => {
  return fetchJson<User>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/removeAvatar`, { method: 'POST' });
};

export const removeCoverPic = () => {
  return fetchJson<User>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/removeCoverPic`, { method: 'POST' });
};

export const updateCurrentUser = async (username?: string, focusedMarkets?: any, bio?: string) => {
  return fetchResponse(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/currentUser`, await API_CONFIG.accessToken, {
    body: JSON.stringify({
      friendlyName: username,
      focusedMarkets: focusedMarkets.map(marketItem => ({
        id: marketItem.id,
      })),
      bio: bio,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'PUT',
  });
};

export const updateCurrentUserSession = async (session?: string) => {
  return fetchResponse(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/currentUser`, await API_CONFIG.accessToken, {
    body: JSON.stringify({
      session: session,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'PUT',
  });
};

export const searchUserByEmail = async (email: string) => {
  return (
    await fetchJson<Array<User>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/users/search?email=${encodeURIComponent(email)}&size=1`)
  )[0];
};

export const searchUserByFriendlyName = async (friendlyName: string) => {
  const users = await fetchJson<Array<User>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/users/search?friendlyName=${encodeURIComponent(friendlyName)}&size=2`
  );
  return users.at(0);
};

export const searchUserByFriendlyNamePrefix = async (searchString: string, size: number) => {
  return await fetchJson<Array<User>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/users/search?friendlyNamePrefix=${encodeURIComponent(searchString)}&size=${size}`
  );
};
