import { Hub } from 'aws-amplify/utils';
import { addDays, isAfter } from 'date-fns';
import { lazy, Suspense, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import { usePtContext } from '@/contexts/PtContext';
import { useAqtQuery } from '@/hooks/useAqtQuery';
import { useMigration } from '@/hooks/useMigration';
import useLocalStorage from '@/utils/useLocalStorage';

const ForgotPwModal = lazy(() => import('./ForgotPwModal'));
const GuestModeModal = lazy(() => import('./GuestModeModal'));
const LoginModal = lazy(() => import('./LoginModal'));
const SignUpModal = lazy(() => import('./SignUpModal'));
const VerifyEmailModal = lazy(() => import('./VerifyEmailModal'));
const PromotionModal = lazy(() => import('./PromotionModal'));
const MigrationModal = lazy(() => import('./MigrationModal'));
const DownloadAppModal = lazy(() => import('./DownloadAppModal'));
const SignUpLiveModal = lazy(() => import('./SignUpLiveModal'));

type UserModalsProps = {
  isShowLoginModal: number;
  isShowSignUpModal: number;
  cognitoId?: string;
};

export default function UserModals({ isShowLoginModal, isShowSignUpModal, cognitoId }: UserModalsProps) {
  const { pathname } = useLocation();
  const { kycStatus } = usePtContext();
  const { isAllowMigration, openMigrationLink } = useMigration();
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [isShowResetPw, setIsShowResetPw] = useState(false);
  const [isShowSignUp, setIsShowSignUp] = useState(false);
  const [isShowVerifyEmail, setIsShowVerifyEmail] = useState(false);
  const [isShowPromotion, setIsShowPromotion] = useState(false);
  const [isShowMigrationMsg, setIsShowMigrationMsg] = useState(false);
  const [verifyEmailProps, setVerifyEmailProps] = useState({ email: '', password: '' });

  useEffect(() => setIsShowLogin(isShowLoginModal > 0), [isShowLoginModal]);
  useEffect(() => setIsShowSignUp(isShowSignUpModal > 0), [isShowSignUpModal]);

  const [showGuest, setShowGuest] = useLocalStorage('showGuestModal', true);
  const [isShowDownloadAppModalStorage, setIsDownloadAppModalStorage] = useLocalStorage('showDownloadAppModal', isMobile);
  const [isShowDownloadAppModal, setIsShowDownloadAppModal] = useState(isShowDownloadAppModalStorage);
  const isShowGuestModal = !pathname.startsWith('/signup') && !pathname.startsWith('/reset-password') && showGuest;
  const [isShowSignUpLiveModal, setIsShowSignUpLiveModal] = useState(false);
  const [shownPromotion, setShownPromotion] = useLocalStorage(
    'shownPromotionModal',
    {} as { checkedAt: string; publishedAt: string; shown: string[] }
  );

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [checkPromotionTime, setCheckPromotionTime] = useState<Date>(() => {
    const today = new Date();
    today.setUTCHours(1, 0, 0, 0);
    return today;
  });

  const { data: promotionModalData, isSuccess } = useAqtQuery('promotionalModalContents', [], {
    enabled: !shownPromotion.checkedAt || isAfter(checkPromotionTime, new Date(shownPromotion.checkedAt)),
  });

  useEffect(() => {
    if (isAllowMigration) {
      const migrationModalCloseAt = localStorage.getItem('migrationModalCloseAt');

      const aWeekAfterLastCloseMigrationModal = migrationModalCloseAt && addDays(new Date(migrationModalCloseAt), 7).toISOString();

      const showMigrationMsg =
        !migrationModalCloseAt ||
        (aWeekAfterLastCloseMigrationModal && isAfter(new Date(), new Date(aWeekAfterLastCloseMigrationModal)));

      setIsShowMigrationMsg(!!showMigrationMsg);
    }
  }, [isAllowMigration]);

  useEffect(() => {
    if (!isSuccess) return;
    if (promotionModalData.totalElements > 0) {
      if (shownPromotion.publishedAt === promotionModalData.content[0].publishedAt)
        setShownPromotion(cur => ({ ...cur, checkedAt: new Date().toISOString() }));
      else
        setShownPromotion({
          checkedAt: new Date().toISOString(),
          publishedAt: promotionModalData.content[0].publishedAt,
          shown: [],
        });
    } else setShownPromotion({ checkedAt: new Date().toISOString(), publishedAt: '', shown: [] });
  }, [promotionModalData, isSuccess, shownPromotion.publishedAt, setShownPromotion]);

  useEffect(() => {
    if (pathname === '/' || pathname === '/discover/dashboard') {
      const newCheckPromotionTime = new Date();
      newCheckPromotionTime.setUTCHours(1, 0, 0, 0);
      if (isAfter(new Date(), newCheckPromotionTime)) setCheckPromotionTime(newCheckPromotionTime);
    }
  }, [pathname]);

  useEffect(() => {
    // if user is viewing sign-up or reset password link, we don't need to show guest and promotional modals
    if (
      !pathname.startsWith('/signup') &&
      !pathname.startsWith('/reset-password') &&
      shownPromotion.publishedAt !== '' &&
      shownPromotion.shown &&
      !shownPromotion.shown.includes(cognitoId ?? 'guest')
    ) {
      setIsShowPromotion(true);

      const shownList = shownPromotion.shown ?? [];
      shownList.push(cognitoId ?? 'guest');
      setShownPromotion({ ...shownPromotion, shown: shownList });
    }
  }, [pathname, cognitoId, shownPromotion, setShownPromotion]);

  useEffect(() => {
    setIsShowSignUpLiveModal(isSignedIn && ['NO_APPLICATION', 'REJECTED'].includes(kycStatus));
  }, [isSignedIn, kycStatus]);

  useEffect(() => {
    const listener = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signedIn') {
        setIsSignedIn(true);
      } else if (payload.event === 'signedOut') {
        setIsSignedIn(false);
      }
    });
    return listener;
  }, []);

  const onFinish = props => {
    setVerifyEmailProps(props);
    setIsShowSignUp(false);
    setIsShowLogin(false);
    setIsShowVerifyEmail(true);
  };

  const handleSignUp = () => {
    setIsShowLogin(false);
    setIsShowSignUp(true);
    setIsShowVerifyEmail(false);
  };

  const handleLogin = () => {
    setIsShowSignUp(false);
    setIsShowLogin(true);
    setIsShowVerifyEmail(false);
  };

  const handleMigrationOnClose = () => {
    localStorage.setItem('migrationModalCloseAt', new Date().toISOString());
    setIsShowMigrationMsg(false);
  };

  const shouldShowPromotionModal =
    isShowPromotion &&
    !isShowDownloadAppModal &&
    !isShowGuestModal &&
    !isShowLogin &&
    !isShowSignUp &&
    !isShowResetPw &&
    !isShowVerifyEmail &&
    !isShowSignUpLiveModal;

  return (
    <Suspense fallback={null}>
      {isShowLogin && (
        <LoginModal
          open
          onClose={() => setIsShowLogin(false)}
          onForgot={() => {
            setIsShowLogin(false);
            setIsShowResetPw(true);
            setIsShowVerifyEmail(false);
          }}
          onSignUp={handleSignUp}
          onVerifyError={onFinish}
        />
      )}

      {isShowResetPw && <ForgotPwModal open onClose={() => setIsShowResetPw(false)} />}

      {isShowSignUp && (
        <SignUpModal
          open
          onClose={() => setIsShowSignUp(false)}
          onLogin={() => {
            setIsShowSignUp(false);
            setIsShowLogin(true);
            setIsShowVerifyEmail(false);
          }}
          onFinish={onFinish}
        />
      )}

      {isShowVerifyEmail && (
        <VerifyEmailModal
          open
          onClose={() => {
            setIsShowVerifyEmail(false);
          }}
          email={verifyEmailProps.email ?? ''}
          password={verifyEmailProps.password ?? ''}
        />
      )}

      {isShowGuestModal && (
        <GuestModeModal
          open
          onClose={() => {
            setShowGuest(false);
          }}
          onSignUp={() => {
            setShowGuest(false);
            handleSignUp();
          }}
          onLogin={() => {
            setShowGuest(false);
            handleLogin();
          }}
          onGuestMode={() => {
            setShowGuest(false);
          }}
        />
      )}

      {!!promotionModalData?.totalElements && shouldShowPromotionModal && (
        <PromotionModal open data={promotionModalData.content[0]} onClose={() => setIsShowPromotion(false)} />
      )}

      {isShowMigrationMsg && (
        <MigrationModal onMigration={() => openMigrationLink()} open onClose={() => handleMigrationOnClose()} />
      )}

      {isShowDownloadAppModal && (
        <DownloadAppModal
          open
          handleShown={() => {
            setIsDownloadAppModalStorage(false);
          }}
          onClose={() => {
            setIsShowDownloadAppModal(false);
          }}
        />
      )}
      {isShowSignUpLiveModal && (
        <SignUpLiveModal
          onClose={() => {
            setIsShowSignUpLiveModal(false);
          }}
        />
      )}
    </Suspense>
  );
}
