import { API_CONFIG } from '@aqt/pt-api';
import {
  Chart,
  Exchange,
  FocusedMarket,
  Holiday,
  Instrument,
  InstrumentInfo,
  Page,
  PriceAlert,
  Resolution,
  TopMover,
  TradingSchedule,
  UserByFocusedMarketResponse,
  WatchList,
  WatchListInstrument,
} from '@aqt/pt-api';

import { fetchJson, fetchMarketDataJson, fetchResponse } from './apiUtils';

export const addWatchListInstrument = (watchListId: string, instrumentIds: Array<string>) => {
  return fetchJson<Array<WatchListInstrument>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/watchlists/customized/instruments`, {
    body: JSON.stringify({
      id: watchListId,
      instruments: instrumentIds.map((instrumentId, index) => ({
        instrumentId,
        index,
      })),
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const createUserPriceAlert = (priceAlert: PriceAlert) => {
  return fetchJson<PriceAlert>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/reference/api/v1/priceAlerts`, {
    body: JSON.stringify({
      instrumentId: priceAlert.instrumentId,
      price: priceAlert.alertPrice.toString(), // AQT-6967 send as string to avoid rounding problems
      percentage: priceAlert.percentage,
      expirationTime: priceAlert.expirationTime,
    }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const deleteUserPriceAlert = async (priceAlertId: string) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/reference/api/v1/priceAlerts?id=${encodeURIComponent(priceAlertId)}`,
    await API_CONFIG.accessToken,
    {
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    }
  );
};

export const getExchanges = () => {
  return fetchJson<Array<Exchange>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/reference/api/v1/exchanges`);
};

export const getFavoriteWatchListInstruments = (watchListId: string) => {
  return fetchJson<Array<WatchListInstrument>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/watchlists/${watchListId}/instruments`);
};

export const getFocusedMarkets = () => {
  return fetchJson<Array<FocusedMarket>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/focusedMarkets`);
};

export const getHolidays = (fr: Date, to: Date) => {
  return fetchJson<Array<Holiday>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/reference/api/v1/calendar/holidays?fr=${fr.toISOString()}&to=${to.toISOString()}`
  );
};

export const getInstrumentInformationById = (
  accountType: string,
  accountId: string,
  instrumentId: string,
  timezoneOffsetMinutes: number
) => {
  return fetchJson<InstrumentInfo>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/instruments/${instrumentId}/information?timezoneOffsetMinutes=${timezoneOffsetMinutes}` +
      (accountId ? `&accountId=${accountId}` : ''),
    { headers: { 'Account-Type': accountType } }
  );
};

export const getTradingSchedules = () => {
  return fetchJson<Array<TradingSchedule>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/reference/api/v1/schedules/trading`);
};

export const getTopMoverInstruments = (accountType: string, periodHours: number) => {
  return fetchMarketDataJson<{ topGainers: TopMover[]; topLosers: TopMover[] }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/topMovers?periodHours=${periodHours}`
  );
};

export const getUserByFocusedMarket = (focusedMarketId: string, page = 0, size = 20) => {
  return fetchJson<Page<UserByFocusedMarketResponse>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/focusedMarkets/${encodeURIComponent(focusedMarketId)}/users?page=${page}&size=${size}`
  );
};

export const getUserPriceAlerts = () => {
  return fetchJson<PriceAlert[]>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/reference/api/v1/userPriceAlerts`);
};

export const getUserWatchLists = () => {
  return fetchJson<Array<WatchList>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/watchlists/customized`);
};

export const getWatchListInstruments = (watchListId: string) => {
  if (watchListId !== '') {
    return fetchMarketDataJson<Array<WatchListInstrument>>(
      `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/watchlists/${watchListId}/instruments`
    );
  } else {
    return Promise.resolve([] as Array<WatchListInstrument>);
  }
};

export const getWatchLists = () => {
  return fetchMarketDataJson<Array<WatchList>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/watchlists/predefined`);
};

export const queryHistorical = (instrumentId: string, resolution: Resolution, startDateTime: Date, endDateTime: Date) => {
  return fetchMarketDataJson<Chart>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/instruments/${instrumentId}/candles/${resolution}/queryRange?startDateTime=${startDateTime.toISOString()}&endDateTime=${endDateTime.toISOString()}`
  );
};

export const queryHistoricalClosePricesBefore = (
  instrumentId: string,
  resolution: Resolution,
  endDateTime: Date,
  nCandles: number
) => {
  // prevent getting too much candles
  nCandles = Math.min(2000, nCandles);
  return fetchMarketDataJson<{ dateTimes: Array<string>; closePrices: Array<number> }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/instruments/${instrumentId}/candles/${resolution}/queryClosePricesBefore?endDateTime=${endDateTime.toISOString()}&nCandles=${nCandles}`
  );
};

export const queryHistoricalWindowBefore = (instrumentId: string, resolution: Resolution, endDateTime: Date, nCandles: number) => {
  // prevent getting too much candles
  nCandles = Math.min(2000, nCandles);
  return fetchMarketDataJson<Chart>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/instruments/${instrumentId}/candles/${resolution}/queryWindowBefore?endDateTime=${endDateTime.toISOString()}&nCandles=${nCandles}`
  );
};

export const searchDollarTagInstrument = async (q: string, from: number, size: number) => {
  let response = await fetchJson<{
    nResults: number;
    results: { type: string; id: string; name: string; description: string }[];
  }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/instruments/search/page?q=${encodeURIComponent(q)}&from=${from}&size=${size}`
  );
  return {
    totalHits: response.nResults,
    hits: response.results.map(({ id, name, description }) => ({ id, index: 'instrument', sourceAsMap: { name, description } })),
  };
};

export const searchInstrument = (instrumentId: string) => {
  return fetchMarketDataJson<Instrument>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/searchInstruments/${encodeURIComponent(instrumentId)}`
  );
};

export const searchInstruments = (searchString: string, size?: number) => {
  const sizeQueryString = size ? `&size=${encodeURIComponent(size)}` : '';
  return fetchMarketDataJson<Array<Instrument>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/price_feed/v1/searchInstruments?searchString=${encodeURIComponent(searchString)}${sizeQueryString}`
  );
};

export const updateUserPriceAlert = (priceAlert: PriceAlert) => {
  return fetchJson<PriceAlert>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/reference/api/v1/priceAlerts`, {
    body: JSON.stringify({
      id: priceAlert.id,
      instrumentId: priceAlert.instrumentId,
      price: priceAlert.alertPrice.toString(), // AQT-6967 send as string to avoid rounding problems
      percentage: priceAlert.percentage,
      expirationTime: priceAlert.expirationTime,
    }),
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
  });
};

export const updateWatchListItems = (watchListId: string, instruments: Array<{ index: number; instrumentId: string }>) => {
  return fetchJson<Array<WatchListInstrument>>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/watchlists/customized/instruments`, {
    body: JSON.stringify({
      id: watchListId,
      instruments: instruments,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  });
};
