const Translation = {
  en: {
    translation: {
      '#': undefined,
      '(Missions) for New License Account Only': 'Missions for New License Account Only',
      '(Stellar Box) for New License Account Only': 'Stellar Box for New License Account Only',
      '(your bank account)': undefined,
      '(your bank name)': undefined,
      '{{WatchlistName}} is coming soon': '{{WatchlistName}} is coming soon. Don\'t miss out.',
      '-': undefined,
      '3 Months': undefined,
      '6 Months': undefined,
      '7 Days': undefined,
      '12 Months': undefined,
      '30 Days': undefined,
      '52 Weeks High/Low': undefined,
      'A fixed ratio will be applied to all your copied trades': 'A fixed ratio will be applied to all your copied trades. For example: if you set a 10% ratio, when the Kaptain trades $100, you will trade $10.',
      'Account': undefined,
      'Account holder\'s name': undefined,
      'Account Name': undefined,
      'Account Number': undefined,
      'Account Settings': undefined,
      'Active': undefined,
      'Active Copy Funds': undefined,
      'Active Copy Funds Def': 'The total Copy Funds allocated by current copiers to copy this Kaptain.',
      'Active Kaptain': undefined,
      'Active Kaptains': undefined,
      'Actual': undefined,
      'Add a bank account for withdrawal.': undefined,
      'Add alerts': undefined,
      'Add favourites in': 'Add favourites in <1>Markets</1> now',
      'Add members': undefined,
      'Add screenshots': undefined,
      'Additional comments': undefined,
      'Admin': undefined,
      'Administrator': undefined,
      'Admins': undefined,
      'Alert number': 'Alert {{index}}',
      'All Currency Pairs': undefined,
      'All Followers': undefined,
      'All Impacts': undefined,
      'All Instruments': undefined,
      'All rights reserved.': undefined,
      'All transactions': undefined,
      'Already a member?': undefined,
      'Amount': undefined,
      'Amount Left': '{{amount}} Left',
      'Amount sent to you': undefined,
      'Amount Using': '{{amount}} Using',
      'Application Pending': undefined,
      'Are you sure you want to close your stacked trades?': undefined,
      'Are you sure you want to close your trade?': undefined,
      'Are you sure you want to partial close your trade?': undefined,
      'Are you sure you want to place your order?': undefined,
      'Are you sure you want to stop Auto-pilot?': undefined,
      'Are you sure you want to stop copying this trader': undefined,
      'Ask me before closing a single trade': undefined,
      'Ask me before closing stacked trades': undefined,
      'Ask me before opening a trade': undefined,
      'At least 8 characters, 64 maximum': undefined,
      'Attach files': undefined,
      'Auto-pilot': undefined,
      'Auto-Pilot is on': undefined,
      'Auto-pilot will automatically close your copied trade at market price when the Kaptain closes theirs.': undefined,
      'Avatar removed successfully': undefined,
      'Average copied trade size': 'Average copied trade size {{averageCopiedTradeSize}}',
      'Average trade size is larger than your Copy Fund': 'Average trade size is larger than your Copy Fund.\n Some trades might not be copied.',
      'Avg. Active Copy Funds': undefined,
      'Avg. Closed Price': undefined,
      'Avg. Open': undefined,
      'Avg. Open Price': undefined,
      'Avg. P/L per Copier': undefined,
      'Avg. Past Copy Funds Used': undefined,
      'Avg. ROE per Copier': undefined,
      'Avg. Size': undefined,
      'Back to home': undefined,
      'Badges': undefined,
      'Balance': undefined,
      'Bank Account Add button': '+ Bank Account',
      'Bank account saved': undefined,
      'Bank Accounts': undefined,
      'Bank Accounts contact our support': 'Please <0>contact our support</0> if you need to change or remove bank account details.',
      'Bank Address cannot be empty': 'Bank Address cannot be empty',
      'Bank Code': undefined,
      'Bank Deposit': undefined,
      'Bank Name': undefined,
      'Bank Name cannot be empty': 'Bank Name cannot be empty',
      'Bank Transfer': undefined,
      'Best Single P/L': undefined,
      'Best Single ROE': undefined,
      'Bio': undefined,
      'Block': undefined,
      'Blog': undefined,
      'Branch Code': undefined,
      'Buy': undefined,
      'Buy Limit': undefined,
      'Buy Stop': undefined,
      'By signing up, you agree to our Terms & Conditions and Privacy Policy': 'By signing up, you agree to our <2>Terms & Conditions</2> and <6>Privacy Policy</6>',
      'Calendar': undefined,
      'Campaign': undefined,
      'Campaign Ended': undefined,
      'Campaign ended': undefined,
      'Campaign ends in:': undefined,
      'Campaign reward': '{{maximumReward}} in prizes awaiting your discovery',
      'Campaign starts in:': undefined,
      'Cancel': undefined,
      'Cancel Order': undefined,
      'Cancel repost': 'Cancel repost',
      'Change Avatar': undefined,
      'Change copy limit': undefined,
      'Change copy limits': undefined,
      'Change Cover Photo': undefined,
      'Change group photo': undefined,
      'Change limits': undefined,
      'Channel list': undefined,
      'Character Left': undefined,
      'Characters Left': undefined,
      'Chart': undefined,
      'Chat': undefined,
      'Chat empty': 'Choose from your existing conversations or start a new chat.',
      'Chat new': 'Start a new chat',
      'Chat now': 'Start a chat now',
      'Chats': undefined,
      'Choose a strong password': undefined,
      'Choose a subject': undefined,
      'Choose deposit method': undefined,
      'Clear': undefined,
      'Close': undefined,
      'Close All': undefined,
      'Close At Market Price': undefined,
      'Close emoji picker': undefined,
      'Close Failed': undefined,
      'Close Position Failed because': 'Close Position Failed because the market is closed. Please try again later.',
      'Close Time': undefined,
      'Close volume should not be greater than {{value}}': undefined,
      'Close volume should not be less than {{value}}': undefined,
      'Closed By Kaptain': undefined,
      'Closed copied trades': undefined,
      'Closed Event': undefined,
      'Closed Price': undefined,
      'Closed Trade': undefined,
      'Closed Trades': undefined,
      'Closing {{percent}}% of your trade': undefined,
      'Coming Soon': undefined,
      'Confirm close stacked trades': undefined,
      'Confirm close trade': undefined,
      'Confirm partial close trade': undefined,
      'Confirm Password': undefined,
      'Confirm your order': undefined,
      'Confirm your password': undefined,
      'Congrats! You won': '🎉 Congrats! You won',
      'Consensus': undefined,
      'Contact us': undefined,
      'Continue': undefined,
      'Continue as guest': undefined,
      'Continue in browser': undefined,
      'Conversion rate': undefined,
      'Conversion rate is indicative': 'Conversion rate is indicative only and may be subject to change.',
      'Copied': undefined,
      'Copied to clipboard': undefined,
      'Copier Limit Exceeded': undefined,
      'Copier_Interval' : '(0)[copiers];(1)[copier];(2-inf)[copiers];',
      'Copiers': undefined,
      'Copy': undefined,
      'Copy Fee': undefined,
      'Copy Fund': undefined,
      'Copy Fund cannot be 0': undefined,
      'Copy Fund cannot be less than equity using by Kaptain': undefined,
      'Copy Fund is negative': 'Your Copy Fund for this Kaptain is negative. New trades will not be copied.',
      'Copy Fund is the total equity used to copy this Kaptain': 'Copy Fund is the total equity used to copy this Kaptain. It will be adjusted according to the realized P/L of your copied trades.',
      'Copy limits updated': undefined,
      'Copy Link': undefined,
      'Copy link to {{where}}': 'Copy link to {{where}}',
      'Copy Payout': undefined,
      'Copy trade failed': undefined,
      'Copy Trading': undefined,
      'Copy Trading Kaptains': 'Copy <1>Trading Kaptains</1>',
      'Copy Trading Rules': undefined,
      'Copying': undefined,
      'Copying since': 'Copying since {{date}}',
      'Country Not Supported': undefined,
      'Country Not Supported Message': 'Deposit from your country is currently not supported.\nPlease try again later.',
      'Country_AE': 'United Arab Emirates',
      'Country_AR': 'Argentina',
      'Country_AT': 'Austria',
      'Country_AU': 'Australia',
      'Country_BE': 'Belgium',
      'Country_BR': 'Brazil',
      'Country_CA': 'Canada',
      'Country_CH': 'Switzerland',
      'Country_CL': 'Chile',
      'Country_CN': 'China',
      'Country_CO': 'Colombia',
      'Country_CZ': 'Czech Republic',
      'Country_DE': 'Germany',
      'Country_DK': 'Denmark',
      'Country_EG': 'Egypt',
      'Country_EMU': 'European M. Union',
      'Country_ES': 'Spain',
      'Country_FI': 'Finland',
      'Country_FR': 'France',
      'Country_GR': 'Greece',
      'Country_HK': 'Hong Kong',
      'Country_HU': 'Hungary',
      'Country_ID': 'Indonesia',
      'Country_IE': 'Ireland',
      'Country_IL': 'Israel',
      'Country_IN': 'India',
      'Country_IS': 'Iceland',
      'Country_IT': 'Italy',
      'Country_JP': 'Japan',
      'Country_KR': 'South Korea',
      'Country_KW': 'Kuwait',
      'Country_MX': 'Mexico',
      'Country_NL': 'Netherlands',
      'Country_NO': 'Norway',
      'Country_NZ': 'New Zealand',
      'Country_PL': 'Poland',
      'Country_PT': 'Portugal',
      'Country_QA': 'Qatar',
      'Country_RO': 'Romania',
      'Country_RU': 'Russia',
      'Country_SA': 'Saudi Arabia',
      'Country_SE': 'Sweden',
      'Country_SG': 'Singapore',
      'Country_SK': 'Slovakia',
      'Country_TH': 'Thailand',
      'Country_TR': 'Türkiye',
      'Country_UA': 'Ukraine',
      'Country_UK': 'United Kingdom',
      'Country_US': 'United States',
      'Country_VN': 'Vietnam',
      'Country_ZA': 'South Africa',
      'Cover image removed successfully': undefined,
      'Cover image uploaded successfully': undefined,
      'Created': undefined,
      'Credit Card': undefined,
      'CS': undefined,
      'Currency': 'Currency',
      'Currency_GHS': 'Ghanaian Cedi',
      'Currency_HKD': 'Hong Kong Dollar',
      'Currency_IDR': 'Indonesian Rupiah',
      'Currency_KES': 'Kenyan Shilling',
      'Currency_MYR': 'Malaysian Ringgit',
      'Currency_THB': 'Thai Baht',
      'Currency_TZS': 'Tanzanian Shilling',
      'Currency_UGX': 'Ugandan Shilling',
      'Currency_USD': 'US Dollar',
      'Currency_VND': 'Vietnamese Dong',
      'Current': undefined,
      'Custom Countries': undefined,
      'Customer Support': undefined,
      'Daily Deposit Limit': undefined,
      'Dashboard': undefined,
      'Date range too large': 'This date range has too many trades, please select a smaller range.',
      'Default': undefined,
      'Delete': undefined,
      'Delete and Notify Author': undefined,
      'Demo account not supported (Bank Account)': 'Demo Account Not Supported',
      'Demo account not supported (Deposit)': 'Demo Account Not Supported',
      'Demo account not supported (Missions)': 'Missions for Live Account Only',
      'Demo account not supported (Stellar Box)': 'Stellar Box for Live Account Only',
      'Demo account not supported (Transaction)': 'Demo Account Not Supported',
      'Demo account not supported (Withdrawal)': 'Demo Account Not Supported',
      'Demo Trade': undefined,
      'Deposit': undefined,
      'Deposit & Withdrawal': undefined,
      'Deposit A fee might be charged': 'A fee might be charged by third-party deposit partners.\nPlease review the fees before making the deposit.',
      'Deposit A transaction fee': 'A {{transactionFee}} transaction fee will be deducted for deposits below {{waiveTransactionFeeAmount}}.',
      'Deposit A transaction fee in percent': 'A {{transactionFeePercent}} transaction fee will be deducted.',
      'Deposit A transaction fee in zero': 'No transaction fees. All deposits go directly to your account.',
      'Deposit A transaction percentage': 'Upon completion of the deposit procedures, a non-refundable {{transactionFeePercent}} transaction fee on your deposit amount will be charged and deducted directly from your deposit amount.',
      'Deposit Account Number One Time Use': 'This account number is valid for one-time use only.',
      'Deposit again': undefined,
      'Deposit Amount': undefined,
      'Deposit Amount (currency)': 'Deposit Amount ({{currency}})',
      'Deposit Checkout New Tab': 'A new tab is opened to continue with the deposit process.\n\nYou will be redirected to the home page in <1>{{time}}</1> seconds or click here to return to home page.',
      'Deposit Currency': undefined,
      'Deposit Fees': undefined,
      'Deposit I have made': 'I have made the deposit',
      'Deposit Instruction': undefined,
      'Deposit Money': undefined,
      'Deposit Monthly Limit': 'You\'ve exceeded the monthly deposit limit of {{monthlyLimit}} per month.\n\nYou will be contacted by email shortly.',
      'Deposit Monthly Limit for Credit Card': 'You\'ve exceeded the monthly deposit limit of {{monthlyLimit}} per month.\n\nYou will be contacted by email shortly.',
      'Deposit Note': 'Note:\n- The value calculated is based on amount after deducting your bank\'s transfer fee.\n- Deposit and withdrawals will go through anti-money laundering checks which may delay the completion time.',
      'Deposit Payment Method BANK_DEPOSIT': 'Bank Deposit',
      'Deposit Payment Method CREDIT_CARD': 'Credit Card',
      'Deposit Payment Method PAYMENT_ASIA': 'Payment Asia',
      'Deposit Payment Method WIRED': 'Wire Transfer',
      'Deposit Pending': undefined,
      'Deposit Please deposit to': 'Please deposit to our bank account using the number below.',
      'Deposit Please make sure': 'Please make sure the debit amount in USD, deposit date, and all account numbers are visible on the receipt.',
      'Deposit Requested': undefined,
      'Deposit Transaction Limit': 'The daily deposit limit is {{dailyQuota}} per day.\n\nYou can deposit <1>{{remainingQuota}}</1> more today.\nPlease try again.',
      'Deposit Transaction Limit for Credit Card': 'The daily deposit limit is {{dailyQuota}} per day.\n\nYou can deposit <1>{{remainingQuota}}</1> more today.\nPlease try again.',
      'Deposit Unsupported Countries Message': 'Unsupported countries/regions: Afghanistan, China, Cote d\'Ivoire, Iran, Japan, North Korea, Russia.',
      'Deposit USD directly': undefined,
      'Deposit When you have': 'When you have made the deposit, let us know by clicking this button:',
      'Deposit You can make': 'You can make deposits through online and phone banking, bank branch, ATM, local and overseas telegraphic transfer.\nMinimum deposit amount is {{minAmount}}.',
      'Deposit Your deposit request': 'Your deposit request has been sent to our team.\nWe will review it and get back to you as soon as we can.',
      'Deposit Your deposit request cancelled': 'Your deposit request has been cancelled.\nYou can try again by clicking Deposit Again below.\nIf the problem persists, please <0>contact us</0>.',
      'Deposit Your deposit request rejected': 'Your deposit request has been rejected.\nPlease contact your card issuer for details.',
      'Description': undefined,
      'Didnt receive code': "Didn't receive the code?",
      'Disclaimer': undefined,
      'Discover': undefined,
      'Display Leaderboard Message': 'Display my performance in leaderboard and trading stats in profile page.',
      'Don\'t Notify me': undefined,
      'Download paragraph': 'Get real time trading alerts, market updates and key financial events on the Pocket Trader app.',
      'Earned on':undefined,
      'Earned Rewards': undefined,
      'Edit': undefined,
      'Edit Comment': undefined,
      'Edit Favourites': undefined,
      'Edit Feed': undefined,
      'Edit group info': undefined,
      'Edit Order': undefined,
      'Edit Position': undefined,
      'Edit Profile': undefined,
      'Edit Trade': undefined,
      'Email': undefined,
      'Email cannot be empty': undefined,
      'Email verified': undefined,
      'Ended.': undefined,
      'Ends in:': undefined,
      'Equity Available': undefined,
      'Equity Available less than Copy Fund': 'Available equity is less than your Copy Fund. Some trades might not be copied.',
      'Equity Left': undefined,
      'Equity Left Definition': 'The equity in your remaining Copy Fund that is available to open new copied trades.',
      'Equity Used': undefined,
      'Equity Used Definition': 'The equity you have used in past copied trades.',
      'Equity Using': undefined,
      'Equity Using Definition': 'The equity in your Copy Fund that is being used to open copied trades.',
      'Error muting channel': undefined,
      'Error unmuting channel': undefined,
      'Est. 4 - 5 days': undefined,
      'Est. Equity Used': undefined,
      'Event': undefined,
      'Event Unavailable': undefined,
      'Event Unavailable Message': 'The event has expired or is unavailable. Please choose an active event from the financial calendar.',
      'Everyone': undefined,
      'Expand': undefined,
      'Expiration': undefined,
      'Expired on': 'Expired on {{expiryDate}}',
      'Expires at': undefined,
      'Expires on': 'Expires on {{expiryDate}}',
      'Failed to change copy trade setting': undefined,
      'Failed to copy to clipboard': undefined,
      'Failed to get copy trade setting': undefined,
      'Failed to get response for uploading avatar': undefined,
      'Failed to get response when removing avatar': undefined,
      'Failed to get response when removing cover image': undefined,
      'Failed to get response when uploading cover image': undefined,
      'FAQ': undefined,
      'Favourites': undefined,
      'Fee': undefined,
      'Feed': undefined,
      'Feed posts': undefined,
      'Feed posts helper text': 'When you have likes, comments, reposts and mentions.',
      'Feedback Submitted': undefined,
      'First deposit': 'You have deposited {{amount}}',
      'Focus Market Empty': 'Browse <1>Focus Markets</1> to discover traders you interested in.',
      'Focus Markets': undefined,
      'Focused Markets': undefined,
      'Followed': undefined,
      'Follower_Interval' : '(0)[followers];(1)[follower];(2-inf)[followers];',
      'Followers': undefined,
      'Following': undefined,
      'For any settings, if the unused Copy Fund is less than needed to copy the Kaptain': 'For any settings, if the unused Copy Fund is less than needed to copy the Kaptain, the trade will not be copied.',
      'For error codes with an asterisk': 'For error codes with an asterisk (*), please contact your bank or card issuer for further assistance.',
      'For You': undefined,
      'Forget Password?': undefined,
      'Frequency': undefined,
      'Frequency_DAY': ' Daily',
      'Frequency_MONTH': 'Monthly',
      'Frequency_QUARTER': 'Quarterly',
      'Frequency_WEEK': 'Weekly',
      'Frequency_YEAR': 'Yearly',
      'From': undefined,
      'Funds requested': undefined,
      'Gainers': undefined,
      'General': undefined,
      'Get App': undefined,
      'Get daily rewards': 'Get daily up to <1>{{amount}}</1> Rewards',
      'Get Rewards': undefined,
      'Global Indices': undefined,
      'Go to Account Setting': 'Go to <1>Account Settings</1> to display my performance.',
      'Go to Markets': undefined,
      'Go to Transaction History': undefined,
      'Gold': undefined,
      'Good Afternoon': undefined,
      'Good Evening': undefined,
      'Good Luck': undefined,
      'Good Morning': undefined,
      'Good till cancelled': undefined,
      'Good till date': undefined,
      'Group creator': undefined,
      'Group members': undefined,
      'Group Name': undefined,
      'GTC': undefined,
      'Guest': undefined,
      'Header Banner': undefined,
      'Hidden Stats - Me': 'You have hidden stats.',
      'Hidden Stats - Trader': 'Trader has hidden stats.',
      'Hidden Trades - Me': 'You have hidden trades.',
      'Hidden Trades - Trader': 'Trader has hidden trades.',
      'Hold the amount until': 'Hold the amount until {{redemptionDate}}',
      'Holidays and Daylight Savings Time': 'Holidays and Daylight Savings Time can affect product trading hours',
      'If all of your Copy Fund is used': 'If all of your Copy Fund is used, new trades from the Kaptain won\'t be copied.',
      'If the equity used by Kaptain exceeds your Min/Max range': 'If the equity used by Kaptain exceeds your Min/Max range, the Min or Max limit will be used to copy the trade. You can set the same amount for both limits.',
      'If you would like to adjust your Stop Loss/Take Profit or use Partial Close, you will need to switch off Auto-pilot.': undefined,
      'Impact': undefined,
      'Impact_HIGH': 'High',
      'Impact_LOW': 'Low',
      'Impact_MEDIUM': 'Mid',
      'In-app notifications': undefined,
      'In-app notifications blocked': 'Notifications blocked. Please turn on browser notifications for Pocket Trader',
      'In-app notifications helper text': 'To view in-app notifications, please allow browser notifications for Pocket Trader',
      'Includes at least one number': undefined,
      'Includes uppercase and lowercase letters': undefined,
      'Ineligible to get reward': 'Your total one-off deposit does not meet the minimum requirement.',
      'Input a valid email here': undefined,
      'Input an Username': undefined,
      'Input Your First Name': undefined,
      'Input Your Last Name': undefined,
      'Instant': undefined,
      'Instrument': undefined,
      'Instrument Info': undefined,
      'Instrument Name': undefined,
      'Instrument not exist': 'The instrument you are looking for does not exists',
      'Instruments': undefined,
      'Invalid GTD': undefined,
      'Invalid input for Maximum per trade or Minimum per trade': undefined,
      'Invalid price, the price must be higher than the current market price.': undefined,
      'Invalid price, the price must be lower than the current market price.': undefined,
      'Invalid Stop Loss Price': undefined,
      'Invalid Take Profit Price': undefined,      
      'Join our community now to enjoy exclusive benefits': undefined,
      'Kaptain': undefined,
      'Kaptain doesn\'t exist.': undefined,
      'Kaptain\'s trades': undefined,
      'Kaptain\'s trades helper text': 'When a Kaptain opens or closes their trades.',
      'Last {{numOfMonths}} Months': undefined,
      'Last {{numOfMonths}} months performance': undefined,
      'Last 3 Months': undefined,
      'Last 6 Months': undefined,
      'Last 12 Months': undefined,
      'Last Month': undefined,
      'Last Quote Time': undefined,
      'Last Week': undefined,
      'Latest Posts': undefined,
      'Leaderboard': undefined,
      'Leaderboard and trading stats': undefined,
      'Leave group': undefined,
      'Leave group content': 'Are you sure you want to leave the group? You will no longer have access to group messages and updates.',
      "Let's deposit money!": undefined,
      "Let's start trading": undefined,
      'Like': undefined,
      'Liked': undefined,
      'Limit Order': undefined,
      'Limit Price': undefined,
      'Limit/Stop Price': undefined,
      'Live Trade': undefined,
      'Live Trade Only': 'Live trading performances only.\nSign up for a live account to show off your results!',
      'Log In': undefined,
      'Log in now to start using Pocket Trader!': undefined,
      'Log Out': undefined,
      'Login or Signup': '<0>Log in</0> or <2>Sign up</2>',
      'Losers': undefined,
      'Loss': undefined,
      'lots': undefined,
      'Make admin': undefined,
      'Make admin content': 'Are you sure you want to make {{name}} an admin? They will be able to manage group members and group settings.',
      'Manual Close': undefined,
      'Margin': undefined,
      'Margin Level': undefined,
      'Margin Stop Out': undefined,
      'Mark all as read': undefined,
      'Market': undefined,
      'Market Closed': undefined,
      'Market News': undefined,
      'Market Order': undefined,
      'Markets': undefined,
      'Markets now': undefined,
      'Max Leverage Information': undefined,
      'Maximize your potential':'Maximize your potential',
      'Maximum deposit': 'Maximum deposit: {{amount}}',
      'Maximum per trade': undefined,
      'Maximum per trade cannot be larger than Copy Fund': undefined,
      'Maximum withdrawal': 'Maximum withdrawal: {{maxAmount}}',
      'Member': undefined,
      'Members': undefined,
      'Message': undefined,
      'Method': undefined,
      'Migration Description': 'Upgrade Live Account to enjoy 500x leverage! With higher leverage, you can amplify your trading positions and increase your returns.',
      'Min / Max': undefined,
      'Min/Max': undefined,
      'Min/Max Trading Volume': undefined,
      'Minimum deposit': 'Minimum deposit: {{amount}}',
      'Minimum per trade': undefined,
      'Minimum per trade cannot be larger than Copy Fund': undefined,
      'Minimum per trade cannot be larger than Maximum per trade': undefined,
      'Minimum per trade or Maximum per trade cannot be 0': undefined,
      'Minimum size 150x150': undefined,
      'Minimum size 1636x273': undefined,
      'Minimum withdrawal': 'Minimum withdrawal: {{minAmount}}',
      'Minimum XXX': 'Minimum {{minAmount}}',
      'Mission start': undefined,
      'Missions end in:': undefined,
      'Missions ended.': undefined,
      'Missions start in:': undefined,
      'Month': undefined,
      'Most Active': undefined,
      'Most Copiers': undefined,
      'Most Followers': undefined,
      'Most Traded': undefined,
      'Move to top': undefined,
      'Mute notifications': undefined,
      'My Badges':undefined,
      'My badges':undefined,
      'My Feed Empty': 'Browse <1>Social Feed</1> to discover traders\' ideas.',
      'My Missions': undefined,
      'My Stellar Rewards': undefined,
      'My Trades': undefined,
      'N/A': undefined,
      'Name your group': undefined,
      'New chat': undefined,
      'New copier': undefined,
      'New copier helper text': 'When someone has started copying your trades.',
      'New followers': undefined,
      'New followers helper text': 'When someone has followed you.',
      'New Trade Followers': undefined,
      'News': undefined,
      'Next': undefined,
      'Next Month': undefined,
      'Next Release': undefined,
      'Next Week': undefined,
      'No Active Kaptains': undefined,
      'No available campaign': undefined,
      'No campaign available now.': undefined,
      'No Chats': undefined,
      'No credit card': undefined,
      'No data to display': undefined,
      'No events found': undefined,
      'No Kaptains': undefined,
      'No Kaptains Message': 'Go to <1>Leaderboard</1> to discover potential Kaptains to copy.',
      'No match': undefined,
      'No Messages': undefined,
      'No notifications yet.': undefined,
      'No Past Kaptains': undefined,
      'No people found': undefined,
      'No Related News': undefined,
      'No rewards in this period': undefined,
      'No Search Result': 'Your search did not match any {{type}} results.',
      'No spaces and non-standard characters': undefined,
      'No Stats': undefined,
      'No traders.': undefined,
      'No trades found.': undefined,
      'No trades in this period.': undefined,
      'No transactions found.': undefined,
      'Not copying': undefined,
      'Not earned yet':undefined,
      'Not enough available equity': undefined,
      'Not enough equity available': undefined,
      'Not Found': 'The resource you were looking for could not be found.',
      'Notifications': undefined,
      'Notify Me on new trades': undefined,
      'Notional': undefined,
      'Notional Value': undefined,
      'Notional Value definition': 'Notional value is calculated by multiplying units/contracts and instrument price in USD.',
      'Oil': undefined,
      'OK': undefined,
      'Online': undefined,
      'Only notify me on live trades': undefined,
      'Only notify me on live trades helper text': 'You won’t be notified on demo trades.',
      'Open': undefined,
      'Open 5 live trades to get a Stellar Box': undefined,
      'Open copied trades': undefined,
      'Open emoji picker': undefined,
      'Open more': undefined,
      'Open Price': undefined,
      'Open Time': undefined,
      'Open Trade': undefined,
      'Open Trades': undefined,
      'Open/Prev Close': undefined,
      'Optional': undefined,
      'Order estimate': 'Estimate using',
      'Order executed': undefined,
      'Order Executed': undefined,
      'Order executed helper text': 'When your limit or stop order has opened, or any orders closed by Stop Loss/Take Profit.',
      'Order ID': undefined,
      'Order Placed': undefined,
      'Order placed': undefined,
      'Order type': undefined,
      'Others': undefined,
      'P&L': undefined,
      'P/L': undefined,
      'Partial Close': undefined,
      'Password': undefined,
      'Past 12 months performance': undefined,
      'Past Copiers': undefined,
      'Past Copy Funds Used': undefined,
      'Past Copy Funds Used Def': 'The total equity used by past copiers to copy this Kaptain.',
      'Past Kaptain': undefined,
      'Past Kaptains': undefined,
      'Payee Address': undefined,
      'Pending box definition': "Pending Stellar Boxes can be opened on the next day starting from 21:00 UTC. We'll notify you when they are ready.",
      'Pending Trade': undefined,
      'Pending Trades': undefined,
      'People': undefined,
      'Pin': undefined,
      'Pin to page': undefined,
      'Pinned': undefined,
      'Place Buy Order': undefined,
      'Place order': undefined,
      'Place Sell Order': undefined,
      'Please check your filters.': undefined,
      'Please enter a valid email': undefined,
      'Please input multiple of step volume': undefined,
      'Please login or signup first': undefined,
      'Please select a date range within 31 days': undefined,
      'Please select a live account (Bank Account)': 'Please select a live account to view your bank accounts.',
      'Please select a live account (Deposit)': 'Please select a live account to deposit money into.',
      'Please select a live account (Missions)': 'Please switch to live account to complete the missions.',
      'Please select a live account (Stellar Box)': 'Please switch to live account to receive Stellar Boxes.',
      'Please select a live account (Transaction)': 'Please select a live account to check transaction history.',
      'Please select a live account (Withdrawal)': 'Please select a live account to withdraw money from.',
      'Please select other options.': undefined,
      'Please set the Copy Fund to copy trades': 'Please set the Copy Fund that will be used to copy {{username}}\'s trades.',
      'Please sign up a live account (Bank Account)': 'Please sign up a live account to view your bank accounts.',
      'Please sign up a live account (Deposit)': 'Please sign up a live account to deposit money into.',
      'Please sign up a live account (Missions)': 'Please sign up a live account to complete the missions.',
      'Please sign up a live account (Stellar Box)': 'Please sign up a live account to receive Stellar Boxes.',
      'Please sign up a live account (Transaction)': 'Please sign up a live account to check transaction history.',
      'Please sign up a live account (Withdrawal)': 'Please sign up a live account to withdraw money from.',
      'Please sign up a live account to view this trade': undefined,
      'Please specify an amount higher than or equal to amount': 'Please specify an amount higher than or equal to {{amount}}.',
      'Please specify an amount lower than amount': 'Please specify an amount lower than {{amount}}.',
      'Please upgrade to new license to (Missions)': 'Please upgrade to new license to complete the missions.',
      'Please upgrade to new license to (Stellar Box)': 'Please upgrade to new license to receive Stellar Boxes.',
      'Please upload a file less than 5MB.': undefined,
      'Please upload a image less than {{fileUpdateSizeLimit}}': undefined,
      'Please upload each file < 2mb': undefined,
      'Please upload images / pdf only.': undefined,
      'Please upload your bank statement': 'Please upload your bank statement showing your name, address and account details.',
      'Please upload your bank transfer receipt.': undefined,
      'Pocket Trader.': undefined,
      'Portfolio': undefined,
      'Position Closed': undefined,
      'Position closed': undefined,
      'Position ID': undefined,
      'Post': undefined,
      'Preview': undefined,
      'Previous': undefined,
      'Privacy Policy': undefined,
      'Profile': undefined,
      'Profile Picture': undefined,
      'Profit': undefined,
      'Profitable': undefined,
      'Proof of bank account': undefined,
      'Public': undefined,
      'Purpose of Remittance': undefined,
      'Ratio': undefined,
      'Ratio per trade': undefined,
      'Ratio per trade must be between 1.00% and {{maxTradeRatioPercentage}}.': undefined,
      'Reached the maximum copier limit':'{{kaptainName}} has reached the maximum copier limit. Please copy another Kaptain.',
      'React': undefined,
      'Read': 'read',
      'Read more': undefined,
      'Received From You': undefined,
      'Receiving Method': undefined,
      'Recent & Next': undefined,
      'Recents': undefined,
      'Refer a friend': undefined,
      'Refer Copy Link Message': 'Hey, I use Pocket Trader for social trading and it\'s amazing! Use this link to sign up and start copying my trades. Let\'s make great profits together:',
      'Refer Link Header': 'Your referral link',
      'Refer Message': 'Earn a bonus of $50 USD when you refer a friend to Pocket Trader!',
      'Refer Step 1': 'Sign up with your link',
      'Refer Step 1 details': 'Verify their identity and get a live account',
      'Refer Step 2': 'Deposit to their account',
      'Refer Step 2 details': 'Make a minimum deposit of $100 USD',
      'Refer Step 3': 'Complete 10 transactions',
      'Refer Step 3 details': 'Eg: Open 10 trades, or open and close 5 trades',
      'Refer Step Header': 'Steps for your friends',
      'Referral Code': undefined,
      'Referral program T&C': 'Terms & Conditions',
      'Referral T&C prefix': '*',
      'Referral T&C suffix': 'apply.',
      'Refill not available': 'The request is only available when your balance is below $10,000',
      'Refresh': undefined,
      'Related News': undefined,
      'Remarks': undefined,
      'Remove': undefined,
      'Remove admin': undefined,
      'Remove admin content': 'Are you sure you want to remove {{name}} as an admin? They will no longer able to manage group members and group settings.',
      'Remove as admin': undefined,
      'Remove from group': undefined,
      'Remove group photo': undefined,
      'Remove member': undefined,
      'Remove member content': 'Are you sure you want to remove {{name}} from the group? They will no longer be able to send or receive new messages.',
      'Reply': undefined,
      'Reply to': undefined,
      'Report': undefined,
      'Repost to my profile': undefined,
      'Required': undefined,
      'Resend code': undefined,
      'Resend code in': undefined,
      'Resend link': 'to log in again and we’ll resend a new link to your email.',
      'Reset': undefined,
      'Reset Password': undefined,
      'Reward box count': 'in {{count}} boxes',
      'Reward Breakdown': undefined,
      'Reward History': undefined,
      'Reward Type': undefined,
      'rewards': undefined,
      'Rewards hub': undefined,
      'Rocket To Reward': undefined,
      'Rocket To Rewards': undefined,
      'ROE': undefined,
      'ROE Made': undefined,
      'ROE on a single trade': undefined,
      'ROE on Commodities': undefined,
      'ROE on FX': undefined,
      'Rules and Terms': undefined,
      'Save': undefined,
      'Save Account': undefined,
      'Saved': undefined,
      'Scroll to zoom in/out': undefined,
      'Search': undefined,
      'Search for traders': undefined,
      'Security': undefined,
      'Security Settings': undefined,
      'See all': undefined,
      'See all chats': undefined,
      'See all notifications': undefined,
      'See less': undefined,
      'See more': undefined,
      'Seen by': undefined,
      'Select a message': undefined,
      'Select bank transfer to withdraw': undefined,
      'Select Date': undefined,
      'Sell': undefined,
      'Sell Limit': undefined,
      'Sell Stop': undefined,
      'Send': undefined,
      'Send gif fail': 'Failed to send gif, please try again',
      'Sender Address': undefined,
      'Sender City': undefined,
      'Sender Post Code': undefined,
      'Sender State': undefined,
      "Sending": undefined,
      'Sent To You': undefined,
      'Session Expired': undefined,
      'Session Expired Action Reminder': 'Please refresh the page and login again',
      'Set Alert': undefined,
      'Set the equity limit to be used for each trade': undefined,
      'Share': undefined,
      'Share All': undefined,
      'Share Event': undefined,
      'Share this Order': undefined,
      'Share your ideas': undefined,
      'Show notional value': undefined,
      'Show summary for closed single trades': undefined,
      'Show summary for closed stacked trades': undefined,
      'Show summary for opened trades': undefined,
      'Sign Up': undefined,
      'Sign up demo': 'You are using guest mode now. To use the full version, please sign up for a demo account.',
      'Sign up for a new account': undefined,
      'Sign Up Live': undefined,
      'Sign Up Live Account': undefined,
      'Sign up message': 'Please enter the 6-digit verification code sent to',
      'Sign up now': undefined,
      'Sign up or log in to share your ideas!': undefined,
      'Single trade limit': undefined,
      'SLTP Black New': '<0>Black</0> - Your equity used.',
      'SLTP Black Update': '<0>Black</0> - Equity used by Kaptain.',
      'SLTP Green': '<0>Green</0> - Within your 80% equity available.',
      'SLTP Kopy Trade': 'You cannot copy a Kaptain when your equity available is below 0.',
      'SLTP Red': '<0>Red</0> - Above your 80% equity available.',
      'SLTP Regular Trade': 'Order cannot be placed when your equity available is below 0.',
      'Social Feed': undefined,
      'Social notifications': undefined,
      'Something went wrong': undefined,
      'Source': undefined,
      'Start': undefined,
      'Start a new conversation': undefined,
      'Start chat': undefined,
      'Start copy trade': undefined,
      'Start trading': undefined,
      'Starts in:': undefined,
      'Stats': undefined,
      'Stay signed in': undefined,
      'Stellar Box': undefined,
      'Stellar Boxes': 'Stellar Boxes <1>x {{boxCount}}</1>',
      'Stop Auto-pilot': undefined,
      'Stop copy trade': undefined,
      'Stop copy trade failed': undefined,
      'Stop copying': undefined,
      'Stop Loss': undefined,
      'Stop Loss Triggered': undefined,
      'Stop Order': undefined,
      'Stop Price': undefined,
      'Strive for greater rewards next time!': undefined,
      'Subject': undefined,
      'Submit': undefined,
      'Support hotline:': undefined,
      'Support Request': undefined,
      'Swap': undefined,
      'Swap Definition': 'A swap refers to the interest that you either earn or pay for a trade that you keep open overnight.',
      'Swap Long/Short': undefined,
      'Swift Code': undefined,
      'Swift code is 8 to 11 characters': 'A SWIFT code is 8 to 11 characters',
      'Switch Account': undefined,
      'Switch Account Message': 'Please switch to {{targetAccountType}} Account to view this trade',
      'Switch Account Notify': 'Switched to {{accountType}} Account',
      'Switch to Live Account': undefined,
      'Symbol': undefined,
      'T&C': 'Terms and Conditions',
      'Tag @Users, $instruments or use #hashtags': undefined,
      'Take Profit': undefined,
      'Take Profit Triggered': undefined,
      'Tell other traders something about you!': undefined,
      'Terms & Conditions': undefined,
      'The latest two earned badges will be featured.': undefined,
      'Their future trades will not be copied': undefined,
      'This Month': undefined,
      'This Week': undefined,
      'To': undefined,
      'Today': undefined,
      'Today\'s high': undefined,
      'Today\'s High/Low': undefined,
      'Today\'s low': undefined,
      'Tomorrow': undefined,
      'Top Gainers': undefined,
      'Top Losers': undefined,
      'Top Movers': undefined,
      'Trade': undefined,
      'Trade completion': undefined,
      'Trade completion message': 'Display a completion summary after you’ve opened or closed any trades.',
      'Trade confirmation': undefined,
      'Trade confirmation message': 'Display a confirm message before you open or close a trade at market price.',
      'Trade details': undefined,
      'Trade in your pocket 24/7': undefined,
      'Trade notifications': undefined,
      'Trade whatever they are trading, automatically': 'trade whatever they are trading, automatically',
      'Trader': 'trader',
      'Trader doesn\'t have any posts.': undefined,
      'Trader doesn\'t have any trades.': undefined,
      'Traders': 'traders',
      'Trades': undefined,
      'Trades Until Next Box': undefined,
      'Trading': undefined,
      'Trading Credits': 'Power Token',
      'Trading Credits Abb': 'P-Token',
      'Trading credits definition': 'Power Token increases your equity available until it expires, staying at a 1:1 ratio with your balance if it drops below your Power Token.',
      'Trading Hours': undefined,
      'Trailing SL': undefined,
      'Trailing Stop Loss': undefined,
      'Transaction Fee': undefined,
      'Transaction History': undefined,
      'Transaction ID': undefined,
      'Trending': undefined,
      'Trending Now': undefined,
      'Turning off Auto-pilot': 'Turning off Auto-pilot lets you Partial Close and edit Stop Loss/Take Profit for this trade. It will not auto close when the Kaptain closes their trade.',
      'Type a message': undefined,
      'Type in your message': undefined,
      'Type to search for traders': undefined,
      'Unit': undefined,
      'Units/Contracts': undefined,
      'Unmute': undefined,
      'Unpin': undefined,
      'Unreport': undefined,
      'Updated': undefined,
      'Upgrade Live Account to enjoy 500x leverage!': undefined,
      'Upgrade now': undefined,
      'Upload avatar successfully': undefined,
      'Upload bank statement': undefined,
      'Upload Receipt': undefined,
      'Username': undefined,
      'Username already exists.': undefined,
      'Username must be numbers or letters only (0-9, A-Z, a-z)': undefined,
      'Using of your equity': 'Using {{percentage}}% of your equity',
      'Value': undefined,
      'Verification Code': undefined,
      'Verify your email': undefined,
      'Video': undefined,
      'View All Results': undefined,
      'View copied trades': undefined,
      'View more comments': undefined,
      'View profile': undefined,
      'View trade': undefined,
      'Virtual Funds': undefined,
      'Virtual Funds Requested': undefined,
      'We convert USD for you': undefined,
      'We will reply to you as soon as possible.': undefined,
      'We\'ve sent your password reset instructions to': undefined,
      'Week': undefined,
      'Welcome to Copy Trading': undefined,
      'What\'s going on with this post': undefined,
      'What\'s New': undefined,
      'What\'s so special about this event?': undefined,
      'Win': undefined,
      'Win Rate': undefined,
      'Win up to Bonus Rewards': 'Win up to {{amount}} Bonus Rewards',
      'Wins': undefined,
      'Wire Transfer': undefined,
      'Withdraw': undefined,
      'withdraw: insufficient funds': "Insufficient funds. Your equity available is {{amount}}",
      'Withdrawal': undefined,
      'Withdrawal A transaction fee': 'A {{transactionFee}} transaction fee will be deducted.',
      'Withdrawal A transaction fee in percent': 'A {{transactionFeePercent}} transaction fee will be deducted.',
      'Withdrawal A transaction fee in zero': 'No transaction fees. All withdrawals go directly to your account.',
      'Withdrawal Account': undefined,
      'Withdrawal Amount': 'Withdrawal Amount',
      'Withdrawal Destination': undefined,
      'Withdrawal Fees': undefined,
      'Withdrawal Limit': undefined,
      'Withdrawal limit is the deposited': 'Withdrawal limit is the deposited amount per credit card within {{days}} days. Profits exceeding the limit must be withdrawn to a bank account.',
      'Withdrawal Note': 'Note:\n- Your withdrawal fund will be frozen once you submit the request.\n- Deposit and withdrawals will go through anti-money laundering checks which may delay the completion time.',
      'Withdrawal Please submit': 'Please submit a withdrawal request.',
      'Withdrawal Policy Agree': 'I have read and understood PocketTrader’s <1>Withdrawal Policy</1> by submitting the withdrawal request.',
      'Withdrawal reminder': 'You can withdraw funds up to the minimum value of your balance or equity available.\nPlease ensure that sufficient margins are available to avoid stop out.',
      'Withdrawal Your request for': 'Your request for withdrawal is submitted.',
      'Write a comment': undefined,
      'Yesterday': undefined,
      'You': undefined,
      'You agree to our Terms & Conditions when you continue': 'You agree to our <1>Terms & Conditions</1> when you continue',
      'You are not logged in.': undefined,
      'You are now copying user': 'You are now copying {{friendlyName}}',
      'You can add an additional bank account': 'You can add an additional bank account for withdrawals. If you want to add more, just deposit into Pocket Trader and they will appear here as options.',
      'You can change it later': undefined,
      'You can withdraw to your accounts below.': undefined,
      'You cannot undo this': 'You can\'t undo this.',
      'You don\'t have any favourite instruments.': undefined,
      'You don\'t have any posts.': undefined,
      'You don\'t have any trades.': undefined,
      'You have': undefined,
      'You have no trades yet': undefined,
      'You have stopped copying user': 'You have stopped copying {{friendlyName}}',
      'You have to close all the copied trades from them before you can copy again': 'You have to close all the copied trades from them before you can copy again.',
      'You still have open copied trades from KaptainName': 'You still have open copied trades from {{kaptainName}}.',
      'You\'ve reached the maximum number of files': undefined,
      'Your account only have withdraw limits': 'Your account only have {{amount}} to withdraw.',
      'Your email': undefined,
      'Your email goes here': undefined,
      'Your email is verified.': undefined,
      'Your feedback has been sent to support team.': undefined,
      'Your Message': undefined,
      'Your open copied trades will remain open': undefined,
      'Your position is closed.': undefined,
      'Your request for virtual funds may take up to 48 hours': undefined,
    }
  },
};

export default Translation;
