import { accountTypeNames } from '@aqt/pt-api/constants';
import { ImpactType, Resolution } from '@aqt/pt-api/types';

import { Sentiment } from './Types';

const accountTypeLabels = Object.entries(accountTypeNames);

const accountStatsLabels = Object.fromEntries(accountTypeLabels.map(([k, v]) => [k, `${v} Stats`]));

const accountTradeLabels = Object.fromEntries(accountTypeLabels.map(([k, v]) => [k, `${v} Trade`]));

const accountTypeOptions = accountTypeLabels.map(([k, v]) => ({ value: k, label: `${v} Account` }));

const basePath = ''; // '/web2.0'

const defaultImpacts: ImpactType[] = ['HIGH', 'MEDIUM'];

const fileUpdateSizeLimit = 5 * 1024 * 1024; // 5MB

const lots = 100000;
const lotsDecimalPlaces = 2;

const maxTextFieldLength = 255;
const maxUsernameLength = 20;

const pageSize = 20;

const periodHoursLabel = {
  1: '1H',
  4: '4H',
  24: '1D',
  720: '1M',
  2160: '3M',
};

const profileTimeFilterOptions = {
  IN_6_MONTHS: '6 Months',
  IN_12_MONTHS: '12 Months',
};

const resolutionByPeriodHours: { [periodHours: number]: Resolution } = {
  1: 'MINUTE_5',
  4: 'MINUTE_15',
  24: 'HOUR_1',
  720: 'DAY_1',
  2160: 'WEEK_1',
};

const sentiments: Sentiment[] = ['Bullish', 'Bearish', 'Neutral', 'None'];

const timeFilterOptions = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  SEVEN_DAYS: '7 Days',
  THIS_WEEK: 'This Week',
  LAST_WEEK: 'Last Week',
  THIRTY_DAYS: '30 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  LAST_3_MONTHS: 'Last 3 Months',
  LAST_6_MONTHS: 'Last 6 Months',
  LAST_12_MONTHS: 'Last 12 Months',
};

const globalIndices = [
  { id: 'INDEX.US30R', name: 'US30' },
  { id: 'INDEX.US500R', name: 'US500' },
  { id: 'INDEX.UK100R', name: 'UK100' },
  { id: 'BBG003RZT7Q1', name: 'HK50' },
];

const userDisplayLimit = 20;

export const dollarTagRegex = /\$\[(.*?)\]\((.*?)\)/g;
export const hashTagRegex = /#\S+/g;
export const mentionTagRegex = /@\[(.*?)\]\((.*?)\)/g;

export const usernameBeginWithLetterOrNumberRegex = /^[a-zA-Z\d].{1,20}$/g;
export const usernameRegex = /^[a-zA-Z\d](?=.*[a-zA-Z\d._-])[a-zA-Z0-9._-]{1,20}$/g;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const virtualPayLocalPaymentSuccessCode: string[] = ['0', '2'];
// 0 is transaction is successful 2 transaction is in processing

const virtualPaySuccessCode: string[] = ['0', '00'];

export {
  accountStatsLabels,
  accountTradeLabels,
  accountTypeOptions,
  basePath,
  defaultImpacts,
  fileUpdateSizeLimit,
  globalIndices,
  lots,
  lotsDecimalPlaces,
  maxTextFieldLength,
  maxUsernameLength,
  pageSize,
  periodHoursLabel,
  profileTimeFilterOptions,
  resolutionByPeriodHours,
  sentiments,
  timeFilterOptions,
  userDisplayLimit,
  virtualPayLocalPaymentSuccessCode,
  virtualPaySuccessCode,
};
