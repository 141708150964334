import { API_CONFIG } from '@aqt/pt-api';
import {
  Account,
  BasicUser,
  BuySell,
  ClosedPosition,
  ClosePositionsResponse,
  CopierStats,
  FocusedMarketStats,
  KopyStatus,
  KopySubscribeRequest,
  KopyTradeSetting,
  KopyTradeSummary,
  LeaderboardResponse,
  LeaderboardUserStats,
  newTraderStatus,
  Order,
  OwnTradesStatsResponse,
  Page,
  PageV2,
  PastPerformance,
  Position,
  RegulatedTrade,
  SingleKopyTradeSummary,
  TraderAccount,
  TraderStatus,
  TradingResponse,
  Transaction,
  TransactionOverviewResponse,
  UserBadge,
} from '@aqt/pt-api';
import { isNumeric } from '@aqt/pt-api';

import { fetchJson, fetchMarketDataJson, fetchResponse, fetchText } from './apiUtils';

const scaleUpFactor = 'e8';
const scaleDownFactor = 'e-8';

const scaleUp = <T extends number | null | undefined>(num: T): T extends number ? number : null => {
  return num != null ? (Number(`${num}` + scaleUpFactor) as any) : num;
};

const scaleDown = <T extends number | null | undefined>(num: T): T extends number ? number : null => {
  return num != null ? (Number(`${num}` + scaleDownFactor) as any) : num;
};

const scaleDownTraderStatusOrder = (order: Order) => ({
  ...order,
  val: scaleDown(order.val),
  limitPc: scaleDown(order.limitPc),
  stopPc: scaleDown(order.stopPc),
  tradedVal: scaleDown(order.tradedVal),
  slPc: scaleDown(order.slPc),
  tpPc: scaleDown(order.tpPc),
  curPc: scaleDown(order.curPc),
  slPnl: scaleDown(order.slPnl),
  tpPnl: scaleDown(order.tpPnl),
});

const scaleDownTraderStatusPosition = (position: Position) => ({
  ...position,
  val: scaleDown(position.val),
  avgPc: scaleDown(position.avgPc),
  slPc: scaleDown(position.slPc),
  tpPc: scaleDown(position.tpPc),
  curPc: scaleDown(position.curPc),
  slPnl: scaleDown(position.slPnl),
  tpPnl: scaleDown(position.tpPnl),
  margUsed: scaleDown(position.margUsed),
  pnl: scaleDown(position.pnl),
  swap: scaleDown(position.swap),
});

const scaleDownTraderStatusTransaction = (transaction: Transaction) => ({
  ...transaction,
  value: scaleDown(transaction.value),
  entryPrice: scaleDown(transaction.entryPrice),
  price: scaleDown(transaction.price),
  marginUsed: scaleDown(transaction.marginUsed),
  commission: scaleDown(transaction.commission),
  swap: scaleDown(transaction.swap),
  pnl: scaleDown(transaction.pnl),
  balance: scaleDown(transaction.balance),
});

const scaleDownTraderStatus = (traderStatus: TraderStatus) => ({
  ...newTraderStatus(),
  orders: traderStatus.orders.map(scaleDownTraderStatusOrder),
  positions: traderStatus.positions.map(scaleDownTraderStatusPosition),
  balance: scaleDown(traderStatus.balance),
  latestCashflowTimestamp: traderStatus.latestCashflowTimestamp,
  latestTransactionTimestamp: traderStatus.latestTransactionTimestamp,
  unrealizedPnl: scaleDown(traderStatus.unrealizedPnl),
  marginAvailable: scaleDown(traderStatus.marginAvailable),
  marginUsed: scaleDown(traderStatus.marginUsed),
  nav: scaleDown(traderStatus.nav),
});

const scaleDownTradingResponse = (v: TradingResponse) => ({
  ...v,
  executionPrice: scaleDown(v.executionPrice),
  marginUsed: scaleDown(v.marginUsed),
  pnl: scaleDown(v.pnl),
});

export const addVirtualFund = async (accountType: string, accountId: string) => {
  return await fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/tradingAccounts/${accountId}/refill`,
    await API_CONFIG.accessToken,
    {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );
};

export const amendPosition = async (
  accountType: string,
  accountId: string,
  positionId: string,
  stopLossPrice: number | null,
  takeProfitPrice: number | null,
  isTrailingStopLoss: boolean
) => {
  const response = await fetchJson<TradingResponse>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trade_feed_${encodeURIComponent(accountType.toLowerCase())}/v1/accounts/${encodeURIComponent(
      accountId
    )}/positions/${positionId}`,
    {
      body: JSON.stringify({
        stopLossPrice: scaleUp(stopLossPrice),
        takeProfitPrice: scaleUp(takeProfitPrice),
        trailingStopLoss: isTrailingStopLoss,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    }
  );

  if (response.code !== 0) {
    return Promise.reject({ message: response.message, code: response.code });
  }

  return scaleDownTradingResponse(response);
};

export const cancelOrder = async (accountType: string, accountId: string, orderId: string) => {
  return scaleDownTradingResponse(
    await fetchJson<TradingResponse>(
      `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trade_feed_${encodeURIComponent(accountType.toLowerCase())}/v1/accounts/${encodeURIComponent(
        accountId
      )}/orders/${encodeURIComponent(orderId)}`,
      {
        method: 'DELETE',
      }
    )
  );
};

export const closePosition = async (accountType: string, accountId: string, positionId: string, value: number) => {
  const response = scaleDownTradingResponse(
    await fetchJson<TradingResponse>(
      `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trade_feed_${encodeURIComponent(accountType.toLowerCase())}/v1/accounts/${encodeURIComponent(
        accountId
      )}/positions/${encodeURIComponent(positionId)}/close`,
      {
        body: JSON.stringify({ value: scaleUp(value) }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      }
    )
  );

  if (response.code !== 0) {
    return Promise.reject({ message: response.message, code: response.code });
  }
  return response;
};

export const closePositions = async (accountType: string, accountId: string, positionIds: string[]) => {
  const response = await fetchJson<ClosePositionsResponse>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trade_feed_${encodeURIComponent(accountType.toLowerCase())}/v1/accounts/${accountId}/positions/closeMultiple`,
    {
      body: JSON.stringify(positionIds),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );

  if (response.code !== 0) {
    return Promise.reject({ message: response.message, code: response.code });
  }

  return {
    ...response,
    entryPrice: scaleDown(response.entryPrice),
    closePrice: scaleDown(response.closePrice),
    value: scaleDown(response.value),
    marginUsed: scaleDown(response.marginUsed),
    pnl: scaleDown(response.pnl),
  };
};

export const createOrder = async (
  accountType: string,
  accountId: string,
  instrumentId: string,
  buySell: BuySell,
  qty: number,
  limitPriceString: string | null,
  stopPriceString: string | null,
  expiryTimestamp: number | null,
  isStopLoss: boolean = false,
  stopLossPriceString: string | null = null,
  isTakeProfit: boolean = false,
  takeProfitPriceString: string | null = null,
  isTrailingStopLoss: boolean = false,
  orderId?: string,
  isEdit = false
) => {
  const limitPrice = limitPriceString ? scaleUp(Number(limitPriceString)) : null;
  const stopPrice = stopPriceString ? scaleUp(Number(stopPriceString)) : null;
  const orderId_p = isEdit ? `/${orderId}` : '';
  const response = await fetchJson<TradingResponse>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trade_feed_${encodeURIComponent(accountType.toLowerCase())}/v1/accounts/${accountId}/orders${orderId_p}`,
    {
      body: JSON.stringify({
        buySell,
        instrumentId,
        limitPrice,
        orderType:
          limitPrice != null && isNumeric(limitPrice) ? 'LIMIT' : stopPrice != null && isNumeric(stopPrice) ? 'STOP' : 'MARKET',
        stopPrice,
        stopLoss: isStopLoss,
        stopLossPrice: stopLossPriceString ? scaleUp(Number(stopLossPriceString)) : null,
        takeProfit: isTakeProfit,
        takeProfitPrice: takeProfitPriceString ? scaleUp(Number(takeProfitPriceString)) : null,
        value: scaleUp(qty),
        expiryTimestamp,
        id: orderId,
        trailingStopLoss: isTrailingStopLoss,
      }),
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: !isEdit ? 'POST' : 'PUT', // Create / Edit
    }
  );

  if (response.code !== 0) {
    return Promise.reject({ message: response.message, code: response.code });
  }

  return scaleDownTradingResponse(response);
};

let estimateOrderAbortController = new AbortController();

export const estimateOrder = async (
  accountType: string,
  accountId: string,
  buySell: string,
  orderType: string,
  limitPrice: number | null,
  stopPrice: number | null,
  instrumentId: string,
  value: number,
  stopLossPnl: number | null,
  stopLossPrice: number | null,
  takeProfitPnl: number | null,
  takeProfitPrice: number | null,
  allowDuplicate = true
) => {
  // abort duplicate requests
  if (!allowDuplicate) estimateOrderAbortController.abort();
  estimateOrderAbortController = new AbortController();
  const signal = estimateOrderAbortController.signal;
  const estimate = await fetchJson<{
    marginLevel: number | null;
    marginUsed: number;
    stopLossPnl: number;
    stopLossPrice: number;
    takeProfitPnl: number;
    takeProfitPrice: number;
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/orders/estimate`, {
    body: JSON.stringify({
      accountId,
      order: {
        buySell,
        orderType,
        limitPrice: scaleUp(limitPrice),
        stopPrice: scaleUp(stopPrice),
        instrumentId,
        value: scaleUp(value),
        stopLossPnl: stopLossPnl !== null ? scaleUp(stopLossPnl) : null,
        stopLossPrice: stopLossPrice !== null ? scaleUp(stopLossPrice) : null,
        takeProfitPnl: takeProfitPnl !== null ? scaleUp(takeProfitPnl) : null,
        takeProfitPrice: takeProfitPrice !== null ? scaleUp(takeProfitPrice) : null,
      },
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
    signal,
  });
  return {
    ...estimate,
    marginUsed: scaleDown(estimate.marginUsed),
    stopLossPnl: scaleDown(estimate.stopLossPnl),
    stopLossPrice: scaleDown(estimate.stopLossPrice),
    takeProfitPnl: scaleDown(estimate.takeProfitPnl),
    takeProfitPrice: scaleDown(estimate.takeProfitPrice),
  };
};

export const estimateOrderByMarginValue = async (
  accountType: string,
  accountId: string,
  buySell: string,
  instrumentId: string,
  marginValue: number
) => {
  return scaleDown(
    Number(
      await fetchJson<number | null>(
        `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/orders/estimateQuantityByMarginValue` +
          `?buySell=${encodeURIComponent(buySell)}&accountId=${encodeURIComponent(accountId)}&instrumentId=${encodeURIComponent(
            instrumentId
          )}&marginValue=${scaleUp(marginValue)}`
      )
    )
  );
};

export const getAccounts = async () => {
  return (await fetchJson<{ accounts: Account[] }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/accounts`)).accounts;
};

export const getActiveKaptains = (cognitoId: string, accountType: string) => {
  return fetchJson<BasicUser[]>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType).toLowerCase()}/v2/cognitoUsers/${cognitoId}/kopyTrades/kaptains`
  );
};

export const getCashflows = (accountType: string, accountId: string, fr: Date, to: Date) => {
  return fetchJson<Array<any>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/accounts/${encodeURIComponent(
      accountId
    )}/cashflows?fr=${fr.toISOString()}&to=${to.toISOString()}`
  );
};

export const getClosedPositions = (accountType: string, accountId: string, positionId: string) => {
  return fetchJson<ClosedPosition>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/accounts/${encodeURIComponent(
      accountId
    )}/closedPositions/${positionId}`
  );
};

export const getCopierStats = (accountType: string, cognitoId: string) => {
  return fetchJson<CopierStats>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/cognitoUsers/${cognitoId}/copiers`
  );
};

export const getFocusedMarketsStats = (accountType: string, cognitoId: string) => {
  return fetchJson<FocusedMarketStats>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/cognitoUsers/${cognitoId}/${accountType}/focusedMarkets/stats`
  );
};

export const getIsFollowKaptain = (accountId: string, accountType: string, positionId: string) => {
  return fetchJson<boolean>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${accountType.toLowerCase()}/v2/accounts/${accountId}/kopyTrades/${positionId}/followKaptain`
  );
};

export const getKopierCognitoIds = (cognitoId: string, accountType: string) => {
  return fetchJson<Array<BasicUser>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/cognitoUsers/${cognitoId}/kopyTrades/copiers`
  );
};

export const getKopierCount = (cognitoId: string, accountType: string) => {
  return fetchJson<{ count: number }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/cognitoUsers/${cognitoId}/kopyTrades/copiers/count`
  );
};

export const getKopyingCount = (cognitoId: string, accountType: string) => {
  return fetchJson<{ count: number }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/cognitoUsers/${cognitoId}/kopyTrades/copying/count`
  );
};

export const getKopyTradeSetting = (accountId: string, cognitoId: string, accountType: string) => {
  return fetchJson<KopyTradeSetting[]>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/accounts/${accountId}/kopyTrades/cognitoUsers/${cognitoId}`
  );
};

export const getKopyTradesSummary = (
  accountType: string,
  accountId: string,
  limit: number,
  page: number,
  role: 'kaptain' | 'copier',
  state: 'active' | 'past'
) => {
  return fetchJson<Page<KopyTradeSummary>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/accounts/${encodeURIComponent(
      accountId
    )}/kopyTrades/summary?limit=${limit}&page=${page}&role=${role}&state=${state}`
  );
};

export const getKopyTradeStatus = (accountId: string, cognitoId: string, accountType: string) => {
  return fetchJson<KopyStatus>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/accounts/${accountId}/kopyTrades/cognitoUsers/${cognitoId}/status`
  );
};

export const getLeaderboard = (accountType: string, periodHour = 24, sortBy = 'WINRATE') => {
  return fetchMarketDataJson<LeaderboardResponse>(
    `/aqt/trading_disp/v2/leaderboards/${accountType}/periodHours/${periodHour}?sortBy=${sortBy}`
  );
};

export const getLeaderboardCognitoUser = (accountType: string, periodHour = 24, cognitoId: string) => {
  return fetchMarketDataJson<LeaderboardUserStats>(
    `/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/leaderboards/periodHours/${periodHour}/cognitoUsers/${encodeURIComponent(cognitoId)}`
  );
};

export const getLeaderboardCognitoUserFollowers = (accountType: string, cognitoId: string, periodHour = 24, sortBy = 'WINRATE') => {
  return fetchJson<LeaderboardResponse>(
    `/aqt/trading_disp/v2/leaderboards/periodHours/${periodHour}/cognitoUsers/${encodeURIComponent(
      cognitoId
    )}/${accountType}/followers?sortBy=${sortBy}`
  );
};

export const getMostActiveTraders = (accountType: string) => {
  return fetchMarketDataJson<{
    mostActiveTraders: {
      cognitoUuid: string;
      friendlyName: string;
      avatarLink: string;
      numberOfTrades: number;
      badges: Array<UserBadge>;
    }[];
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/mostActiveTraders/${accountType}`);
};

export const getMostTradedInstruments = (accountType: string, periodHours: number) => {
  return fetchMarketDataJson<{
    mostTradedInstruments: {
      instrumentId: string;
      numberOfTrades: number;
      numberOfWinTrades: number;
      prevPrice: number;
    }[];
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/mostTradedInstruments/${accountType}/${periodHours}`);
};

export const getOwnTradesStats = (accountType: string, cognitoId: string) => {
  return fetchJson<OwnTradesStatsResponse>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/analyticsStats/cognitoUsers/${cognitoId}/${accountType}/ownTrades`
  );
};

export const getPastKaptains = (cognitoId: string, accountType: string) => {
  return fetchJson<BasicUser[]>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/cognitoUsers/${cognitoId}/kopyTradeHistories/deletedKaptains`
  );
};

export const getPastPerformance = (accountType: string, cognitoId: string) => {
  return fetchMarketDataJson<PastPerformance>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/cognitoUsers/${encodeURIComponent(cognitoId)}/${accountType}/pastPerformance`
  );
};

export const getPastPerformanceTotalReturn = (accountType: string, cognitoIds: string[]) => {
  if (cognitoIds && cognitoIds.length > 0) {
    return fetchMarketDataJson<{ [cognitoId: string]: number }>(
      `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/cognitoUsers/${encodeURIComponent(
        cognitoIds.join(',')
      )}/${accountType}/pastPerformance/totalReturn`
    );
  } else {
    return [];
  }
};

export const getRegulatedTrade = (cognitoId: string, accountType: string, accountId: string) => {
  let url = `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/cognitoUsers/${encodeURIComponent(
    cognitoId
  )}/accounts/${encodeURIComponent(accountId)}/regulatedTrade`;
  return fetchJson<RegulatedTrade>(url);
};

export const getPortfolioOverview = (
  accountType: string,
  accountId: string,
  from: Date,
  to: Date,
  instrumentIds?: Array<string>
) => {
  return fetchJson<TransactionOverviewResponse>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/traderStatus/transactions/overview`,
    {
      body: JSON.stringify({
        accountId,
        from: Math.floor(+from / 1000),
        instrumentIds: instrumentIds,
        to: Math.floor(+to / 1000),
      }),
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );
};

export const getPosition = (accountType: string, accountId: string, positionId: string) => {
  return fetchJson<Position>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/traderStatus/positions/${encodeURIComponent(
      positionId
    )}?accountId=${encodeURIComponent(accountId)}`
  );
};

export const getShowProfileStats = (accountType: string) => {
  return fetchJson<boolean>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/profileStats`
  );
};

export const getSingleKopyTradeSummary = (
  accountId: string,
  cognitoId: string,
  accountType: string,
  role: 'kaptain' | 'copier'
) => {
  return fetchJson<SingleKopyTradeSummary>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/accounts/${accountId}/kopyTrades/cognitoUsers/${cognitoId}/summary?role=${role}`
  );
};

export const getTradedInstruments = (accountType: string, accountId: string) => {
  return fetchJson<Array<string>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/accounts/${encodeURIComponent(
      accountId
    )}/tradedInstruments`
  );
};

export const getTradeNotificationSubscribe = (accountType: string, fromCognitoId: string, toCognitoId: string) => {
  return fetchJson<boolean>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/cognitoUsers/${fromCognitoId}/tradeNotifications/subscribers/${toCognitoId}`
  );
};

export const getTradeNotificationSubscriberCount = async (accountType: string, toCognitoId: string) => {
  return (
    Number(
      await fetchText(
        `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
          accountType.toLowerCase()
        )}/v2/cognitoUsers/${toCognitoId}/tradeNotifications/subscriberCount`
      )
    ) || 0
  );
};

export const getTradeNotificationSubscribingUsers = (accountType: string, toCognitoId: string, page: number, size: number) => {
  return fetchJson<string[]>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/cognitoUsers/${toCognitoId}/tradeNotifications/subscribings?page=${page}&size=${size}`
  );
};

export const getTraderRecordPortfolioOverview = (
  accountType: string,
  cognitoId: string,
  from: Date,
  to: Date,
  instrumentIds?: Array<string>
) => {
  return fetchJson<TransactionOverviewResponse>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v3/cognitoUsers/${cognitoId}/traderRecords/transactions/${accountType}/overview`,
    {
      body: JSON.stringify({
        from: Math.floor(+from / 1000),
        instrumentIds: instrumentIds,
        to: Math.floor(+to / 1000),
      }),
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );
};

export const getTraderRecordAccounts = (accountType: string, cognitoId: string) => {
  return fetchJson<Array<TraderAccount>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v3/cognitoUsers/${cognitoId}/traderRecords/accounts`
  );
};

export const getTraderRecords = async (accountType: string, cognitoId: string, accountId: string) => {
  const traderStatus = await fetchJson<TraderStatus>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/traderRecords/cognitoUsers/${encodeURIComponent(
      cognitoId
    )}/accounts/${encodeURIComponent(accountId)}/traderStatus`
  );
  return scaleDownTraderStatus(traderStatus);
};

export const getTraderStatus = async (accountType: string, accountId: string) => {
  const traderStatus = await fetchJson<TraderStatus>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v3/accounts/${encodeURIComponent(
      accountId
    )}/traderStatus`
  );
  return traderStatus;
};

export const getTraderStatusPosition = async (accountType: string, accountId: string, positionId: string) => {
  return scaleDownTraderStatusPosition(
    await fetchJson<Position>(
      `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/traderStatus/positions/${encodeURIComponent(
        positionId
      )}?accountId=${encodeURIComponent(accountId)}`
    )
  );
};

export const getTraderStatusPositionTransactions = async (accountType: string, accountId: string, positionId: string) => {
  return (
    await fetchJson<Transaction[]>(
      `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/accounts/${encodeURIComponent(
        accountId
      )}/positions/${encodeURIComponent(positionId)}/transactions`
    )
  ).map(scaleDownTraderStatusTransaction);
};

export const getTraderStatusTransaction = async (accountType: string, accountId: string, transactionId: string) => {
  const transaction = await fetchJson<Transaction>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/accounts/${encodeURIComponent(
      accountId
    )}/transactions/${encodeURIComponent(transactionId)}`
  );
  return transaction && scaleDownTraderStatusTransaction(transaction);
};

export const getTraderRecordTradedInstruments = (accountType: string, cognitoId: string, fr: Date, to: Date) => {
  return fetchJson<Array<string>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v3/cognitoUsers/${cognitoId}/traderRecords/${accountType}/tradedInstruments?fr=${fr.toISOString()}&to=${to.toISOString()}`
  );
};

export const getTraderRecordTransactionPages = async (
  accountType: string,
  cognitoId: string,
  fr: Date,
  to: Date,
  page: number,
  size: number,
  sortField: string,
  isDesc: boolean,
  instruments?: Array<string>
) => {
  const searchParams = new URLSearchParams({
    fr: fr.toISOString(),
    to: to.toISOString(),
    page: page.toString(),
    size: size.toString(),
    sortField,
    isDesc: isDesc.toString(),
  });
  if (instruments) {
    searchParams.append('instrumentIds', instruments.join(','));
  }
  const p = await fetchJson<Page<Transaction>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v3/cognitoUsers/${cognitoId}/traderRecords/${accountType}/transactionPages?${searchParams.toString()}`
  );
  return {
    ...p,
    content: p.content.map(transaction => ({
      ...transaction,
      value: scaleDown(transaction.value),
      entryPrice: scaleDown(transaction.entryPrice),
      price: scaleDown(transaction.price),
      commission: scaleDown(transaction.commission),
      swap: scaleDown(transaction.swap),
      pnl: scaleDown(transaction.pnl),
      balance: scaleDown(transaction.balance),
      marginUsed: scaleDown(transaction.marginUsed),
    })),
  };
};

export const getTransactionPages = async (
  accountType: string,
  accountId: string,
  fr: Date,
  to: Date,
  page: number,
  size: number,
  sortField: string,
  isDesc: boolean,
  instruments?: Array<string>
) => {
  const searchParams = new URLSearchParams({
    fr: fr.toISOString(),
    to: to.toISOString(),
    page: page.toString(),
    size: size.toString(),
    sortField,
    isDesc: isDesc.toString(),
  });
  if (instruments) {
    searchParams.append('instrumentIds', instruments.join(','));
  }
  const p = await fetchJson<PageV2<Transaction>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(accountType.toLowerCase())}/v2/accounts/${encodeURIComponent(
      accountId
    )}/transactionPages?${searchParams.toString()}`
  );
  return {
    ...p,
    content: p.content.map(transaction => ({
      ...transaction,
      value: scaleDown(transaction.value),
      entryPrice: scaleDown(transaction.entryPrice),
      price: scaleDown(transaction.price),
      commission: scaleDown(transaction.commission),
      swap: scaleDown(transaction.swap),
      pnl: scaleDown(transaction.pnl),
      balance: scaleDown(transaction.balance),
      marginUsed: scaleDown(transaction.marginUsed),
    })),
  };
};

export const getTransactionAll = async (
  accountType: string,
  accountId: string,
  from: Date,
  to: Date,
  sortField: string,
  isDesc: boolean
) => {
  const pageSize = 200;
  const pages = await getTransactionPages(accountType, accountId, from, to, 0, 1, sortField, isDesc);
  let list: Transaction[] = [];
  let pageNumber = 0;
  if (10000 <= pages.totalElements) {
    throw new Error('Date range too large');
  }
  for (let i = 0; i < pages.totalElements; i += pageSize) {
    list = list.concat(
      (await getTransactionPages(accountType, accountId, from, to, pageNumber++, pageSize, sortField, isDesc)).content
    );
    await new Promise(res => setTimeout(res, 250));
  }
  return list;
};

export const subscribeKopyTradeByUser = (
  accountId: string,
  cognitoId: string,
  kopySubscribeRequest: KopySubscribeRequest,
  accountType: string
) => {
  return fetchJson<{ isSubscribed: boolean }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/accounts/${accountId}/kopyTrades/cognitoUsers/${cognitoId}`,
    {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(kopySubscribeRequest),
      method: 'POST',
    }
  );
};

export const subscribeTradeNotifications = async (accountType: string, fromCognitoId: string, toCognitoId: string) => {
  return await fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/cognitoUsers/${fromCognitoId}/tradeNotifications/subscribers/${toCognitoId}`,
    await API_CONFIG.accessToken,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );
};

export const unsubscribeKopyTradeByUser = (accountId: string, cognitoId: string, accountType: string) => {
  return fetchJson<{ isSubscribed: boolean }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/accounts/${accountId}/kopyTrades/cognitoUsers/${cognitoId}`,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    }
  );
};

export const unsubscribeTradeNotifications = async (accountType: string, fromCognitoId: string, toCognitoId: string) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/cognitoUsers/${fromCognitoId}/tradeNotifications/subscribers/${toCognitoId}`,
    await API_CONFIG.accessToken,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    }
  );
};

export const updateIsFollowKaptain = async (
  accountId: string,
  accountType: string,
  positionId: string,
  isFollowKaptain: boolean
) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${accountType.toLowerCase()}/v2/accounts/${accountId}/kopyTrades/${positionId}/followKaptain`,
    await API_CONFIG.accessToken,
    {
      body: isFollowKaptain ? 'true' : 'false',
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    }
  );
};

// This api only need to send the different data before to update the result.
export const updateKopyTradeSetting = (
  accountId: string,
  cognitoId: string,
  accountType: string,
  res: Partial<KopyTradeSetting>
) => {
  return fetchJson<{ isSubscribed: boolean }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_${encodeURIComponent(
      accountType.toLowerCase()
    )}/v2/accounts/${accountId}/kopyTrades/cognitoUsers/${cognitoId}`,
    {
      body: JSON.stringify(res),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    }
  );
};

export const updateShowProfileStats = async (accountType: string, isOptInLeaderboard: boolean) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/trading_disp/v2/${accountType}/profileStats?show=${isOptInLeaderboard}`,
    await API_CONFIG.accessToken,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );
};
