import { API_CONFIG } from '@aqt/pt-api';
import { NotificationCategory, NotificationMessage, NotificationMessageUnreadCountResponse, Page } from '@aqt/pt-api';

import { fetchJson, fetchResponse } from './apiUtils';

export const getNotificationMessages = (page: number, size: number, category: NotificationCategory) => {
  return fetchJson<Page<NotificationMessage>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/notification/v1/messages?pageNumber=${page}&size=${size}&category=${category}`
  );
};

export const getNotificationMessageUnreadCounts = () => {
  return fetchJson<NotificationMessageUnreadCountResponse>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/notification/v2/messages/unreadCounts`
  );
};

export const unregisterPushPlayerId = async (os: string, cognitoId: string, playerId: string) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/clearPlayerId?cognitoId=${cognitoId}&clientAppOs=${os}&playerId=${encodeURIComponent(playerId)}`,
    await API_CONFIG.accessToken,
    { method: 'POST' }
  );
};

export const updateNotificationMessage = async (notificationMessage: { id: string; status: 'READ' | 'UNREAD' }) => {
  return await fetchJson<NotificationMessage>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/notification/v1/messages`, {
    body: JSON.stringify(notificationMessage),
    headers: { 'Content-Type': 'application/json' },
    method: 'PATCH',
  });
};

export const markAllNotificationMessagesAsRead = async () => {
  return fetchResponse(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/notification/v1/messages/markAllAsRead`, await API_CONFIG.accessToken, {
    body: '',
    method: 'POST',
  });
};

export const registerOneSignalPlayerId = async (os: string, cognitoId: string, playerId: string) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/registerPlayerId?cognitoId=${cognitoId}&clientAppOs=${os}&playerId=${encodeURIComponent(playerId)}`,
    await API_CONFIG.accessToken,
    { method: 'POST' }
  );
};
