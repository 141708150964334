export const SERVICES_ENV: Readonly<{
  AQT_ENV: string;
  AWS_COGNITO_USER_POOL_CLIENT_ID: string;
  AWS_COGNITO_USER_POOL_ID: string;
  AWS_COGNITO_USER_POOL_MOBILE_CLIENT_ID: string;
  AWS_COGNITO_USER_POOL_WEB_CLIENT_ID: string;
  AWS_REGION: string;
  PT_API_ENDPOINT: string;
  PT_BO_ENDPOINT: string;
  PT_MARKETING_ENDPOINT: string;
  PT_WEB_ENDPOINT: string;
  PT_WEB2_ENDPOINT: string;
  PT_WEB_VERIFY_ENDPOINT: string;
  GTM_AUTH: string;
  GTM_ID: string;
  GTM_PREVIEW: string;
  ONESIGNAL_APP_ID: string;
}> = import.meta.env.AQT_SERVICES;

export const GIPHY_API_KEY = import.meta.env.GIPHY_API_KEY as string;
