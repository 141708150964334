type AccountMigration = {
  from: string;
  to: string;
};

let accountMigrations_: Array<AccountMigration> = [];
let brokerageAccountType_: string;
let brokerageAccountTypes_: string[] = [];

// you can check this value in aqt-web-verify/labels/account_type.json
export let accountMigrations = () => accountMigrations_;
export let brokerageAccountType = () => brokerageAccountType_;
export let brokerageAccountTypes = () => brokerageAccountTypes_;
export let setAccountMigrations = (accountFromTo: AccountMigration[]) => (accountMigrations_ = accountFromTo);
export let setBrokerageAccountType = (accountType: string) => (brokerageAccountType_ = accountType);
export let setBrokerageAccountTypes = (accountTypes: string[]) => (brokerageAccountTypes_ = accountTypes);

let AuthConfig = {
  alert: console.error,
  endpoint: '',
  isLogStdout: false,
};

export default AuthConfig;
