import {
  Account,
  Exchange,
  Holiday,
  Instrument,
  KycStatus,
  newInstrument,
  newUserPreferences,
  TradingSchedule,
  User,
  UserPreferences,
} from '@aqt/pt-api/types';
import { AuthTokens, fetchAuthSession, getCurrentUser as getAuthUser } from 'aws-amplify/auth';
import { createContext, useContext, useMemo, useState } from 'react';

import { getKycStatus } from '@/api/kyc';
import { getExchanges, getHolidays, getTradingSchedules, searchInstruments } from '@/api/marketData';
import { getAccounts } from '@/api/trading';
import { getCurrentUser, getUserPreferences } from '@/api/user';
import Auth from '@/Auth';
import UserModals from '@/components/Header/Modal/UserModals';
import Loading from '@/components/Loading';
import { useAsyncEffect } from '@/Hooks';
import { CognitoUser } from '@/Types';
import { registerPushPlayerId } from '@/utils/OneSignalUtils';

interface IPtContext {
  // the account user has chosen, empty string if none
  accountId: string;
  accountType: string;
  accountBalance: number;
  accounts: Account[];
  accountLeverage: number;

  // logged-in user
  cognitoUser?: CognitoUser; // cognito
  currentUser: User; // AQT

  // instrument names etc preloaded information
  exchanges: { [exchangeId: string]: Exchange };
  holidays: { [exchangeId: string]: Array<Holiday> };
  instrumentByKey: { [instrumentKey: string]: Instrument };
  instruments: { [instrumentId: string]: Instrument };
  kycStatus: KycStatus;
  tradingSchedules: { [exchangeId: string]: TradingSchedule };
  userPreferences: UserPreferences;

  // UI actions
  refresh: () => void;
  showLoginModal: () => void;
  showSignUpModal: () => void;

  // getters and setters
  getInstrument: (instrumentId: string) => Instrument;
  setAccount: (accountType: string, accountId: string, accountLeverage: number) => void;
  setAccountBalance: (accountBalance: number) => void;
  setUserPreferences: (userPreferences: UserPreferences) => void;
}

let newPtContext: () => IPtContext = () => ({
  accountId: '',
  // default account type for Freemium
  accountType: 'PAPER',
  accountBalance: 0,
  accountLeverage: 0,
  accounts: [],
  cognitoUser: undefined,
  currentUser: { id: '' } as User,
  exchanges: {},
  holidays: {},
  instrumentByKey: {},
  instruments: {},
  kycStatus: 'NO_APPLICATION',
  tradingSchedules: {},
  userPreferences: {} as UserPreferences,
  getInstrument: (_instrumentId: string) => newInstrument(),

  refresh: () => {},
  setAccount: () => {},
  setAccountBalance: () => {},
  setUserPreferences: () => {},
  showLoginModal: () => {},
  showSignUpModal: () => {},
});

let defaultAccountType = newPtContext().accountType;
let dummyInstrument = newInstrument();
let dummyUser: User = { id: '' };

const PtContext = createContext(newPtContext());

export function PtContextProvider({ children }) {
  let [account, setAccount] = useState(() => ({ accountType: '', accountId: '', accountLeverage: 0 }));
  let [accountBalance, setAccountBalance] = useState<number>(0);
  let [accounts, setAccounts] = useState<Array<Account>>([]);
  let [accountType, setAccountType] = useState('');
  let [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  let [currentUser, setCurrentUser] = useState<User>(dummyUser);
  let [exchanges, setExchanges] = useState<Array<Exchange>>([]);
  let [kycStatus, setKycStatus] = useState<KycStatus>('NO_APPLICATION');
  let [holidays, setHolidays] = useState<Array<Holiday>>([]);
  let [instruments, setInstruments] = useState<Array<Instrument>>([]);
  let [tradingSchedules, setTradingSchedules] = useState<Array<TradingSchedule>>([]);
  let [userPreferences, setUserPreferences] = useState<UserPreferences>(newUserPreferences);

  let [isShowLogin, setIsShowLogin] = useState(0);
  let [isShowSignUp, setIsShowSignUp] = useState(0);
  let [refresh, setRefresh] = useState(0);
  let [isLoading, setIsLoading] = useState(false);

  let exchangeMap = useMemo(() => Object.fromEntries(exchanges.map(exchange => [exchange.id, exchange])), [exchanges]);

  let holidaysMap = useMemo(() => {
    let holidaysMap: { [exchangeId: string]: Array<Holiday> } = {};
    for (let holiday of holidays) {
      if (holidaysMap[holiday.exchangeId] == null) holidaysMap[holiday.exchangeId] = [];
      holidaysMap[holiday.exchangeId].push(holiday);
    }
    return holidaysMap;
  }, [holidays]);

  let instrumentByKey = useMemo(
    () => Object.fromEntries(instruments.map(instrument => [instrument.key, instrument])),
    [instruments]
  );
  let instrumentMap = useMemo(
    () => Object.fromEntries(instruments.map(instrument => [instrument.instrumentId, instrument])),
    [instruments]
  );
  let tradingScheduleMap = useMemo(
    () => Object.fromEntries(tradingSchedules.map(tradingSchedule => [tradingSchedule.exchangeId, tradingSchedule])),
    [tradingSchedules]
  );

  useAsyncEffect(async () => {
    try {
      let user = await getAuthUser();
      let authUserSession = await fetchAuthSession();
      const cognitoUser: CognitoUser = { ...user, tokens: authUserSession.tokens as AuthTokens };

      setCognitoUser(cognitoUser);
      let cognitoId = cognitoUser?.username;
      cognitoId && setKycStatus(await getKycStatus(cognitoId));
    } catch (e) {
      console.error(e);
      setCognitoUser(undefined); // user not logged in
      setAccount({ accountType: defaultAccountType, accountId: '', accountLeverage: 0 });
      setAccountType(defaultAccountType);
      setKycStatus('NO_APPLICATION');
    }
  }, [Auth.isFreemium(), refresh]);

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      let now = +new Date();
      const [exchanges, holidays, instruments, tradingSchedules] = await Promise.all([
        getExchanges(),
        getHolidays(new Date(now - 360 * 86400 * 1000), new Date(now + 360 * 86400 * 1000)),
        searchInstruments(''),
        getTradingSchedules(),
      ]);
      setExchanges(exchanges);
      setHolidays(holidays);
      setInstruments(instruments);
      setTradingSchedules(tradingSchedules);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useAsyncEffect(async () => {
    if (cognitoUser != null) {
      try {
        let userPromise = getCurrentUser();
        let accountsPromise = getAccounts();

        let user = await userPromise;
        user.cognitoId = cognitoUser?.username;
        setAccountType(user.session ?? defaultAccountType);
        setCurrentUser(user);

        let accounts = await accountsPromise;
        setAccounts(accounts);
      } catch (e) {
        console.error(e);
      }

      await registerPushPlayerId(cognitoUser.username as string);
    } else {
      setCurrentUser(dummyUser);
    }
  }, [cognitoUser]);

  useAsyncEffect(async () => {
    const account = accounts.find(account => account.accountType === accountType);
    if (account) {
      setAccount({ accountType, accountId: account.id, accountLeverage: account.leverage });
    } else if (accounts.length > 0) {
      setAccountType(accounts[0].accountType);
    } else {
      setAccount({ accountType, accountId: '', accountLeverage: 0 });
    }
  }, [accounts, accountType]);

  useAsyncEffect(async () => {
    if (cognitoUser != null) {
      getUserPreferences(cognitoUser.username as string).then(setUserPreferences);
    }
  }, [cognitoUser]);

  let ptContext = useMemo(
    () => ({
      accountId: account.accountId,
      accountType: account.accountType,
      accountBalance,
      accountLeverage: account.accountLeverage,
      accounts,
      cognitoUser,
      currentUser,
      exchanges: exchangeMap,
      holidays: holidaysMap,
      instrumentByKey,
      instruments: instrumentMap,
      kycStatus,
      tradingSchedules: tradingScheduleMap,
      userPreferences,
      refresh: () => setRefresh(r => r + 1),
      showLoginModal: () => setIsShowLogin(i => i + 1),
      showSignUpModal: () => setIsShowSignUp(i => i + 1),
      getInstrument: (instrumentId: string) => instrumentMap[instrumentId] ?? dummyInstrument,
      setAccount: (accountType: string, accountId: string, accountLeverage: number) =>
        setAccount({ accountType, accountId, accountLeverage }),
      setAccountBalance,
      setUserPreferences,
    }),
    [
      account,
      accounts,
      accountBalance,
      cognitoUser,
      currentUser,
      exchangeMap,
      holidaysMap,
      instrumentByKey,
      instrumentMap,
      kycStatus,
      tradingScheduleMap,
      userPreferences,
    ]
  );

  return (
    <PtContext.Provider value={ptContext}>
      {!accountType || isLoading ? <Loading /> : children}
      <UserModals isShowLoginModal={isShowLogin} isShowSignUpModal={isShowSignUp} cognitoId={currentUser.cognitoId} />
    </PtContext.Provider>
  );
}

export const usePtContext = () => {
  return useContext(PtContext);
};
