import { useRouteError } from 'react-router-dom';
import styled from 'styled-components';

import Cat from '../../images/cats/cat_ufo.svg?react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

  div:nth-of-type(1) {
    font-size: 18px;
    font-weight: 600;
    margin: 24px auto 16px;
  }
`;

/**
 * Error component for route error
 */
export default function ErrorPage() {
  const error = useRouteError();
  return (
    <Container>
      <Cat />
      <div>Oops! Lost in space</div>
      <div>
        Something went wrong. We suggest you <a href='/'>back to home</a>
      </div>
      {process.env.NODE_ENV === 'development' && (
        <pre style={{ color: 'red' }}>{typeof error === 'object' ? JSON.stringify(error, null, 2) : String(error)}</pre>
      )}
    </Container>
  );
}
