import { LatestRedeemedBadge, Page, RedeemedBadge } from '@aqt/pt-api';
import { API_CONFIG } from '@aqt/pt-api/http';

import { fetchJson } from './apiUtils';

export const getAllBadges = async (cognitoId: string, page = 0) => {
  return await fetchJson<Page<RedeemedBadge>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/badges/cognitoUsers/${cognitoId}/badges?page=${page}&size=10`
  );
};

export const getBadgesByBadgeTypeIds = async (cognitoId: string, userBadgeIds: Array<string>, page = 0) => {
  return await fetchJson<Page<RedeemedBadge>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/badges/cognitoUsers/${cognitoId}/badges?page=${page}&size=10&userBadgeIds=${userBadgeIds.join(',')}`
  );
};

export const getLatestTenBadges = async (cognitoId: string) => {
  return await fetchJson<{ data: LatestRedeemedBadge[] }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/campaign/v1/badges/cognitoUsers/${cognitoId}/badges/latest`
  );
};
