import { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

const animationTimeMs = 150;

const BackdropDiv = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: ${props => props.theme.zIndex.modal};
  position: fixed;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  background-color: rgba(151, 149, 156, 0.85);

  &.bd-enter {
    opacity: 0;
  }

  &.bd-enter-active {
    opacity: 1;
    transition: ease-in-out ${animationTimeMs}ms;
  }

  &.bd-exit {
    opacity: 1;
  }

  &.bd-exit-active {
    opacity: 0;
    transition: ease-in-out ${animationTimeMs}ms;
  }
`;

type BackdropProps = {
  open?: boolean;
  children?: JSX.Element | JSX.Element[];
  onClick?: React.EventHandler<any>;
  style?: React.CSSProperties;
};

export default function Backdrop({ open = true, children, onClick, style }: BackdropProps) {
  // FIXME: cannot use loading and modal at the same time since they both use the same dom tree
  const backdropRoot = useMemo(() => document.getElementById('backdrop'), []);
  const backDropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const random = Math.floor(Math.random() * 100000);
    if (open) {
      document.body.classList.add(`overflow-hidden-${random}`);
    }

    return () => document.body.classList.remove(`overflow-hidden-${random}`);
  }, [open]);

  return createPortal(
    <CSSTransition in={open} timeout={animationTimeMs} nodeRef={backDropRef} unmountOnExit classNames='bd'>
      <BackdropDiv
        role='dialog'
        ref={backDropRef}
        onMouseDown={e => {
          // only triggered when Backdrop is clicked
          if (e.target === e.currentTarget) onClick?.(e);
        }}
        style={style}
      >
        {open && children}
      </BackdropDiv>
    </CSSTransition>,

    backdropRoot as Element
  );
}
