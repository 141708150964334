import { NotificationCategory } from '@aqt/pt-api/types';
import OneSignal from 'react-onesignal';

import { registerOneSignalPlayerId, unregisterPushPlayerId } from '@/api/notification';
import { SERVICES_ENV } from '@/env';

import Auth_ from '../Auth';

export type InAppNotificationSettingCategory = 'feedPost' | 'kaptainTrade' | 'newCopier' | 'newFollower' | 'orderExecuted' | '';
export type ItemProps = {
  activityId: string;
  cognitoId: string;
  feedId: string;
  friendlyName: string;
};

export type OneSignalNotification = {
  id: string;
  heading: string;
  content: string;
  data: {
    __isOneSignalWelcomeNotification?: boolean;
    cognitoId?: string;
    context?: string;
    deeplink?: string;
    groupingKey?: string;
    inAppCategory: InAppNotificationSettingCategory;
    isShowInline?: boolean;
    items: ItemProps[];
    notificationId?: string;
    type?: string;
    category?: NotificationCategory;
    [key: string]: any;
  };
  url?: string;
  tag?: string;
  icon?: string;
};

export const showNativePrompt = () => {
  return OneSignal.showNativePrompt();
};

export const setSubscription = (isSubscribed: boolean) => {
  return OneSignal.setSubscription(isSubscribed);
};

export const showSlidedownPrompt = () => {
  return OneSignal.showSlidedownPrompt({
    force: true,
  });
};

export const initOneSignal = async (appId: string) => {
  try {
    await OneSignal.init({
      appId,
      autoResubscribe: true,
      allowLocalhostAsSecureOrigin: import.meta.env.DEV,
    });
    await OneSignal.registerForPushNotifications();
    const isPushEnabled = await OneSignal.isPushNotificationsEnabled();
    if (!isPushEnabled) {
      showSlidedownPrompt();
    }
  } catch (error) {
    console.error('OneSignal: cannot init', error);
  }
};

const getPlayerId = async () => {
  const playerId = await OneSignal.getUserId();
  console.log('OneSignal player id', playerId);
  if (!playerId) {
    throw new Error('OneSignal: cannot get player id');
  }
  return playerId;
};

export let configureOneSignal = () =>
  initOneSignal(import.meta.env.DEV ? (import.meta.env.VITE_ONESIGNAL_APP_ID as string) : SERVICES_ENV.ONESIGNAL_APP_ID);

export const isPushNotificationsEnabled = () => {
  return OneSignal.isPushNotificationsEnabled();
};

export const getSubscription = () => {
  return OneSignal.getSubscription();
};

export const registerPushPlayerId = async (cognitoId: string) => {
  if (!Auth_.isFreemium()) {
    await registerOneSignalPlayerId('WEB', cognitoId, await getPlayerId().catch(() => '-'));
    const permission = await OneSignal.getNotificationPermission();
    if (permission === 'granted') {
      await OneSignal.setSubscription(true);
    }
  }
};

export let subscribeOneSignalNotifications = (
  onDisplay: (notification: OneSignalNotification) => void,
  onDismiss: (notification: OneSignalNotification) => void
) => {
  let handleNotificationDismiss = (event: OneSignalNotification) => {
    onDismiss(event);
  };

  let handleNotificationDisplay = (event: OneSignalNotification) => {
    onDisplay(event);
  };

  OneSignal.on('notificationDismiss', handleNotificationDismiss);
  OneSignal.on('notificationDisplay', handleNotificationDisplay);

  return () => {
    OneSignal.off('notificationDisplay', handleNotificationDisplay);
    OneSignal.off('notificationDismiss', handleNotificationDismiss);
  };
};

export let unregisterOneSignalPlayerId = async (cognitoId: string) => {
  if (!Auth_.isFreemium()) {
    await unregisterPushPlayerId('WEB', cognitoId, await getPlayerId().catch(() => '-'));
    await OneSignal.setSubscription(false);
  }
};
