import { getAllBadges, getLatestTenBadges } from '@/api/badge';
import {
  getCalendarDisclaimer,
  getCalendarEventDetails,
  getCalendarEvents,
  getCountries,
  getEventSubscriptionStatus,
} from '@/api/calendar';
import {
  claimLuckyDraw,
  claimReward,
  getCampaignBanners,
  getCampaignConfig,
  getCampaignFAQs,
  getCampaignRules,
  getFirstDepositAmount,
  getLuckyDrawHistory,
  getLuckyDrawStatus,
  getMyMission,
  getPromotionCampaign,
  getRewardHistory,
  getUnredeemedCount,
  getUserRewardStatus,
} from '@/api/campaign';
import {
  getAdjustments,
  getBankAccounts,
  getCashierKey,
  getCreditCards,
  getDepositAndWithdrawal,
  getDepositDetails,
  getDepositFeeInfo,
  getDepositInfo,
  getDepositLimit,
  getLiveAccountRegistrations,
  getMerchantReference,
  getPaymentAsiaSignature,
  getPaymentDetails,
  getSignedCurrencyConversionRate,
  getTransactionHistories,
  getWithdrawalDetails,
  getWithdrawalFeeInfo,
  getWithdrawalInfo,
} from '@/api/cash';
import { getMigrationStatus, migrateAccount, submitKycForm, uploadImagesForKyc } from '@/api/kyc';
import {
  createUserPriceAlert,
  deleteUserPriceAlert,
  getFocusedMarkets,
  getTopMoverInstruments,
  getUserPriceAlerts,
  getWatchListInstruments,
  updateUserPriceAlert,
} from '@/api/marketData';
import {
  getNotificationMessages,
  getNotificationMessageUnreadCounts,
  markAllNotificationMessagesAsRead,
  updateNotificationMessage,
} from '@/api/notification';
import {
  getAlertBanners,
  getBlogs,
  getFAQContents,
  getPromotionalModalContents,
  getPromotionBannerContents,
  getPtSupport,
  getServerTime,
  getSignUpModalContents,
  getWhatsNewInfo,
} from '@/api/other';
import {
  followUnfollow,
  getFollowerCognitoIds,
  getFollowerCount,
  getFollowingCognitoIds,
  getFollowingCount,
  getMostFollowers,
  getPinnedActivity,
  pinActivity,
  unpinActivity,
} from '@/api/social';
import {
  closePosition,
  closePositions,
  createOrder,
  getActiveKaptains,
  getCopierStats,
  getFocusedMarketsStats,
  getIsFollowKaptain,
  getKopierCognitoIds,
  getKopierCount,
  getKopyingCount,
  getKopyTradeSetting,
  getKopyTradesSummary,
  getKopyTradeStatus,
  getLeaderboard,
  getLeaderboardCognitoUserFollowers,
  getMostTradedInstruments,
  getOwnTradesStats,
  getPastKaptains,
  getPastPerformance,
  getPortfolioOverview,
  getShowProfileStats,
  getSingleKopyTradeSummary,
  getTradeNotificationSubscribe,
  getTradeNotificationSubscriberCount,
  getTraderRecordAccounts,
  getTraderRecordPortfolioOverview,
  getTraderRecordTradedInstruments,
  getTraderRecordTransactionPages,
  getTransactionPages,
  subscribeKopyTradeByUser,
  subscribeTradeNotifications,
  unsubscribeKopyTradeByUser,
  unsubscribeTradeNotifications,
  updateIsFollowKaptain,
  updateKopyTradeSetting,
  updateShowProfileStats,
} from '@/api/trading';
import {
  getUserByCognitoId,
  getUserNotificationPreferences,
  getUserTradePreferences,
  removeAvatar,
  removeCoverPic,
  updateUserNotificationPreferences,
  updateUserTradePreferences,
  uploadAvatar,
  uploadCoverPic,
} from '@/api/user';

export const AqtQuery = {
  activeKaptains: getActiveKaptains,
  adjustments: getAdjustments,
  alertBanners: getAlertBanners,
  allBadges: getAllBadges,
  bankAccounts: getBankAccounts,
  blogs: getBlogs,
  calendarDisclaimer: getCalendarDisclaimer,
  calendarEventDetails: getCalendarEventDetails,
  calendarEvents: getCalendarEvents,
  campaignBanners: getCampaignBanners,
  campaignConfig: getCampaignConfig,
  campaignFAQs: getCampaignFAQs,
  campaignRules: getCampaignRules,
  cashierKey: getCashierKey,
  copierStats: getCopierStats,
  countries: getCountries,
  creditCards: getCreditCards,
  depositAndWithdrawal: getDepositAndWithdrawal,
  depositDetails: getDepositDetails,
  depositFeeInfo: getDepositFeeInfo,
  depositInfo: getDepositInfo,
  depositLimit: getDepositLimit,
  eventSubscriptionStatus: getEventSubscriptionStatus,
  firstDepositAmount: getFirstDepositAmount,
  focusedMarkets: getFocusedMarkets,
  focusedMarketsStats: getFocusedMarketsStats,
  followerCognitoIds: getFollowerCognitoIds,
  followerCount: getFollowerCount,
  followingCognitoIds: getFollowingCognitoIds,
  followingCount: getFollowingCount,
  isFollowKaptain: getIsFollowKaptain,
  kopierCognitoIds: getKopierCognitoIds,
  kopierCount: getKopierCount,
  kopyingCount: getKopyingCount,
  kopyTradeSetting: getKopyTradeSetting,
  kopyTradesSummary: getKopyTradesSummary,
  kopyTradeStatus: getKopyTradeStatus,
  latestBadges: getLatestTenBadges,
  leaderboard: getLeaderboard,
  leaderboardCognitoUserFollowers: getLeaderboardCognitoUserFollowers,
  liveAccountRegistrations: getLiveAccountRegistrations,
  luckyDrawHistory: getLuckyDrawHistory,
  luckyDrawStatus: getLuckyDrawStatus,
  merchantReference: getMerchantReference,
  migrationStatus: getMigrationStatus,
  mostFollower: getMostFollowers,
  mostTradedInstruments: getMostTradedInstruments,
  myMission: getMyMission,
  notificationMessage: getNotificationMessages,
  notificationMessageUnreadCounts: getNotificationMessageUnreadCounts,
  ownTradesStats: getOwnTradesStats,
  pastKaptains: getPastKaptains,
  pastPerformance: getPastPerformance,
  paymentAsiaSignature: getPaymentAsiaSignature,
  paymentDetails: getPaymentDetails,
  pinActivity,
  pinnedActivity: getPinnedActivity,
  priceAlerts: getUserPriceAlerts,
  promotionalModalContents: getPromotionalModalContents,
  promotionBannerContents: getPromotionBannerContents,
  promotionCampaign: getPromotionCampaign,
  ptFAQContents: getFAQContents,
  ptSupport: getPtSupport,
  rewardHistory: getRewardHistory,
  serverTime: getServerTime,
  showProfileStats: getShowProfileStats,
  signedCurrencyConversionRate: getSignedCurrencyConversionRate,
  signUpModalContents: getSignUpModalContents,
  singleKopyTradeSummary: getSingleKopyTradeSummary,
  topMoverInstruments: getTopMoverInstruments,
  tradedInstruments: getTraderRecordTradedInstruments,
  tradeNotificationSubscribe: getTradeNotificationSubscribe,
  tradeNotificationSubscriberCount: getTradeNotificationSubscriberCount,
  traderRecordAccounts: getTraderRecordAccounts,
  transactionHistories: getTransactionHistories,
  transactionOverview: getPortfolioOverview,
  transactionOverviewTrader: getTraderRecordPortfolioOverview,
  transactionPages: getTransactionPages,
  transactionPagesTrader: getTraderRecordTransactionPages,
  unpinActivity,
  unredeemedCount: getUnredeemedCount,
  userByCognitoId: getUserByCognitoId,
  userNotificationPreferences: getUserNotificationPreferences,
  userRewardStatus: getUserRewardStatus,
  userTradePreferences: getUserTradePreferences,
  watchListInstruments: getWatchListInstruments,
  whatsNewInfo: getWhatsNewInfo,
  withdrawalDetails: getWithdrawalDetails,
  withdrawalFeeInfo: getWithdrawalFeeInfo,
  withdrawalInfo: getWithdrawalInfo,
};

export const AqtMutation = {
  claimLuckyDraw,
  claimReward,
  closePosition,
  closePositions,
  createOrder,
  createPriceAlert: createUserPriceAlert,
  deletePriceAlert: deleteUserPriceAlert,
  followUnfollow,
  markAllNotificationMessagesAsRead,
  migrateAccount,
  removeAvatar,
  removeCoverPic,
  submitKycForm,
  subscribeKopyTradeByUser,
  subscribeTradeNotifications,
  unsubscribeKopyTradeByUser,
  unsubscribeTradeNotifications,
  updateIsFollowKaptain,
  updateKopyTradeSetting,
  updateNotificationMessage,
  updatePriceAlert: updateUserPriceAlert,
  updateShowProfileStats,
  updateUserNotificationPreferences,
  updateUserTradePreferences,
  uploadAvatar,
  uploadCoverPic,
  uploadImagesForKyc,
};
