export const black = '#000000';
export const white = '#ffffff';

export const gray = '#999999';
export const lightGray = '#cccccc';
export const gray30 = '#4d4d4d';
export const gray70 = '#b3b3b3';
export const gray85 = '#d9d9d9';
export const gray90 = '#e6e6e6';
export const gray95 = '#f2f2f2';
export const gray97 = '#f7f7f7';
export const lightShadeGray = '#e6e6e6';
export const secondaryLightGray = '#f8f5fc';
export const approxSilver = '#bfbfbf';

// Green family
export const green = '#3dc90a';
export const congLuGreen = '#0abf89';
export const deepSpringGreen = '#00ffb2';
export const softSpringGreen = '#00f2a9';

// Purple family
export const purple = '#6f2ee6';
export const softPurple = '#f8f5fc';

// Red family
export const persianRed = '#d32f2f';
export const softRed = '#ed5c5c';

// White family
export const antiFlashWhite = '#f1f1f1';
export const secondaryWhite = '#fcfaeb';
export const yellow = '#f5cc03';

const responsiveBreakpoints = { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1440, xxl: 1920 } as const;

const zIndex = {
  button: 1,
  sticky: 1,
  chat: 50,
  dropDown: 60,
  sideBar: 60,
  sideBarHeader: 88,
  subHeader: 89,
  header: 90,
  hover: 100,
  tooltip: 101,

  // index with specific component
  channelSearch: -1,
  dateRangePickerDrag: -1,
  welcomeBackground: -1,

  previewImage: 0,

  avatarContainer: 1,
  chatFollowedTraderLabel: 1,
  chatSimpleStatus: 1,
  confetti: 1,
  dayTimePicker: 1,
  memberOnly: 1,
  groupAvatarPriority: 1,
  timePickerContainer: 1,

  createChannelHeader: 2,
  chatSearchContainer: 2,
  luckyDrawButton: 2,
  goToTop: 2,
  tradeMenu: 2,

  select: 9,

  floatingMenu: 10,

  hamburgerMenu: 999,

  createChatHeader: 1000,

  chatInnerModal: 9999,

  modal: 10000,
  hoverTip: 10000,

  downloadAppModal: 10001,
} as const;

const breakpoints = {
  xs: `${responsiveBreakpoints.xs}px`,
  sm: `${responsiveBreakpoints.sm}px`,
  md: `${responsiveBreakpoints.md}px`,
  lg: `${responsiveBreakpoints.lg}px`,
  xl: `${responsiveBreakpoints.xl}px`,
  xxl: `${responsiveBreakpoints.xxl}px`,
};

const device = {
  mobile: `max-width: ${responsiveBreakpoints.sm - 1}px`,
  tablet: `max-width: ${responsiveBreakpoints.md - 1}px`,
  laptop: `max-width: ${responsiveBreakpoints.lg - 1}px`,
  desktop: `max-width: ${responsiveBreakpoints.xl - 1}px`,
  desktopXl: `max-width: ${responsiveBreakpoints.xxl - 1}px`,
};

const fontSizes = {
  h1: {
    lg: 30,
    md: 26,
    sm: 24,
    xl: 40,
  },
  h2: {
    lg: 24,
    md: 22,
    sm: 20,
  },
  h3: {
    lg: 28,
    md: 22,
    sm: 19,
  },
  h4: {
    lg: 22,
    md: 18,
    sm: 17,
    xs: 15,
  },
  h5: {
    lg: 16,
    md: 14,
    sm: 12,
  },
  lg: 18,
  md: 16,
  sm: 14,
  xl: 20,
  xs: 12,
} as const;

const commonStyle = {
  black,
  white,
  purple,
  green,
  gray,
  lightGray,
  gray90,
  gray95,
  gray97,
  softRed,
  softPurple,
  secondaryWhite,
  yellow,
  breakpoints,
  device,
  fontFamily: 'Poppins',
  fontSizes,
  zIndex,
};

const kycTheme = {
  background: white,
  breakpoints: {
    xs: breakpoints.xs,
    sm: breakpoints.sm,
    md: breakpoints.md,
    lg: breakpoints.lg,
    xl: breakpoints.xl,
    xxl: breakpoints.xxl,
  },
  device: {
    mobile: `max-width: ${responsiveBreakpoints.sm - 1}px`,
    tablet: `max-width: ${responsiveBreakpoints.md - 1}px`,
    laptop: `max-width: ${responsiveBreakpoints.lg - 1}px`,
    desktop: `max-width: ${responsiveBreakpoints.xl - 1}px`,
    desktopXl: `max-width: ${responsiveBreakpoints.xxl - 1}px`,
  },
  button: {
    active: { primary: congLuGreen, secondary: gray70, off: approxSilver },
    background: { primary: deepSpringGreen, secondary: gray95, off: lightShadeGray },
    border: { on: black, off: gray },
    boxShadow: { on: black, off: gray },
    disabled: { background: lightGray, border: gray, boxShadow: gray },
    hover: { primary: softSpringGreen, secondary: lightShadeGray, off: gray85 },
    text: { on: black, off: gray },
  },
  border: lightShadeGray,
  gray: gray,
  primaryPurple: purple,
  text: black,
  radioButton: {
    color: purple,
    backgroundColor: white,
    hover: { opacity: 0.4 },
    borderColor: lightShadeGray,
  },
  textField: {
    accentColor: purple,
    focusColor: purple,
    errorColor: persianRed,
    backgroundColor: {
      disabled: antiFlashWhite,
      active: gray97,
    },
    caretColor: purple,
  },
  errorMessage: {
    fontColor: persianRed,
  },
  checkBox: {
    backgroundColor: purple,
  },
  select: {
    backgroundColor: gray97,
  },
  progress: {
    on: purple,
    off: gray85,
  },
};

export const Theme = {
  ...commonStyle,
  accountBalance: {
    button: {
      background: { normal: purple, hover: '#5819cd', active: '#43139b' },
      text: white,
    },
    content: {
      default: black,
      withPnL: white,
    },
    disabled: { background: lightGray, text: gray },
    optionBackground: gray95,
    text: black,
  },
  accountSettings: {
    list: {
      background: secondaryWhite,
      color: purple,
    },
    marketButton: {
      bgoff: gray95,
      bgon: purple,
    },
    textField: {
      background: gray95,
    },
    warning: softRed,
  },
  alertBanner: {
    closeButton: black,
    background: secondaryWhite,
    text: black,
  },
  arrowButton: {
    background: purple,
  },
  background: white,
  border: gray90,
  borderDark: '#979797',
  button: {
    active: { off: '#bfbfbf', primary: '#0abf89', secondary: '#b3b3b3' },
    background: { off: gray90, primary: '#00ffb2', secondary: gray95 },
    border: { off: gray, on: black },
    boxShadow: { off: gray, on: black },
    disabled: { background: lightGray, border: gray, boxShadow: gray },
    hover: { off: '#d9d9d9', primary: '#00f2a9', secondary: gray90 },
    text: { off: gray, on: black },
  },
  buttonText: white,
  buy: green,
  chat: {
    background: white,
    channel: {
      active: softPurple,
      hover: secondaryWhite,
      readLabel: purple,
      timeLabel: gray,
    },
    input: {
      activeBackground: white,
      idleBackground: gray95,
    },
    message: {
      background: gray97,
      backgroundMe: softPurple,
    },
    deleted: {
      background: '#ecebeb',
      color: '#7a7a7a',
    },
  },
  campaigns: {
    marketingCampaign: {
      banner: {
        title: {
          top: '#88ff00',
          bottom: '#00ffb2',
        },
        text: white,
        countdown: {
          background: '#2b2608',
          border: yellow,
          text: yellow,
        },
      },
      mission: {
        amount: purple,
        badge: {
          background: '#f4fef2',
          text: green,
        },
        border: lightGray,
        credit: green,
        header: {
          background: softPurple,
        },
        highlight: {
          background: purple,
          text: white,
        },
        progress: {
          background: {
            on: purple,
            off: '#c2b8d4',
          },
          text: {
            on: purple,
            off: '#c2b8d4',
          },
        },
        rules: {
          text: purple,
        },
      },
      luckyDraw: {
        boxCount: yellow,
      },
      label: gray,
      slogan: yellow,
    },
  },
  checkbox: purple,
  circularGraph: {
    background: softPurple,
    green: green,
    purple: purple,
    softRed: softRed,
  },
  datePicker: {
    lightText: '#f0f8ff',
    primaryBackground: purple,
    secondaryBackground: softPurple,
  },
  dateRangePicker: {
    backgroundColor: gray97,
  },
  dayTimePicker: {
    backgroundColor: gray97,
  },
  deposit: { input: { background: gray97, placeholder: gray }, text: black },
  disabled: white,
  dottedLine: lightGray,
  dragndrop: { background: white, active: secondaryWhite },
  error: {
    fontColor: 'red',
  },
  feed: {
    commentContainer: {
      backgroundColor: gray95,
    },
    commentReactionButton: {
      activeColor: purple,
      inactiveColor: gray,
    },
    commentReactionCount: {
      backgroundColor: white,
      borderColor: gray95,
    },
    moreButtonColor: gray,
    reactionButton: {
      color: gray,
    },
    sharedTrade: {
      background: {
        neutral: softPurple,
      },
      titleBackground: gray,
    },
  },
  feedback: {
    imageBackground: gray97,
  },
  fileUploader: {
    border: '#dfdce5',
    background: softPurple,
  },
  headerBorder: gray95,
  hover: secondaryWhite,
  iconButton: {
    active: { background: '#b3b3b3' },
    background: gray95,
    border: black,
    disabled: { background: lightGray },
    hover: { background: gray90 },
    onClick: '#d9d9d9',
    text: black,
    tooltip: {
      background: black,
      text: white,
    },
  },
  imageBackground: gray97,
  impact: {
    selectBackground: gray97,
    high: { color: softRed, background: '#fef2f2' },
    mid: { color: '#ff811a', background: '#fef6ec' },
    low: { color: green, background: '#f4fef2' },
  },
  input: {
    background: gray95,
    warning: softRed,
  },
  lightGray: lightGray,
  link: purple,
  liveAccountRegistration: kycTheme,
  maxWidth: '1680px',
  menu: {
    active: purple,
    background: '#f7f7f5',
    liveAccountBackground: purple,
    liveText: white,
    mobileBackground: white,
    mobileLiveBurgerColor: '#c5abf5',
    mobileLiveText: black,
    mobileProfileLink: gray,
    text: black,
  },
  order: {
    active: { background: purple, text: white },
    background: softPurple,
    switchBackground: '#c2b8d4',
  },
  personalCorner: {
    trendingForYou: {
      newsIndexBackground: purple,
      newsIndexColor: white,
    },
    welcomeMessage: {
      background: purple,
      greetingColor: white,
      tradeLinkColor: yellow,
    },
  },
  priceDown: softRed,
  priceUp: green,
  priceZero: purple,
  primaryPurple: purple,
  progress: {
    background: '#ebe8ef',
    regular: purple,
    warning: softRed,
  },
  promotionBanner: {
    arrow: {
      background: 'rgba(194, 184, 212, 0.6)',
      hover: 'rgba(194, 184, 212, 1)',
    },
    pagination: {
      inactive: 'rgba(194, 184, 212, 0.6)',
      active: white,
    },
  },
  promotionModal: {
    slider: {
      active: purple,
      inactive: '#dfdce5',
    },
  },
  purple,
  referral: {
    circleBg: '#714fd5',
    displayFieldBg: gray97,
    word: purple,
  },
  search: {
    demo: {
      background: 'rgba(0,0,0,0.05)',
      icon: 'rgba(0,0,0,0.3)',
      placeholder: 'rgba(0,0,0,0.4)',
      text: black,
    },
    live: {
      background: 'rgba(255,255,255,0.2)',
      icon: 'rgba(255,255,255,0.4)',
      placeholder: 'rgba(255,255,255,0.4)',
      text: white,
    },
  },
  select: {
    background: gray97,
  },
  selectBox: {
    background: softPurple,
  },
  sell: softRed,
  sentiment: {
    background: gray95,
    bearishBackground: '#FEF2F2',
    bearishText: softRed,
    bullishBackground: '#F0FBEC',
    bullishText: green,
    text: gray,
  },
  sideMenuItem: {
    selectBackgroundColor: purple,
    unSelectBackgroundColor: gray95,
  },
  signUp: {
    active: purple,
    text: black,
    warn: softRed,
  },
  signUpLiveAcc: {
    background: purple,
    text: white,
  },
  sltpTrack: {
    background: softPurple,
    rail: '#dfdce5',
    tooltip: { background: purple, text: white },
    track: { lose: softRed, win: green, pending: gray },
  },
  softPurple,
  softRed,
  subMenu: {
    activeMobile: softPurple,
    background: '#eeebf2',
    icon: '#979797',
    selectedText: purple,
    text: black,
  },
  text: black,
  title: purple,
  unread: purple,
  user: {
    administrator: {
      background: gray97,
      tagText: purple,
      text: gray,
    },
  },
  upgradeLiveAccount: {
    background: '#31008A',
  },
  warning: {
    fontColor: softRed,
    background: '#fdeeee',
  },
  watchListMenu: {
    backgroundColor: gray95,
  },
  widgetLine: { default: purple, discover: '#ff811a', myTrade: '#1e5ef6', trade: '#00dbb2' },
};

export const darkTheme: typeof Theme = {
  ...commonStyle,
  accountBalance: {
    button: {
      background: { normal: purple, hover: '#5819cd', active: '#43139b' },
      text: white,
    },
    content: {
      default: black,
      withPnL: white,
    },
    disabled: { background: lightGray, text: gray },
    optionBackground: gray95,
    text: black,
  },
  accountSettings: {
    list: {
      background: secondaryWhite,
      color: purple,
    },
    marketButton: {
      bgoff: gray95,
      bgon: purple,
    },
    textField: {
      background: gray95,
    },
    warning: softRed,
  },
  alertBanner: {
    closeButton: black,
    background: secondaryWhite,
    text: black,
  },
  arrowButton: {
    background: purple,
  },
  background: white,
  border: gray90,
  borderDark: '#979797',
  button: {
    active: { off: '#bfbfbf', primary: '#0abf89', secondary: '#b3b3b3' },
    background: { off: gray90, primary: '#00ffb2', secondary: gray95 },
    border: { off: gray, on: black },
    boxShadow: { off: gray, on: black },
    disabled: { background: lightGray, border: gray, boxShadow: gray },
    hover: { off: '#d9d9d9', primary: '#00f2a9', secondary: gray90 },
    text: { off: gray, on: black },
  },
  buttonText: white,
  buy: green,
  chat: {
    background: white,
    channel: {
      active: softPurple,
      hover: secondaryWhite,
      readLabel: purple,
      timeLabel: gray,
    },
    input: {
      activeBackground: white,
      idleBackground: gray95,
    },
    message: {
      background: gray97,
      backgroundMe: softPurple,
    },
    deleted: {
      background: '#ecebeb',
      color: '#7a7a7a',
    },
  },
  campaigns: {
    marketingCampaign: {
      banner: {
        title: {
          top: '#88ff00',
          bottom: '#00ffb2',
        },
        text: white,
        countdown: {
          background: '#2b2608',
          border: yellow,
          text: yellow,
        },
      },
      mission: {
        amount: purple,
        badge: {
          background: '#f4fef2',
          text: green,
        },
        border: lightGray,
        credit: green,
        header: {
          background: softPurple,
        },
        highlight: {
          background: purple,
          text: white,
        },
        progress: {
          background: {
            on: purple,
            off: '#c2b8d4',
          },
          text: {
            on: purple,
            off: '#c2b8d4',
          },
        },
        rules: {
          text: purple,
        },
      },
      luckyDraw: {
        boxCount: yellow,
      },
      label: gray,
      slogan: yellow,
    },
  },
  checkbox: purple,
  circularGraph: {
    background: softPurple,
    green: green,
    purple: purple,
    softRed: softRed,
  },
  datePicker: {
    lightText: '#f0f8ff',
    primaryBackground: purple,
    secondaryBackground: softPurple,
  },
  dateRangePicker: {
    backgroundColor: gray97,
  },
  dayTimePicker: {
    backgroundColor: gray97,
  },
  deposit: { input: { background: gray97, placeholder: gray }, text: black },
  disabled: white,
  dottedLine: lightGray,
  dragndrop: { background: white, active: secondaryWhite },
  error: {
    fontColor: 'red',
  },
  feed: {
    commentContainer: {
      backgroundColor: gray95,
    },
    commentReactionButton: {
      activeColor: purple,
      inactiveColor: gray,
    },
    commentReactionCount: {
      backgroundColor: white,
      borderColor: gray95,
    },
    moreButtonColor: gray,
    reactionButton: {
      color: gray,
    },
    sharedTrade: {
      background: {
        neutral: softPurple,
      },
      titleBackground: gray,
    },
  },
  feedback: {
    imageBackground: gray97,
  },
  fileUploader: {
    border: '#dfdce5',
    background: softPurple,
  },
  headerBorder: gray95,
  hover: secondaryWhite,
  iconButton: {
    active: { background: '#b3b3b3' },
    background: gray95,
    border: black,
    disabled: { background: lightGray },
    hover: { background: gray90 },
    onClick: '#d9d9d9',
    text: black,
    tooltip: {
      background: black,
      text: white,
    },
  },
  imageBackground: gray97,
  impact: {
    selectBackground: gray97,
    high: { color: softRed, background: '#fef2f2' },
    mid: { color: '#ff811a', background: '#fef6ec' },
    low: { color: green, background: '#f4fef2' },
  },
  input: {
    background: gray95,
    warning: softRed,
  },
  lightGray: lightGray,
  link: purple,
  liveAccountRegistration: kycTheme,
  maxWidth: '1680px',
  menu: {
    active: purple,
    background: '#f7f7f5',
    liveAccountBackground: purple,
    liveText: white,
    mobileBackground: white,
    mobileLiveBurgerColor: '#c5abf5',
    mobileLiveText: black,
    mobileProfileLink: gray,
    text: black,
  },
  order: {
    active: { background: purple, text: white },
    background: softPurple,
    switchBackground: '#c2b8d4',
  },
  personalCorner: {
    trendingForYou: {
      newsIndexBackground: purple,
      newsIndexColor: white,
    },
    welcomeMessage: {
      background: purple,
      greetingColor: white,
      tradeLinkColor: yellow,
    },
  },
  priceDown: softRed,
  priceUp: green,
  priceZero: purple,
  primaryPurple: purple,
  progress: {
    background: '#ebe8ef',
    regular: purple,
    warning: softRed,
  },
  promotionBanner: {
    arrow: {
      background: 'rgba(194, 184, 212, 0.6)',
      hover: 'rgba(194, 184, 212, 1)',
    },
    pagination: {
      inactive: 'rgba(194, 184, 212, 0.6)',
      active: white,
    },
  },
  promotionModal: {
    slider: {
      active: purple,
      inactive: '#dfdce5',
    },
  },
  purple,
  referral: {
    circleBg: '#714fd5',
    displayFieldBg: gray97,
    word: purple,
  },
  search: {
    demo: {
      background: 'rgba(0,0,0,0.05)',
      icon: 'rgba(0,0,0,0.3)',
      placeholder: 'rgba(0,0,0,0.4)',
      text: black,
    },
    live: {
      background: 'rgba(255,255,255,0.2)',
      icon: 'rgba(255,255,255,0.4)',
      placeholder: 'rgba(255,255,255,0.4)',
      text: white,
    },
  },
  select: {
    background: gray97,
  },
  selectBox: {
    background: softPurple,
  },
  sell: softRed,
  sentiment: {
    background: gray95,
    bearishBackground: '#FEF2F2',
    bearishText: softRed,
    bullishBackground: '#F0FBEC',
    bullishText: green,
    text: gray,
  },
  sideMenuItem: {
    selectBackgroundColor: purple,
    unSelectBackgroundColor: gray95,
  },
  signUp: {
    active: purple,
    text: black,
    warn: softRed,
  },
  signUpLiveAcc: {
    background: purple,
    text: white,
  },
  sltpTrack: {
    background: softPurple,
    rail: '#dfdce5',
    tooltip: { background: purple, text: white },
    track: { lose: softRed, win: green, pending: gray },
  },
  softPurple,
  softRed,
  subMenu: {
    activeMobile: softPurple,
    background: '#eeebf2',
    icon: '#979797',
    selectedText: purple,
    text: black,
  },
  text: black,
  title: purple,
  unread: purple,
  user: {
    administrator: {
      background: gray97,
      tagText: purple,
      text: gray,
    },
  },
  upgradeLiveAccount: {
    background: '#31008A',
  },
  warning: {
    fontColor: softRed,
    background: '#fdeeee',
  },
  watchListMenu: {
    backgroundColor: gray95,
  },
  widgetLine: { default: purple, discover: '#ff811a', myTrade: '#1e5ef6', trade: '#00dbb2' },
};

export type AppTheme = typeof Theme;
