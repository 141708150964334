import { ReactNode } from 'react';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';

import useUserLoggedIn from '@/hooks/useUserLoggedIn';

import type { AuthType } from './routes';

type RestrictedRouteProps = {
  auth?: Extract<AuthType, 'AuthenticatedOnly' | 'UnauthenticatedOnly'>;
  children?: ReactNode;
} & RouteProps;

const RestrictedRoute = ({ auth, children }: RestrictedRouteProps) => {
  const isLoggedIn = useUserLoggedIn();
  const { pathname = '' } = useLocation();

  if (auth === 'AuthenticatedOnly' && !isLoggedIn) {
    return (
      <Navigate to={{ pathname: '/discover/dashboard', search: pathname ? `?redirect_url=${pathname}` : undefined }} replace />
    );
  } else if (auth === 'UnauthenticatedOnly' && isLoggedIn) {
    return <Navigate to={{ pathname: '/discover/dashboard' }} replace />;
  }

  return children;
};

export default RestrictedRoute;
