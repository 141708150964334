import { CalendarEvent, EventDetails, ImpactType } from '@aqt/pt-api';
import { API_CONFIG } from '@aqt/pt-api/http';

import { fetchJson } from './apiUtils';

export const getCalendarEventDetails = (eventId: string) => {
  return fetchJson<EventDetails>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/calendar/v2/events/${eventId}`);
};

export const getEventSubscriptionStatus = (eventIds: string[]) => {
  return fetchJson<Array<{ eventId: string; subscribed: boolean }>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/calendar/v2/subscriptions/status/${eventIds}`
  );
};

export const getCalendarDisclaimer = async () => {
  const response = await fetch(`${API_CONFIG.PT_WEB_ENDPOINT}/labels/financial_calendar_disclaimer.json`);
  return response.json();
};

export const getCalendarEvents = (from: Date, to: Date, countries: string[], impacts: ImpactType[], page: number, size: number) => {
  const queryParam = new URLSearchParams();
  queryParam.set('impacts', impacts.toString());
  queryParam.set('page', page + '');
  queryParam.set('size', size + '');
  if (countries.length > 0) queryParam.set('countries', countries.toString());
  return fetchJson<{ content: CalendarEvent[]; totalElements: number; totalPages: number }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/calendar/v2/events/${from.toISOString()}/${to.toISOString()}?${queryParam.toString()}`
  );
};

export const getCountries = async () => {
  const response = await fetch(`${API_CONFIG.PT_WEB_ENDPOINT}/labels/calendar_countries.json`);
  return response.json();
};

export const subscribeEventNotifications = (eventId: string) => {
  return fetchJson<{ message: string }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/calendar/v2/subscriptions/${eventId}`, {
    method: 'POST',
  });
};

export const unsubscribeEventNotifications = (eventId: string) => {
  return fetchJson<{ message: string }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/calendar/v2/subscriptions/${eventId}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE',
  });
};
