import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import Translations from './Translations';

const i18nInit = () => {
  return (
    i18n
      // passes i18n down to react-i18next
      .use(initReactI18next)
      // reference: https://www.i18next.com/translation-function/plurals#ordinal-plurals
      .use(intervalPlural)
      .init({
        resources: Translations,
        lng: 'en',
        // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        returnNull: false,
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      })
  );
};

export default i18nInit;
