import { API_CONFIG } from '@aqt/pt-api';
import { PageV2, PinActivityResponse, PinnedActivityResponse } from '@aqt/pt-api';

import { fetchJson, fetchResponse } from './apiUtils';

export const addChannelModerator = async (channelId: string, userIds: string[]) => {
  return fetchJson<{ success: boolean }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/chat/v1/getstream/channels/${channelId}/moderators`, {
    body: JSON.stringify({ userIds }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const deleteActivity = (activityId: string) => {
  return fetchJson<any>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/activities/${activityId}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE',
  });
};

export const deleteFeedComment = async (reactionId: string, isNotifyAuthor: boolean) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/reactions/${reactionId}?isNotifyAuthor=${isNotifyAuthor ? 'Y' : 'N'}`,
    await API_CONFIG.accessToken,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
    }
  );
};

export const followUnfollow = async (cognitoId: string, followingCognitoId: string, isFollow: boolean) => {
  return fetchResponse(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/cognitoUsers/${cognitoId}/followingCognitoUsers/${followingCognitoId}`,
    await API_CONFIG.accessToken,
    {
      body: '',
      method: isFollow ? 'POST' : 'DELETE',
    }
  );
};

export const getFollowerCognitoIds = (cognitoId: string) => {
  return fetchJson<string[]>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/cognitoUsers/${cognitoId}/followerCognitoUsers`);
};

export const getFollowerCount = (cognitoId: string) => {
  return fetchJson<number>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/cognitoUsers/${cognitoId}/followerCount`);
};

export const getFollowingCognitoIds = (cognitoId: string, page = 0, size = 100) => {
  return fetchJson<PageV2<string>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/cognitoUsers/${cognitoId}/followingCognitoUsers?page=${page}&size=${size}`
  );
};

export const getFollowingCount = (cognitoId: string) => {
  return fetchJson<number>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/cognitoUsers/${cognitoId}/followingCount`);
};

export const getMostFollowers = () => {
  return fetchJson<{
    userFollowers: {
      cognitoId: string;
      friendlyName: string;
      avatarLink: string;
      followerCount: number;
    }[];
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/cognitoUsers/mostFollowers`);
};

export const getGetStreamToken = () => {
  return fetchJson<{
    apiKey: string;
    appId: string;
    token: string;
    expireAt: number;
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/token`);
};

export const getPinnedActivity = (feed: string) => {
  return fetchJson<PinnedActivityResponse>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/feeds/${feed}/pinnedActivity`, {
    method: 'GET',
  });
};

export const removeChannelModerator = (channelId: string, userIds: string[]) => {
  return fetchJson<{ success: boolean }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/chat/v1/getstream/channels/${channelId}/moderators`, {
    body: JSON.stringify({ userIds }),
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE',
  });
};

export const removeGroupChatAvatar = (channelId: string) => {
  return fetchJson<{ success: boolean }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/chat/v1/getstream/channels/${channelId}/avatars`, {
    method: 'DELETE',
  });
};

export const reportFeedComment = (
  feed: string,
  foreignId: string,
  time: string,
  reactionId: string,
  type: string,
  comments: string,
  action = 'moderate'
) => {
  return fetchJson(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/reactions/${reactionId}/${action}`, {
    body: JSON.stringify({ feed, foreignId, time, type, comments }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const reportFeedPost = (
  feed: string,
  foreignId: string,
  time: string,
  type: string,
  comments: string,
  action = 'moderate'
) => {
  return fetchJson(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/feeds/${feed}/activities/${foreignId}/${time}/${action}`, {
    body: JSON.stringify({ type, comments }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const pinActivity = (feed: string, activityId: string) => {
  return fetchJson<PinActivityResponse>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/feeds/${feed}/activities/${activityId}/pin`, {
    method: 'POST',
  });
};

export const unpinActivity = (feed: string, activityId: string) => {
  return fetchJson<{ unpinned: boolean }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/feeds/${feed}/activities/${activityId}/unpin`,
    {
      method: 'POST',
    }
  );
};

export const uploadGroupChatAvatar = async (channelId: string, image: Blob, width: number, height: number) => {
  const formData = new FormData();
  formData.append('files', image);
  formData.append('toCropImgX', '0');
  formData.append('toCropImgY', '0');
  formData.append('toCropImgW', width.toString());
  formData.append('toCropImgH', height.toString());
  return await fetchJson<{ url: string }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/chat/v1/getstream/channels/${channelId}/avatars`, {
    method: 'POST',
    headers: { Authorization: await API_CONFIG.accessToken },
    body: formData,
  });
};

export const updateActivity = async (activityId: string, editedText: string, editedImages: Array<string>) => {
  return fetchJson<any>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/feed/v1/getstream/activities/${activityId}`, {
    body: JSON.stringify({
      editedText,
      editedImages,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'PUT',
  });
};
