import { API_CONFIG } from '@aqt/pt-api/http';
import { fetchAuthSession } from 'aws-amplify/auth';

import AuthConfig from './AuthConfig';
import { SERVICES_ENV } from './env';

let { isLogStdout } = AuthConfig;

type Freemium = {
  email: string;
  accessToken: string;
  expTs: Date;
};

let freemium: Freemium | undefined = undefined;

let clearFreemium = () => (freemium = undefined);

let isFreemium = () => freemium != null;

let loginFreemium = async () => {
  let response = await fetch(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/login/freemium`, { method: 'GET' });
  if (response.ok) {
    let json: { email: string; accessToken: string; exp: string } = await response.json();
    let fm = { ...json, expTs: new Date(json.exp) };
    freemium = fm;
    return fm;
  } else {
    throw new Error('Server returned HTTP ' + response.status);
  }
};

let loginManual = async (email: string, password: string) => {
  let ClientId = SERVICES_ENV.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID;

  let response = await fetch(`https://cognito-idp.ap-southeast-1.amazonaws.com/`, {
    body: JSON.stringify({ AuthFlow: 'USER_PASSWORD_AUTH', AuthParameters: { USERNAME: email, PASSWORD: password }, ClientId }),
    headers: { 'Content-Type': 'application/x-amz-json-1.1', 'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth' },
    method: 'POST',
  });
  if (response.ok) {
    let json: { AuthenticationResult: { AccessToken: string; ExpiresIn: number } } = await response.json();
    let { AccessToken, ExpiresIn } = json.AuthenticationResult;
    return { jwt: AccessToken, expireAt: Date.now() + ExpiresIn * 1000 };
  } else {
    throw new Error('Server returned HTTP ' + response.status);
  }
};

let loginFreemiumPromise: Promise<Freemium> | null = null;

let getAccessTokenInner = async () => {
  const { tokens } = await fetchAuthSession();
  if (!tokens) throw new Error('No tokens');
  return tokens.accessToken.toString();
};

let getAccessToken = async () => {
  if (freemium == null) {
    try {
      return await getAccessTokenInner();
    } catch (e) {
      // failed to get user, not logged in
    }

    loginFreemiumPromise = loginFreemiumPromise ?? loginFreemium();
    freemium = await loginFreemiumPromise;
  }

  if (freemium.expTs <= new Date()) {
    loginFreemiumPromise = loginFreemium(); // Freemium token expired, get another one
    freemium = await loginFreemiumPromise;
  }

  return freemium.accessToken;
};

let Auth_ = {
  clearFreemium,
  getAccessToken,
  isFreemium,
  isLogStdout,
  loginFreemium,
};

export default Auth_;
