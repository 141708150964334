import { useEffect, useState } from 'react';

import AuthConfig from './AuthConfig';

let useAsyncEffect: (callback: (active: () => boolean) => Promise<any>, deps: any) => void = (callback, deps) => {
  // avoid "Can't perform a React state update on an unmounted component"
  useEffect(() => {
    let active = true;
    callback(() => active).catch(AuthConfig.alert);
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

let useDebouncedEffect = (callback, deps, delay = 500) => {
  let [timer, setTimer] = useState<any>(null); // debouncer

  useEffect(() => {
    let cleanup: any = null;
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        cleanup?.();
        cleanup = callback();
      }, delay)
    );
    return () => {
      cleanup?.();
      cleanup = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

let useFetch: <T>(callback: () => Promise<T>, init: T, deps) => T = (callback, init, deps) => {
  let [value, setValue] = useState(init);
  useAsyncEffect(async active => {
    let v = await callback();
    active() && setValue(v);
  }, deps);
  return value;
};

export { useAsyncEffect, useDebouncedEffect, useFetch };
