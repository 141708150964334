import { API_CONFIG } from '@aqt/pt-api';
import {
  AdjustmentType,
  BankAccount,
  CheckoutRequest,
  CheckoutType,
  CreditCard,
  DepositFeesInfo,
  DepositInfo,
  DepositMethodLimit,
  DepositPaymentMethod,
  DepositRequest,
  DepositWithdraw,
  LiveAccountRegistration,
  Page,
  SignedCurrencyConversionRate,
  TxDetail,
  TxHistory,
  WithdrawalFeesInfo,
  WithdrawalInfo,
  WithdrawalRequest,
} from '@aqt/pt-api';

import { fetchJson, fetchMarketDataJson } from './apiUtils';

export const createBankAccount = (cognitoId: string, bankAccountNumber: string, bankName: string, imageId: string) => {
  return fetchJson<BankAccount>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/cognitoUsers/${cognitoId}/bankAccounts`, {
    body: JSON.stringify({
      bankAccountNumber,
      bankName,
      imageId,
    }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const createCheckout = (
  accountType: string,
  checkoutRequest: CheckoutRequest,
  checkoutType: CheckoutType = 'credit_card'
) => {
  return fetchJson<{ cashierKey: string; embedUrl: string; checkoutToken: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v2/${accountType}/payins/bridgerPay/checkouts/${checkoutType}`,
    {
      body: JSON.stringify(checkoutRequest),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );
};

export const createDepositRequest = (depositRequest: DepositRequest) =>
  fetchJson<DepositRequest>(`/aqt/cash/v1/depositRequests`, {
    body: JSON.stringify(depositRequest),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });

export const createWithdrawalRequest = (withdrawalRequest: WithdrawalRequest) => {
  return fetchJson<WithdrawalRequest>(
    `/aqt/trading_${encodeURIComponent(withdrawalRequest.accountType.toLowerCase())}/v2/withdrawalRequests`,
    {
      body: JSON.stringify(withdrawalRequest),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }
  );
};

export const getAdjustments = async (
  cognitoId: string,
  accountType: string,
  accountId: string,
  fr: Date,
  to: Date,
  page: number,
  size: number,
  sortField: string,
  isDesc: boolean,
  adjustmentTypes?: string,
  notInAdjustmentTypes?: string
) => {
  const endpointUrl = `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/users/${encodeURIComponent(cognitoId)}/accounts/${encodeURIComponent(
    accountType
  )}/${accountId}/transactions/adjustments`;
  const queryParams = new URLSearchParams();
  queryParams.set('fr', fr.toISOString());
  queryParams.set('to', to.toISOString());
  queryParams.set('page', page.toString());
  queryParams.set('size', size.toString());
  queryParams.set('sortField', sortField);
  queryParams.set('isDesc', isDesc.toString());
  if (adjustmentTypes) queryParams.set('adjustmentTypes', adjustmentTypes);
  if (notInAdjustmentTypes) queryParams.set('notInAdjustmentTypes', notInAdjustmentTypes);
  return fetchJson<Page<TxHistory<AdjustmentType>>>(`${endpointUrl}?${queryParams.toString()}`);
};

export const getBankAccounts = (cognitoId: string) => {
  return fetchJson<BankAccount[]>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/cognitoUsers/${cognitoId}/bankAccounts`);
};

export const getCashierKey = async (accountType: string, checkoutType: CheckoutType = 'credit_card') => {
  return await fetchJson<{ cashierKey: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v2/${accountType}/payins/bridgerPay/cashierKeys/${checkoutType}`
  );
};

export const getCreditCards = async (cognitoId: string, accountType: string, accountId: string) => {
  const result = await fetchJson<{ data: CreditCard[] }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/users/${cognitoId}/accounts/${accountType}/${accountId}/withdrawals/creditCards`
  );
  return result.data;
};

export const getDepositAndWithdrawal = (cognitoId: string, accountType: string, accountId: string, fr: Date, to: Date) => {
  return fetchJson<Page<TxHistory<DepositWithdraw>>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/users/${encodeURIComponent(cognitoId)}/accounts/${encodeURIComponent(
      accountType
    )}/${accountId}/transactions/deposit-withdrawal?fr=${fr.toISOString()}&to=${to.toISOString()}`
  );
};

export const getDepositInfo = async () => {
  return await fetchJson<DepositInfo>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/depositRequests/depositInfo`);
};

export const getDepositDetails = async () => {
  const response = await fetch(`${API_CONFIG.PT_WEB_ENDPOINT}/labels/v4/deposit.json`);
  return response.json();
};

export const getDepositFeeInfo = async () => {
  return fetchJson<DepositFeesInfo>(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/transactionFeeInfo/deposit`);
};

export const getDepositLimit = (accountType: string, accountId: string) => {
  return fetchJson<{ depositMethodLimits: Array<DepositMethodLimit> }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v2/depositLimits/${accountType}/${accountId}`
  );
};

export const getDepositRequestStatus = (accountType: string, accountId: string, paymentCode: string) => {
  return fetchJson<{
    isPaymentGatewayProcessing: boolean;
    depositRequestStatus: string;
  }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v2/accountCodes/${accountType}:${accountId}/paymentCodes/VIRTUALPAY/${paymentCode}/status`
  );
};

export const getPaymentAsiaSignature = (request: string) => {
  return fetchJson<{ signature: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/payins/paymentAsia/signature?request=${encodeURIComponent(request)}`
  );
};

export const getPaymentAsiaConfig = (accountType: string) => {
  return fetchJson<{ clientIpAddr: string; merchantToken: string; paymentUrl: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v2/${accountType}/payins/paymentAsia/config`
  );
};

export const getPaymentCode = (accountType: string, accountId: string, paymentMethod: string) => {
  if (accountId) {
    return fetchJson<{
      paymentMethod: string;
      accountCode: string;
      paymentCode: string;
    }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/accountCodes/${accountType}:${accountId}/paymentCodes/${paymentMethod}`);
  } else {
    return { paymentCode: '' };
  }
};

export const getPaymentDetails = async () => {
  const response = await fetch(`${API_CONFIG.PT_WEB_ENDPOINT}/labels/v4/payment.json`);
  return response.json();
};

export const getLiveAccountRegistrations = (cognitoId: string) => {
  return fetchJson<Page<LiveAccountRegistration>>(`/aqt/cash/v1/liveAccountRegistrations?cognitoId=${cognitoId}`);
};

export const getMerchantReference = (
  accountType: string,
  accountId: string,
  paymentMethod: DepositPaymentMethod,
  signedCurrencyConversionRate: Record<string, string>
) => {
  const accountCode = `${accountType}:${accountId}`;
  return fetchJson<{ paymentCode: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/accountCodes/${accountCode}/paymentCodeWithConversionRates/${paymentMethod}`,
    {
      body: JSON.stringify(signedCurrencyConversionRate),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
  );
};

export const getTransactionHistories = async (cognitoId: string, accountType: string, accountId: string) => {
  return fetchJson<Array<TxHistory>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/users/${encodeURIComponent(cognitoId)}/accounts/${encodeURIComponent(
      accountType
    )}/${accountId}/transactions`
  );
};

export const getTransactionHistoryDetail = (cognitoId: string, transactionType: DepositWithdraw, transactionId: string) => {
  return fetchJson<TxDetail<DepositWithdraw>>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/users/${encodeURIComponent(
      cognitoId
    )}/transactions/deposit-withdrawal/${transactionType}/${transactionId}`
  );
};

export const getSignedCurrencyConversionRate = (frCurrency: string, toCurrency: string) => {
  return fetchJson<SignedCurrencyConversionRate>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/signedCurrencyConversionRates/${frCurrency}/${toCurrency}`
  );
};

export const getVirtualPayConfig = (accountType: string) => {
  return fetchMarketDataJson<{
    apiKey: string;
    merchantId: string;
    url: string;
    localPaymentUrl: string;
    mobileValidateUrl: string;
    secret: string;
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v2/${accountType}/payins/virtualpay/config`);
};

export const getWithdrawalDetails = async () => {
  const response = await fetch(`${API_CONFIG.PT_WEB_ENDPOINT}/labels/v4/withdrawal.json`);
  return response.json();
};

export const getWithdrawalFeeInfo = async () => {
  return fetchJson<WithdrawalFeesInfo>(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/transactionFeeInfo/withdrawal`);
};

export const getWithdrawalInfo = () => {
  return fetchJson<WithdrawalInfo>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/withdrawalRequests/withdrawalInfo`);
};

export const uploadDepositRequestImage = (depositRequest: DepositRequest, file: File) => {
  let formData = new FormData();
  formData.append('files', file);

  return fetchJson<DepositRequest>(`/aqt/cash/v1/depositRequests/${encodeURIComponent(depositRequest.id)}/images`, {
    body: formData,
    method: 'POST',
  });
};

export const uploadBankStatement = async (userBankAccountId: string, file: File) => {
  const formData = new FormData();
  formData.append('files', file);

  return fetchJson<BankAccount>(`/aqt/cash/v1/userBankAccounts/${userBankAccountId}/images`, {
    body: formData,
    method: 'POST',
  });
};

export const upsertPaymentCode = async (
  accountType: string,
  accountId: string,
  paymentMethod: DepositPaymentMethod,
  paymentCode?: any
) => {
  let accountCode = `${accountType}:${accountId}`;
  return await fetchJson<{ paymentCode: string }>(
    `${API_CONFIG.PT_WEB_ENDPOINT}/aqt/cash/v1/accountCodes/${accountCode}/paymentCodes/${paymentMethod}`,
    {
      body: JSON.stringify({ paymentCode }),
      method: 'PUT',
      headers: { Authorization: await API_CONFIG.accessToken, 'Content-Type': 'application/json' },
    }
  );
};
