import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Auth_ from '@/Auth';
import { accountMigrations } from '@/AuthConfig';
import { usePtContext } from '@/contexts/PtContext';

import { useAqtQuery } from './useAqtQuery';

const getAccountMigrationLink = async (fromAccountType: string, toAccountType: string, webRedirectUrl: string) => {
  return `/onboard/upgrade/${fromAccountType}/${toAccountType}?token=${await Auth_.getAccessToken()}&webRedirectUrl=${webRedirectUrl}`;
};

const useMigration = () => {
  const { kycStatus, accounts, cognitoUser } = usePtContext();
  const { pathname } = useLocation();

  const cognitoId = cognitoUser?.username || '';

  const [fromAccountType = '', toAccountType = ''] = [accountMigrations()[0]?.from, accountMigrations()[0]?.to];

  const { data } = useAqtQuery(
    ['migrationStatus', { fromAccountType, toAccountType, cognitoId }],
    [fromAccountType, toAccountType],
    {
      enabled: cognitoId !== '' && kycStatus === 'COMPLETED' && accounts.some(account => account.accountType === fromAccountType),
      placeholderData: { status: 'UNKNOWN', reason: '' },
    }
  );

  const openMigrationLink = useCallback(async () => {
    const webRedirectUrl = encodeURIComponent(pathname);
    const url = await getAccountMigrationLink(fromAccountType, toAccountType, webRedirectUrl);

    window?.open(url, '_self')?.focus();
  }, [pathname, fromAccountType, toAccountType]);

  const isAllowMigration = useMemo(() => {
    // if migrated or no kyc completed, return false
    if (data?.status === 'FAILED' || data?.status === 'NOT_STARTED') {
      return true;
    }

    return false;
  }, [data?.status]);

  return {
    isAllowMigration,
    openMigrationLink,
  };
};

export { useMigration };
