// ref: https://github.com/davidhu2000/react-spinners/blob/main/src/ScaleLoader.tsx
import { CSSProperties, DetailedHTMLProps, HTMLAttributes } from 'react';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  height?: string;
  width?: string;
  radius?: string;
  margin?: string;

  color?: string;
  loading?: boolean;
  cssOverride?: CSSProperties;
  speedMultiplier?: number;
}

const animationName = 'react-spinners-ScaleLoader-scale';

const createAnimation = (): string => {
  if (typeof window == 'undefined' || !window.document) {
    return animationName;
  }

  const styleEl = document.createElement('style');
  document.head.appendChild(styleEl);
  const styleSheet = styleEl.sheet;

  const keyFrames = `
      @keyframes ${animationName} {
        0% {transform: scaley(1.0)} 50% {transform: scaley(0.4)} 100% {transform: scaley(1.0)}
      }
    `;

  if (styleSheet) {
    styleSheet.insertRule(keyFrames, 0);
  }

  return animationName;
};

const scale = createAnimation();

const ScaleLoaderComponent: React.FC<Props> = ({
  loading = true,
  color = '#000000',
  speedMultiplier = 1,
  cssOverride = {},
  height = '35px',
  width = '4px',
  radius = '2px',
  margin = '2px',
  ...props
}) => {
  const style = (i: number): CSSProperties => {
    return {
      backgroundColor: color,
      width: width,
      height: height,
      margin: margin,
      borderRadius: radius,
      display: 'inline-block',
      animation: `${scale} ${1 / speedMultiplier}s ${i * 0.1}s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)`,
      animationFillMode: 'both',
    };
  };

  if (!loading) {
    return null;
  }

  return (
    <span
      style={{
        display: 'inherit',
        ...cssOverride,
      }}
      {...props}
    >
      <span style={style(1)} />
      <span style={style(2)} />
      <span style={style(3)} />
      <span style={style(4)} />
      <span style={style(5)} />
    </span>
  );
};

export default ScaleLoaderComponent;
