import { memo } from 'react';
import styled from 'styled-components';

import Backdrop from './Backdrop';
import ScaleLoader from './ScaleLoader';

const HeadingTitle = styled.div`
  margin-top: 22px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`;

type LoadingProps = {
  open?: boolean;
  title?: string;
};

const Loading = memo(({ open = true, title = '' }: LoadingProps) => {
  return (
    <Backdrop open={open} style={{ background: 'transparent' }}>
      <>
        <ScaleLoader />
        {title && <HeadingTitle>{title}</HeadingTitle>}
      </>
    </Backdrop>
  );
});

export const NoCardLoading = memo(({ open = true }: LoadingProps) => {
  return <ScaleLoader loading={open} height='30px' />;
});

export default Loading;
