import { API_CONFIG } from '@aqt/pt-api/http';
import { Amplify } from 'aws-amplify';

import Auth from './Auth';
import { SERVICES_ENV } from './env';
import i18nInit from './i18n';
import { configureOneSignal } from './utils/OneSignalUtils';

export function initConfig() {
  API_CONFIG.PT_WEB_ENDPOINT = '';
  API_CONFIG.PT_MARKETING_ENDPOINT = SERVICES_ENV.PT_MARKETING_ENDPOINT;
  API_CONFIG.PT_WEB_VERIFY_ENDPOINT = SERVICES_ENV.PT_WEB_VERIFY_ENDPOINT;
  API_CONFIG.accessTokenProvider = Auth.getAccessToken;
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: SERVICES_ENV.AWS_COGNITO_USER_POOL_ID,
        userPoolClientId: SERVICES_ENV.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
      },
    },
  });
  configureOneSignal();
  i18nInit();
}
