import {
  AlertBanner,
  API_CONFIG,
  Blogs,
  KycStatus,
  Page,
  PromotionBannerContent,
  PromotionModalContent,
  PtFAQ,
  SignupModalContent,
  WhatsNewInfo,
} from '@aqt/pt-api';

import { fetchJson } from './apiUtils';
import { searchDollarTagInstrument } from './marketData';

export const search = (indices: string[] | null, q: string, isSaveTrending: boolean, from = 0, size = 10) => {
  if (indices != null && indices.length === 1 && indices[0] === 'instrument') {
    return searchDollarTagInstrument(q, from, size);
  }

  let url: string;
  if (indices == null) {
    url = `/aqt/api/v1/search?`;
  } else {
    url = `/aqt/api/v1/search?indices=${encodeURIComponent(indices.join(','))}&`;
  }
  return fetchJson<{
    totalHits: number;
    hits: { score: number; index: string; id: string; sourceAsMap: any }[];
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}${url}q=${encodeURIComponent(q)}&from=${from}&size=${size}&isSaveTrending=${isSaveTrending}`);
};

export const getAlertBanners = async (accountType: string, kycStatus: KycStatus, page = 0, size = 10) => {
  return fetchJson<Page<AlertBanner>>(
    `${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/alertBanners?accountType=${accountType}&kycStatus=${kycStatus}&page=${page}&pageSize=${size}`
  );
};

export const getBlogs = async (pageNumber = 1, pageSize: number, isDesc = true) => {
  return fetchJson<Blogs>(
    `${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/blogs?page=${pageNumber}&pageSize=${pageSize}&isDesc=${isDesc}&imageFormat=true`
  );
};

export const getPromotionalModalContents = async (
  isWeb: boolean = true,
  isDesc: boolean = true,
  page: number = 0,
  size: number = 1
) => {
  return fetchJson<Page<PromotionModalContent>>(
    `${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/modalContents?isWeb=${isWeb}&isDesc=${isDesc}&page=${page}&pageSize=${size}`
  );
};

export const getPromotionBannerContents = async () => {
  return fetchJson<Page<PromotionBannerContent>>(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/promotionBanners`);
};

export const getPtSupport = async () => {
  return fetchJson<{ content?: { id: number; hotline?: string; contactUs?: string } }>(
    `${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/support`
  );
};

export const getServerTime = () => {
  return fetchJson<number>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/now`);
};

export const getSignUpModalContents = async () => {
  return fetchJson<Page<SignupModalContent>>(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/modalContents?category=sign-up`);
};

export const getTrendingSearches = async () => {
  return fetchJson<string[]>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/search/trending`);
};

export const getWhatsNewInfo = async (pageNumber: number, pageSize = 10, isDesc = true) => {
  return fetchJson<WhatsNewInfo>(
    `${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/whatsnews?page=${pageNumber}&pageSize=${pageSize}&isDesc=${isDesc}`
  );
};

export const getFAQContents = async () => {
  return fetchJson<PtFAQ>(`${API_CONFIG.PT_MARKETING_ENDPOINT}/ptm-api/v1/ptWebFAQ`);
};

export const sendFeedback = async (email: string, content: string, type: string, imageIds: string[]) => {
  return fetchJson<{ responseMessage: string }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/createFeedback`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ content: content, type: type, email: email, imageIds: imageIds }),
  });
};

export const uploadFeedbackImage = async (image: File, filename: string) => {
  let formData = new FormData();
  formData.append('content', image);
  formData.append('filename', filename);
  return fetchJson<{
    contentType: string;
    filename: string;
    height: number;
    id: string;
    length: number;
    uploadDate: Date;
    width: number;
  }>(`${API_CONFIG.PT_WEB_ENDPOINT}/aqt/api/v1/uploadFeedbackImage`, {
    method: 'POST',
    headers: { Authorization: await API_CONFIG.accessToken },
    body: formData,
  });
};
