import { lazy, ReactNode } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import ErrorPage from '@/components/error/ErrorPage';
import { basePath } from '@/Constants';

import PlainLayout from './PlainLayout';
import RestrictedRoute from './RestrictedRoute';

const AccountSettings = lazy(() => import('../pages/Settings/AccountSettings'));
const AllMarkets = lazy(() => import('../pages/Trade/Markets/AllMarkets'));
const BankAccounts = lazy(() => import('../pages/BankAccounts'));
const Campaign = lazy(() => import('../pages/Campaign'));
const ChatPage = lazy(() => import('../pages/Chat/index'));
const ClosedTrades = lazy(() => import('../pages/MyTrades/ClosedTrades'));
const CopiedKaptains = lazy(() => import('../pages/MyTrades/CopiedKaptains'));
const Deposit = lazy(() => import('../pages/Deposit'));
const DepositResponse = lazy(() => import('../pages/Deposit/DepositResponse'));
const ReferFriends = lazy(() => import('../pages/ReferFriends'));
const Markets = lazy(() => import('../pages/Trade/Markets/Markets'));
const MostTraded = lazy(() => import('../pages/Trade/MostTraded'));
const MyProfile = lazy(() => import('../pages/MyProfile'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Notifications = lazy(() => import('../pages/Notifications'));
const OpenTrades = lazy(() => import('../pages/MyTrades/OpenTrades'));
const PendingTrades = lazy(() => import('../pages/MyTrades/PendingTrades'));
const Portfolio = lazy(() => import('../pages/MyTrades/Portfolio/Portfolio'));
const Profile = lazy(() => import('../pages/Profile'));
const RedirectToApp = lazy(() => import('../pages/RedirectToApp'));
const SearchResult = lazy(() => import('../pages/SearchResult'));
const SignUp = lazy(() => import('../pages/SignUp'));
const StackedTrades = lazy(() => import('../pages/MyTrades/StackedTrades'));
const Support = lazy(() => import('../pages/Support/Support'));
const TopMovers = lazy(() => import('../pages/Trade/TopMovers'));
const TradeDetailOrder = lazy(() => import('../pages/MyTrades/TradeDetailOrder'));
const TradeDetailPosition = lazy(() => import('../pages/MyTrades/TradeDetailPosition'));
const TransactionHistory = lazy(() => import('../pages/TransactionHistory'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const Withdrawal = lazy(() => import('../pages/Withdrawal'));

export type AuthType = 'AuthenticatedOnly' | 'UnauthenticatedOnly' | 'Public';

/**
 * @note Any public route addition or modification please also update the sitemapbuilder
 * @deprecated for any new route please add to createBrowserRouter
 * @todo migrate all routes to createBrowserRouter
 */
const appRoutes: Array<{ paths: Array<string>; element?: ReactNode; strict?: boolean; auth: AuthType }> = [
  {
    paths: ['/bank-accounts'],
    element: <BankAccounts />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/campaigns/*'],
    element: <Campaign />,
    auth: 'Public',
  },
  {
    paths: ['/chats/:cid?'],
    element: <ChatPage />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: [
      '/deposit',
      '/deposit/:pageType',
      '/deposit/:pageType/:depositCurrency',
      '/deposit/:pageType/:depositCurrency/:step_',
      '/deposit/:pageType/:depositCurrency/:step_/:paymentCode',
    ],
    element: <Deposit />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/deposit-response'],
    strict: true,
    element: <DepositResponse />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/refer'],
    element: <ReferFriends />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/my-trades/closed-trades'],
    element: <ClosedTrades />,
    strict: true,
    auth: 'Public',
  },
  {
    paths: ['/my-trades/closed-trades/:transactionId', '/my-trades/closed-trades/kaptain/:kaptainCognitoId'],
    element: <ClosedTrades />,
    strict: true,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/my-trades/copied-kaptains', '/my-trades/copied-kaptains/:cognitoId'],
    element: <CopiedKaptains />,
    strict: true,
    auth: 'Public',
  },
  {
    paths: ['/my-trades/open-trades', '/my-trades/open-trades/kaptain/:kaptainCognitoId'],
    element: <OpenTrades />,
    strict: true,
    auth: 'Public',
  },
  {
    paths: ['/my-trades/open-trades/stacked-trades/:instrumentKey'],
    element: <StackedTrades />,
    strict: true,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/my-trades/open-trades/:positionId', '/my-trades/open-trades/stacked-trades/:instrumentKey/:positionId'],
    element: <TradeDetailPosition />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/my-trades/pending-trades'],
    element: <PendingTrades />,
    strict: true,
    auth: 'Public',
  },
  {
    paths: ['/my-trades/pending-trades/:orderId'],
    element: <TradeDetailOrder />,
    auth: 'Public',
  },
  {
    paths: ['/my-trades/portfolio'],
    element: <Portfolio />,
    auth: 'Public',
  },
  {
    paths: ['/my-profile/*'],
    element: <MyProfile />,
    auth: 'Public',
  },
  {
    paths: ['/not-found'],
    element: <NotFound />,
    auth: 'Public',
  },
  {
    paths: ['/notifications'],
    element: <Notifications />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/profile/:cognitoId/*'],
    element: <Profile />,
    auth: 'Public',
  },
  {
    paths: ['/search/:searchInput'],
    element: <SearchResult />,
    auth: 'Public',
  },
  {
    paths: ['/settings/*'],
    element: <AccountSettings />,
    auth: 'Public',
  },
  {
    paths: ['/signup/:referralCode?'],
    element: <SignUp />,
    auth: 'UnauthenticatedOnly',
  },
  {
    paths: ['/support/*'],
    element: <Support />,
    auth: 'Public',
  },
  {
    paths: ['/trade/markets/:instrumentKey/*'],
    element: <Markets />,
    auth: 'Public',
  },
  {
    paths: ['/trade/markets/'],
    element: <AllMarkets />,
    auth: 'Public',
  },
  {
    paths: ['/trade/most-traded'],
    element: <MostTraded />,
    auth: 'Public',
  },
  {
    paths: ['/trade/top-movers'],
    element: <TopMovers />,
    auth: 'Public',
  },
  {
    paths: ['/transaction-history'],
    element: <TransactionHistory />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/user/:friendlyName/*'],
    element: <UserProfile />,
    auth: 'Public',
  },
  {
    paths: ['/withdrawal'],
    element: <Withdrawal />,
    auth: 'AuthenticatedOnly',
  },
  {
    paths: ['/app-redirect'],
    element: <RedirectToApp />,
    auth: 'Public',
  },
];

/**
 * @deprecated will be replaced by createBrowserRouter, do not add new routes here
 */
const Root = () => {
  return (
    <Routes>
      {appRoutes.map(({ auth, paths, element, ...rest }) =>
        paths.map(path => (
          <Route
            key={path}
            path={path}
            element={auth === 'Public' ? element : <RestrictedRoute auth={auth}>{element}</RestrictedRoute>}
            {...rest}
          />
        ))
      )}
      <Route path='*' element={<Navigate to='/discover/dashboard' replace />} />
    </Routes>
  );
};

const migrateRoutes = (
  <Route
    path='onboard/upgrade'
    errorElement={<ErrorPage />}
    lazy={async () => {
      const { UpgradeLayout } = await import('./upgrade');
      return {
        element: <UpgradeLayout />,
      };
    }}
  >
    <Route
      path=':fromAccountType/:toAccountType'
      lazy={async () => {
        const MigrateLiveAccount = (await import('@/pages/Migrate/MigrateLiveAccount')).default;
        const { upgradeLoader } = await import('./upgrade');
        return {
          loader: upgradeLoader,
          element: <MigrateLiveAccount />,
        };
      }}
    />
    <Route
      path='result'
      lazy={async () => {
        const MigrationResult = (await import('@/pages/Migrate/MigrationResult')).default;
        return {
          element: <MigrationResult />,
        };
      }}
    />
  </Route>
);

const kycRoutes = (
  <Route
    id='kyc'
    path='onboard'
    errorElement={<ErrorPage />}
    shouldRevalidate={({ currentUrl }) => currentUrl.pathname === '/onboard/result'}
    lazy={async () => {
      const { kycLoader, KycLayout } = await import('./kyc');
      return {
        loader: kycLoader,
        element: <KycLayout />,
      };
    }}
  >
    <Route
      index
      lazy={async () => {
        const IndexPage = (await import('@/components/KYC/OnBoard/SignUpLiveAccount')).default;
        return {
          element: <IndexPage />,
        };
      }}
      handle={{ title: 'SIGN UP LIVE ACCOUNT', currentStep: 0, nextRoute: '/onboard/step1' }}
    />
    <Route
      path='step1'
      lazy={async () => {
        const Step1 = (await import('@/pages/KYC/Step1')).default;
        return {
          element: <Step1 />,
        };
      }}
      handle={{ title: 'Personal Details', currentStep: 1, nextRoute: '/onboard/step2' }}
    />
    <Route
      path='step2'
      lazy={async () => {
        const Step2 = (await import('@/pages/KYC/Step2')).default;
        return {
          element: <Step2 />,
        };
      }}
      handle={{ title: 'Economic Profile', currentStep: 2, nextRoute: '/onboard/step3' }}
    />
    <Route
      path='step3'
      lazy={async () => {
        const Step3 = (await import('@/pages/KYC/Step3')).default;
        return {
          element: <Step3 />,
        };
      }}
      handle={{ title: 'Related Parties Declaration', currentStep: 3, nextRoute: '/onboard/step4' }}
    />
    <Route
      path='step4'
      lazy={async () => {
        const Step4 = (await import('@/pages/KYC/Step4')).default;
        return {
          element: <Step4 />,
        };
      }}
      handle={{ title: 'BANK STATEMENT', currentStep: 4, nextRoute: '/onboard/step5' }}
    />
    <Route
      path='step5'
      lazy={async () => {
        const Step5 = (await import('@/pages/KYC/Step5')).default;
        return {
          element: <Step5 />,
        };
      }}
      handle={{ title: 'Identification Documents', currentStep: 5, nextRoute: '/onboard/step6' }}
    />
    <Route
      path='step6'
      lazy={async () => {
        const Step6 = (await import('@/pages/KYC/Step6')).default;
        return {
          element: <Step6 />,
        };
      }}
      handle={{ title: 'Terms of Service', currentStep: 6, nextRoute: '/onboard/result' }}
    />
    <Route
      path='result'
      lazy={async () => {
        const Result = (await import('@/pages/KYC/Result')).default;
        return {
          element: <Result />,
        };
      }}
      handle={{ title: 'Done' }}
    />
  </Route>
);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {migrateRoutes}
      {kycRoutes}
      <Route
        lazy={async () => {
          const AllTheProviders = (await import('@/contexts/AllTheProviders')).default;
          return {
            element: (
              <AllTheProviders>
                <Outlet />
              </AllTheProviders>
            ),
          };
        }}
        errorElement={<ErrorPage />}
      >
        <Route element={<PlainLayout />} errorElement={<ErrorPage />}>
          {/* plain layout routes put here */}
          <Route
            path='support/faq-plain'
            lazy={async () => {
              const FAQ = (await import('../pages/Support/FAQ')).default;
              return {
                element: (
                  <div style={{ backgroundColor: 'white', height: '100vh' }}>
                    <FAQ />
                  </div>
                ),
              };
            }}
          />
          <Route
            path='reset-password'
            lazy={async () => {
              const ResetPassword = (await import('../pages/Verify/ResetPassword')).default;
              return {
                element: (
                  <RestrictedRoute auth='UnauthenticatedOnly'>
                    <ResetPassword />
                  </RestrictedRoute>
                ),
              };
            }}
          />
          <Route
            path='reset-password-success'
            lazy={async () => {
              const ResetPasswordSuccess = (await import('../pages/Verify/ResetPasswordSuccess')).default;
              return {
                element: (
                  <RestrictedRoute auth='UnauthenticatedOnly'>
                    <ResetPasswordSuccess />
                  </RestrictedRoute>
                ),
              };
            }}
          />
        </Route>
        <Route
          lazy={async () => {
            const RootLayout = (await import('./RootLayout')).default;
            return {
              element: <RootLayout />,
            };
          }}
        >
          <Route path='discover'>
            <Route
              path='calendar/:eventId?'
              lazy={async () => {
                const Calendar = (await import('../pages/Calendar')).default;
                return {
                  element: <Calendar />,
                };
              }}
            />
            <Route
              path='dashboard'
              lazy={async () => {
                const Dashboard = (await import('../pages/Discover/Dashboard/Dashboard')).default;
                return {
                  element: <Dashboard />,
                };
              }}
            />
            <Route path='feeds'>
              <Route
                path='hashtag/:tagId'
                lazy={async () => {
                  const HashTag = (await import('../pages/HashTag')).default;
                  return {
                    element: <HashTag />,
                  };
                }}
              />
              <Route
                path=':feedGroup/posts/:activityId'
                lazy={async () => {
                  const CustomSinglePost = (await import('../components/CustomSinglePost')).default;
                  return {
                    element: <CustomSinglePost />,
                  };
                }}
              />
              <Route
                path=':feedGroup?'
                lazy={async () => {
                  const SocialFeed = (await import('../pages/Discover/SocialFeed')).default;
                  return {
                    element: <SocialFeed />,
                  };
                }}
              />
            </Route>
            <Route
              path='leaderboard'
              lazy={async () => {
                const Leaderboard = (await import('../pages/Discover/Leaderboard/Leaderboard')).default;
                return {
                  element: <Leaderboard />,
                };
              }}
            />
            <Route
              path='news'
              lazy={async () => {
                const News = (await import('../pages/Discover/News')).default;
                return {
                  element: <News />,
                };
              }}
            />
          </Route>
          <Route path='*' element={<Root />} errorElement={<ErrorPage />} />
        </Route>
      </Route>
    </Route>
  ),
  {
    basename: basePath,
  }
);
