import styled from 'styled-components';

import Backdrop from './Backdrop';
import ScaleLoaderComponent from './ScaleLoaderComponent';

const CenterAlign = styled.div<{ width?: string; height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
  white-space: nowrap;
`;
interface ScaleLoaderProps {
  loading?: boolean;
  enableBackDrop?: boolean;
  width?: string;
  height?: string;
}

const ScaleLoader: React.FC<ScaleLoaderProps> = ({ loading = true, enableBackDrop = false, width, height }) => {
  return (
    <CenterAlign width={width} height={height}>
      {enableBackDrop ? (
        <Backdrop open={loading}>
          <ScaleLoaderComponent height={height} color='#36D7B7' loading={loading} />
        </Backdrop>
      ) : (
        <ScaleLoaderComponent height={height} color='#36D7B7' loading={loading} />
      )}
    </CenterAlign>
  );
};

export default ScaleLoader;
